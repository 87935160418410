import React, {useEffect, useState, useContext} from "react";
import {AuthContext} from '../../../common/context/auth'
import {useForm, Controller} from "react-hook-form";
import {http, VEHICLE_OPTION} from '../../../env'
import {toast} from 'react-toastify'
import ReactSelect from "react-select";

export const AppointmentRequest = ({history}) => {
    const {isLoggedIn, userInfo} = useContext(AuthContext);
    return (
        <>
            {
                !isLoggedIn ? <AppointmentForm user={userInfo}/> : history.push('/signin')
            }
        </>
    )
}

function AppointmentForm({userInfo}) {

    const {register, handleSubmit, control, errors} = useForm();
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [allModels, setAllModels] = useState([]);

    const [maker, setMaker] = useState(null);
    const [model, setModel] = useState(null);
    const [year, setYear] = useState(null);
    const [years, setYears] = useState([]);
    const handleSetMaker = selectedOption => {
        setMaker(selectedOption);
        setModel(null);
        setModels(allModels.filter(key => key.option_type === VEHICLE_OPTION.MODEL && key.parent_id === selectedOption.value));
    };
    const handleSetModel = selectedOption => {
        setModel(selectedOption);
    };
    const handleSetYear = selectedOption => {
        setYear(selectedOption);
    };
    const onSubmit = async data => {
        data.maker = maker.value;
        data.model = model.value;
        data.year = year.value;
        const _res = await http('POST', 'appointment-request/save', data)
        console.log(_res)
        if (_res.ok) {
            toast.success('car finded ');
        } else
            toast.error(_res.message);
    }
    const makeYears = () => {
        let currentYear = new Date().getFullYear();
        let rows = [];
        for (let i = currentYear; i >= currentYear - 50; i--) {
            rows.push({value: i, label: i});
        }
        setYears(rows);
    }
    const getVehicleOptionData = async () => {
        try {
            const _res = await http('POST', 'vehicle_option_data/all');
            if (_res.ok) {
                const vehicleOptions = _res.data;
                setMakers(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.MAKER));
                setAllModels(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.MODEL));
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getVehicleOptionData();
        makeYears();
    }, [])

    return (
        <div className="container mt-5" id='appointment-form-component'>
            <div className="row">
                <div className="col-12 col-md-7">
                    <div className="card card-shadow shadow-sm">
                        <div className="card-header"><h4 className="text-primary">Appointment Request</h4></div>
                        <div className="card-body p-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <legend>Personal Information:</legend>
                                    <div className="form-group">
                                        <label>First name: <span className="error">*</span></label>
                                        <input type="text" name="first_name" autoFocus id="first_name" className="form-control"
                                               placeholder="Last name"
                                               ref={register({required: 'First name is required.'})}/>
                                        <span className="error">{errors.first_name && errors.first_name.message}</span>
                                    </div>
                                    <div className="form-group ">
                                        <label>Last name: <span className="error">*</span></label>
                                        <input type="text" name="last_name" id="last_name" className="form-control"
                                               placeholder="Last name"
                                               ref={register({required: 'Last name is required.'})}/>
                                        <span className="error">{errors.last_name && errors.last_name.message}</span>
                                    </div>
                                    <div className="form-group ">
                                        <label>email: </label>
                                        <input type="email" name="email" id="email" className="form-control"
                                               placeholder="Email" ref={register({required: 'Email is required.'})}/>
                                        <span className="error">{errors.email && errors.email.message}</span>
                                    </div>
                                    <div className="form-group ">
                                        <label>phone: </label>
                                        <input type="tel" name="phone" id="phone" className="form-control"
                                               placeholder="phone" ref={register({required: 'phone is required.'})}/>
                                        <span className="error">{errors.phone && errors.phone.message}</span>
                                    </div>
                                </fieldset>
                                <fieldset className="mt-5">
                                    <legend>Vehicle Information</legend>
                                    <div className="form-group">
                                        <label htmlFor="maker">Make</label>
                                        <Controller
                                            name="maker"
                                            placeholder="Make"
                                            as={ReactSelect}
                                            defaultValue={maker}
                                            options={makers}
                                            rules={{required: 'Make is required field'}}
                                            onChange={handleSetMaker}
                                            isSearchable
                                            control={control}>
                                        </Controller>
                                        <span className="error">{errors.maker && errors.maker.message}</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="model">Model</label>
                                        <Controller
                                            name="model"
                                            placeholder="Model"
                                            as={ReactSelect}
                                            defaultValue={model}
                                            options={models}
                                            rules={{required: 'Model is required field'}}
                                            onChange={handleSetModel}
                                            isSearchable
                                            control={control}>
                                        </Controller>
                                        <span className="error">{errors.model && errors.model.message}</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="year">Year</label>
                                        <Controller
                                            name="year"
                                            placeholder="Year"
                                            as={ReactSelect}
                                            defaultValue={year}
                                            options={years}
                                            rules={{required: 'Year is required field'}}
                                            onChange={handleSetYear}
                                            isSearchable
                                            control={control}>
                                        </Controller>
                                        <span className="error">{errors.year && errors.year.message}</span>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>vin: </label>
                                        <input className="form-control" type="text" name="vin"
                                               ref={register({required: 'vin is required'})}/>
                                        <span className="error">{errors.vin && errors.vin.message}</span>
                                    </div>
                                </fieldset>
                                <fieldset className="mt-5">
                                    <legend>Appointment Information</legend>
                                    <div className="form-group">
                                        <input type="date" name="date_request" id="date_request" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <span>Comments</span>
                                        <textarea name="description" placeholder="Description"
                                                  rows="8"
                                                  ref={register()}
                                                  className="form-control"/>
                                        <span
                                            className="error">{errors.description && errors.description.message}</span>
                                    </div>
                                </fieldset>

                                <button type="submit"
                                        className="btn btn-primary btn-raised btn-block">
                                    Check Availability
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 d-md-inline d-none text-center pt-4">
                    <img className="w-50" src="/images/calendar-animate.svg" alt=""/>
                    <h1 className="font-size-16 font-weight-bold text-primary">Set Appointment</h1>
                    <p> VFC AUTO GROUP offers a free, no obligation and confidential CarFinder tool,We Have Access to The Largest Inventory  Of Luxury Vehicles ,Our CARFINDER tool allows you to specify your desired Luxury or sports Car or SUV and submit the vehicle configuration to us. Once we locate matching automobiles, you automatically receive details of those models, with photographs included. Let us handle the task of finding your next dream car!</p>
                </div>
            </div>
        </div>
    )
}