import React, {useContext, useEffect, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {PostCategoryFormModal} from "./post_category_form_modal";
import SortableTree from "react-sortable-tree";
import 'react-sortable-tree/style.css';
import {AuthContext} from "../../../common/context/auth";
import {http} from "../../../env";
import $ from "jquery"
import {toast} from "react-toastify";

export const PostCategoryManagement = props => {
    const auth = useContext(AuthContext)
    const [isEdit, setIsEdit] = useState(false)
    const [modalTitle, setModalTitle] = useState('Add Category')
    const [postCategory, setPostCategory] = useState({id: '', title: '', parent_id: 0})
    const [divKey, setDivKey] = useState(Date.now())
    const [postsCategoryTree, setPostsCategoryTree] = useState([]);
    const getPostCategory = async () => {
        try {
            const _res = await http('GET', 'post_category/all')
            if (_res.ok) {
                setPostsCategoryTree(_res.data)
            } else console.log(_res.message)
        } catch (e) {
            console.log(e.message)
        }
    }
    useEffect(() => {
        getPostCategory()
    }, [])

    const callBack = async (data) => {
        if (isEdit) {
            await updateHandler(data);
        } else await saveHandler(data)
        setIsEdit(false)
        setPostCategory({id: '', title: '', parent_id: ''})
    }

    const openNewCatModal = (status, item) => {
        $('#categoryModal').on('shown.bs.modal', function () {
            $('#categoryTitle').trigger('focus')
        });
        let parent_id = item.id ? item.id : '0'
        setDivKey(Date.now)
        if (status) {
            setModalTitle(item.title)
            setPostCategory(item)
            setModalTitle('Edit ' + item.title + ' Category')
        } else {
            setPostCategory({id: '', title: '', parent_id: parent_id})
            setModalTitle('Add Category')
        }
        setIsEdit(status)
    }
    const saveHandler = async (data) => {
        const body = {
            user_id: auth.userInfo.id,
            title: data.title,
            parent_id: data.parent_id,
        };
        const _res = await http('POST', 'post_category/save', body)
        if (_res.ok) setPostsCategoryTree(_res.data)
    }
    const updateHandler = async (data) => {
        const body = {
            id: data.id,
            title: data.title,
            parent_id: data.parent_id,
        };
        const _res = await http('POST', 'post_category/update', body)
        if (_res.ok) {
            setPostsCategoryTree(_res.data)
        }
    }
    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-info mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'post_category/delete', item);
            if (_res.ok) setPostsCategoryTree(_res.data)
            else toast.error(_res.message)
        }
    }
    const updateRow = (item) => {
        setPostsCategoryTree(item);
    }
    const onDragRow = async (item) => {
        let data = {
            id: item.node.id,
            parent_id: item.nextParentNode ? item.nextParentNode.id : 0
        }
        const _res = await http('POST', 'post_category/sort-node-update', data)
        if (_res.ok) setPostsCategoryTree(_res.data)
        else console.log(_res.messages)

    }

    return (
        <div id='create-post-component'>
            <PostCategoryFormModal data={postCategory} divKey={divKey} formData={callBack} modalTitle={modalTitle}
                                   isEdit={isEdit}/>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card card-shadow">
                        <div className="card-header"><h4>List of post category</h4></div>
                        <div className="card-body">
                            <div style={{height: 500}}>
                                <SortableTree
                                    onMoveNode={treeData => onDragRow(treeData)}
                                    getNodeKey={({node}) => node.id}
                                    treeData={postsCategoryTree}
                                    onChange={treeData => updateRow(treeData)}
                                    generateNodeProps={({node, path}) => ({
                                        title: (
                                            <div>
                                                <span className='mr-3'>{node.title}</span>

                                                <span data-toggle="modal" data-target="#categoryModal"
                                                      onClick={() => openNewCatModal(true, node)}>
                                            <i
                                                className="material-icons hover pointer"
                                                data-toggle="tooltip" title="Edit">edit</i> </span>

                                                <span data-toggle="modal" data-target="#categoryModal"
                                                      onClick={() => openNewCatModal(false, node)}>
                                            <i
                                                className="material-icons hover pointer" data-toggle="tooltip"
                                                title="New">add</i> </span>

                                                {node.children && <span onClick={() => deleteItem(node)}>
                                            <i
                                                className="material-icons hover pointer"
                                                data-toggle="tooltip" title="delete">delete</i> </span>
                                                }
                                            </div>
                                        )
                                    })}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-circle-btn-fix" data-toggle="modal" data-target="#categoryModal"
                 onClick={() => openNewCatModal(false, {id: '', title: '', parent_id: 0})}>
                <i className="material-icons md-36 text-white">add</i>
            </div>
        </div>
    );
};