import React, {useEffect, useState} from 'react';
import {http} from "../../../env";
import {Link} from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

export const ViewBlog = (props) => {
    const [posts, setPosts] = useState([]);
    const [headerTitle, setHeaderTitle] = useState('');
    const [meta, setMeta] = useState({});

    const getPosts = async (post_type) => {
        if (post_type === 'articles') {
            post_type = 1;
            setHeaderTitle('Article List');
        } else if (post_type === 'news') {
            setHeaderTitle('News List');
            post_type = 0;
        } else
            post_type = null;
        try {
            if (post_type != null) {
                const _res = await http('POST', 'post/all-published', {post_type: post_type});
                console.log(_res)
                if (_res.ok) {
                    setMeta({
                        title: 'Hillz Autos |  Car classification',
                        description: 'HillzAutos Provides Latest Professional Articles About Car Classification on Different Brands Such As Ford, Dodge, Subaru, Honda, Benz, Toyota',
                        meta: {
                            name: {
                                keywords: 'blog'
                            },
                        },
                    })
                    setPosts(_res.data);
                } else console.log(_res.messages)
            } else setPosts(null);
        } catch (e) {
            console.log(e.messages)
        }
    }
    useEffect(() => {
        document.title = 'Hillz Autos |  Car classification'
        getPosts(props.match.params.post_type);
    }, [props.match.params.post_type])
    return (
        <>
            <DocumentMeta {...meta}>
                <div className="container-fluid">
                    <h1 className="mb-4 mt-5">{headerTitle}</h1>
                    <div className="row mt-4">
                        {posts && posts.map((item, key) =>
                            <div className="col-12 col-md-3 h-auto" key={item.id}>
                                <div className="card card-shadow mb-3">
                                    <div className="overflow-hidden">
                                        <div className="card-img-top"
                                             style={{backgroundImage: `url(${'/uploads/' + item.image_file})`}}/>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="font-weight-bold blog-title">
                                            <Link className="card-title"
                                                  to={`/Blog/${item.post_category.title}/${item.slug}`}>{item.title}</Link>
                                        </h5>
                                        <p className="card-text min-height-70 text-secondary mt-3">
                                            {item.description.length > 100 ? item.description.substring(0, 100) + '...' : item.description}
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <small className="text-muted">{new Intl.DateTimeFormat("en-GB", {
                                                year: "numeric",
                                                month: "long",
                                                day: "2-digit",
                                                hour: "numeric",
                                                minute: "numeric",
                                            }).format(new Date(item.publishAt).getTime())}</small>
                                            <Link className="card-title" to={`/blog/post/${item.slug}`}>
                                                <small className="mr-2">More</small>
                                                <i className="fas fa-arrow-right pt-1"/></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </DocumentMeta>
        </>
    );
};
