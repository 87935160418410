import React from 'react'
import {Route, Switch} from 'react-router-dom';
import {Dashboard} from "../../admin/dashbaord/dashbaord";
import {PostManagement} from "../../admin/posts/post_managemant";
import {PostCategoryManagement} from "../../admin/post-category/post_category";
import {RoutesCommon} from "./routes-common";
import {ChangePassword} from "../../admin/auth/change_password";
import {UserProfile} from "../../admin/auth/user_profile";
import {EditPage} from "../../admin/page/edit_page";
import {AuthorManage} from "../../admin/author/author_manage";
import {AddPost} from "../../admin/posts/add_post";
import {UserManagement} from "../../admin/auth/user_managment";
import {VehicleForm} from "../../admin/vehicles/form_vehicle";
import {VehicleManagement} from "../../admin/vehicles/vehicle_managemant";
import {VehiclePurchaseForm} from "../../admin/vehicles/form_vehicle_purchase";
import {VehiclePurchaseManagement} from "../../admin/vehicles/vehicle_purchase_managemant";
import {DealerShipForm} from "../../admin/dealer-ships/dealer_ship_form";
import {DealerShipManagement} from "../../admin/dealer-ships/dealer_ship_managemant";
import {CommentsList} from "../../admin/posts/comments_list";
import {NewslettersSubscribeList} from "../../admin/posts/newsletters-subscribe_list";
import {VendorForm} from "../../admin/vendors/vendor_form";
import {VendorManagement} from "../../admin/vendors/vendor_managemant";
import {RoleManagement} from "../../admin/role/role_manage";
import {UserForm} from "../../admin/auth/user_form";
import {CarFinderRequestManagement} from "../../admin/car-finder-request/car_finder_request_managemant";
import {Temp} from "../../admin/barcode/temp";
import {CarFinderRequestForm} from "../../admin/car-finder-request/car_finder_request_form";
import {AppointmentRequestManagement} from "../../admin/appointment-request/appointment_request_managment";
import {AppointmentRequestForm} from "../../admin/appointment-request/appointment_request_form";

export const RoutesAdmin = () => {
    return (
        <>
            <RoutesCommon/>
            <Route exact path={'/dashboard'} component={Dashboard}/>
            <Route exact path={'/user/change-password'} component={ChangePassword}/>
            <Route exact path={'/user/profile'} component={UserProfile}/>
            <Route exact path={'/admin/user-manage'} component={UserManagement}/>
            <Route exact path={'/admin/add-user'} component={UserForm}/>
            <Route exact path={'/admin/edit-user/:userId'} component={UserForm}/>
            <Route exact path={'/admin/add-post'} component={AddPost}/>
            <Route exact path={'/admin/edit-post/:postId/:catId'} component={AddPost}/>
            <Route exact path={'/admin/edit-dealer-ship/:dealerShipId'} component={DealerShipForm}/>
            <Route exact path={'/admin/add-dealer-ship'} component={DealerShipForm}/>
            <Route exact path={'/admin/post-manage'} component={PostManagement}/>
            <Route exact path={'/admin/dealer-ship-manage'} component={DealerShipManagement}/>
            <Route exact path={'/admin/add-vehicle'} component={VehicleForm}/>
            <Route exact path={'/admin/edit-vehicle/:vehicleId'} component={VehicleForm}/>
            <Route exact path={'/admin/vehicle-manage'} component={VehicleManagement}/>
            <Route exact path={'/admin/add-vendor'} component={VendorForm}/>
            <Route exact path={'/admin/edit-vendor/:vendorId'} component={VendorForm}/>
            <Route exact path={'/admin/vendor-manage'} component={VendorManagement}/>
            <Route exact path={'/admin/post-category-manage'} component={PostCategoryManagement}/>
            <Route exact path={'/admin/update-page/:slug'} component={EditPage}/>
            <Route exact path={'/admin/author-manage'} component={AuthorManage}/>
            <Route exact path={'/admin/comments-list'} component={CommentsList}/>
            <Route exact path={'/admin/newsletter_subscribe-list'} component={NewslettersSubscribeList}/>
            <Route exact path={'/admin/role-manage'} component={RoleManagement}/>
            <Route exact path={'/admin/car-finder'} component={CarFinderRequestManagement}/>
            <Route exact path={'/admin/car-finder-request-edit/:requestId'} component={CarFinderRequestForm}/>
            <Route exact path={'/admin/appointment-request'} component={AppointmentRequestManagement}/>
            <Route exact path={'/admin/appointment-request-edit/:requestId'} component={AppointmentRequestForm}/>
            <Route exact path={'/admin/temp'} component={Temp}/>

        </>
    )
};