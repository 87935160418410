import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {API_ADMIN_URL, http} from "../../../env";
import {confirmAlert} from "react-confirm-alert";
import Dropzone from "react-dropzone";

export const VehicleImagesModal = (props) => {
    const {vehicle, attachedFiles} = props;
    const [files, setFiles] = useState(attachedFiles);
    const [previews, setPreviews] = useState([]);
    const [progressbar, setProgressbar] = useState(0);
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };
    const thumb = {
        display: 'inline-flex',
        marginBottom: 8,
        marginRight: 8,
        width: 120,
        height: 120,
        padding: 4,
        boxSizing: 'border-box'
    };
    const deleteBtn={
        top: 0,
        right:0
    }
    useEffect(() => {
        setFiles(props.attachedFiles)
    }, [props.attachedFiles])

    const onDrop = acceptedFiles => {
        let tempFiles = [];
        acceptedFiles.map(file => {
            let imgObj = {
                name: file.name,
                preview: URL.createObjectURL(file)
            };
            tempFiles.push(imgObj)
        });
        setPreviews(tempFiles);
        const formData = new FormData();
        for (const file of acceptedFiles) formData.append('file', file);

        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = event => {
            const percentage = parseInt((event.loaded / event.total) * 100);
            setProgressbar(percentage); // Update progress here
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) return;
            if (xhr.status !== 200) {
                console.log('error'); // Handle error here
            }
            let response = JSON.parse(xhr.response)
            if (response.ok) {
                setPreviews([])
                setFiles(response.data)
                console.log('success'); // Handle success here
            }
        };
        xhr.open('POST', API_ADMIN_URL + 'vehicle_image/save/' + vehicle.id, true);
        xhr.setRequestHeader('access_token', `Bearer ${localStorage.getItem('token')}`);
        xhr.send(formData);
    }

    const onChangeIsDefault = async (value, item) => {
        try {
            await http('POST', 'vehicle_image/update-is-default', item);
        } catch (e) {
            console.log(e)
        }
    }

    const thumbs = files.map(file => (

        <div key={file.id}>
            <div style={thumb}>
                <div className="card card-shadow">
                    <div className="card-body p-2">
                        <img
                            src={'/uploads/' + file.file}
                            className="w-100 h-100" alt="" width="200"/>
                    </div>
                    <span onClick={() => deleteItem(file)} className="position-absolute" style={deleteBtn}>
                <i className="material-icons hover pointer mr-2"
                   data-toggle="tooltip" title="delete">delete</i> </span>
                    <input
                        className="form-check-input"
                        type="radio"
                        defaultChecked={file.is_default}
                        onChange={(value) => onChangeIsDefault(value, file)}
                        name="is_default"
                        value="1"/>
                </div>

            </div>
        </div>
    ));

    const thumbsPreview = previews.map(file => (
        <div style={thumb} key={file.name}>
            <div className="card card-shadow">
                <div className="card-body p-2">
                    <img
                        src={file.preview}
                        className="w-100 h-100" alt="" />
                </div>
                {progressbar}
            </div>
        </div>
    ));

    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1 className="text-primary">Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-white-border btn-raised mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success btn-raised" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'vehicle_image/delete', item);
            if (_res.ok) {
                setFiles(_res.data);
            } else console.log(_res.messages);
        }
    };
    return (
        <div className="card card-shadow">
            <div className="card-header-none">
                <h5 className="modal-title">List of files({files.length})</h5>
            </div>
            <div className="card-body">
                <Dropzone onDrop={onDrop}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                            <div className="progress mt-2">
                                <div className="progress-bar"
                                     style={{width: progressbar + '%'}}>{(progressbar !== 0 && progressbar !== 100 ? 'uploading...' : progressbar === 100 ? 'done!' : '')}</div>
                            </div>
                            <aside style={thumbsContainer}>
                                {thumbsPreview}
                                {thumbs}
                            </aside>
                        </section>
                    )}
                </Dropzone>
            </div>
        </div>
    );
};