import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm, Controller} from "react-hook-form";
import {CONDITION_LIST, http, SEARCH_PERIOD_LIST, VEHICLE_OPTION} from "../../../env";
import {toast} from "react-toastify";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";

export const AppointmentRequestForm = (props) => {

    const {register, handleSubmit, control, reset, errors} = useForm({});
    const [request_id] = useState(props.match.params.requestId);
    const [request, setRequest] = useState({});
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [years, setYears] = useState([]);
    const [users, setUsers] = useState([]);

    const [maker, setMaker] = useState(null);
    const [model, setModel] = useState(null);
    const [year, setYear] = useState(null);
    const [dataIsReady, setDataIsReady] = useState(false);
    const [user, setUser] = useState(null);

    const handleSetMaker = selectedOption => {
        setMaker(selectedOption);
        setModel(null);
        setModels(allModels.filter(key => key.option_type === VEHICLE_OPTION.MODEL && key.parent_id === selectedOption.value));
    };
    const handleSetModel = selectedOption => {
        setModel(selectedOption);
    };
    const handleSetYear = selectedOption => {
        setYear(selectedOption);
    };
    const handleSetUser = selectedOption => {
        setUser(selectedOption);
    };
    
    const getRequest = async () => {
        try {
            if (request_id && request_id !== '') {
                const _res = await http('POST', 'appointment_request/get-by-id', {id: request_id});
                if (_res.ok) {
                    _res.data = _res.data.request;
                    setRequest(_res.data);
                    reset(_res.data);
                    if (_res.data.maker) {
                        setMaker(makers.filter(key => key.value === parseInt(_res.data.maker))[0]);
                        setModels(allModels.filter(key => key.option_type === VEHICLE_OPTION.MODEL && key.parent_id === parseInt(_res.data.maker)));
                    }
                    // if (_res.data.user_id)
                    //     setUser(users.filter(key => key.value === parseInt(_res.data.user_id))[0]);
                    if (_res.data.model)
                        setModel(allModels.filter(key => key.value === parseInt(_res.data.model))[0]);
                    if (_res.data.year)
                        setYear(years.filter(key => key.value === parseInt(_res.data.year))[0]);
                } else console.log(_res.messages)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getRequestOptionData = async () => {
        try {
            const _res = await http('POST', 'request_option_data/all');
            if (_res.ok) {
                const requestOptions = _res.data;
                setMakers(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.MAKER));
                setAllModels(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.MODEL));
                setDataIsReady(true);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    const getUsers = async () => {
        try {
            const _res = await http('POST', 'user/all');
            if (_res.ok) {
                setUsers(_res.data);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    const makeYears = () => {
        let currentYear = new Date().getFullYear();
        let rows = [];
        for (let i = currentYear; i >= currentYear - 50; i--) {
            rows.push({value: i, label: i});
        }
        setYears(rows);
    }

    const onSubmit = async (data) => {
        data.maker = maker.value;
        data.model = model.value;
        data.year = year.value;
        // data.user_id = user.value;
        let _res;
        if (data.id)
            _res = await http('POST', 'car_finder_request/update', data);
        else
            _res = await http('POST', 'car_finder_request/save', data);

        if (_res.ok) {
            props.history.push({
                pathname: '/admin/appointment-request-edit/' + _res.data.id,
                search: ''
            });
            setRequest(_res.data);
            toast.success('Request saved successfully');
        } else
            toast.error('System error occurs. Please try again.');

    };

    useEffect(() => {
        getRequestOptionData();
        // getUsers();
        makeYears();
    }, [props.match.params.requestId]);

    useEffect(() => {
        if (dataIsReady) {
            getRequest();
            setDataIsReady(false);
        }
    }, [dataIsReady]);

    return (
        <div>
            <div className="card card-shadow flex-grow-1 w-100">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className=""><i
                        className="fas fa-car opacity-78 mr-3"/>{request_id ? 'Edit Request' : 'Create Request'}
                    </h4>
                </div>
            </div>
            <ul className="nav nav-tabs border-bottom-0 align-items-center mt-4" id="BlogTab"
                role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#RequestInformation" role="tab"
                       aria-controls="RequestInformation" aria-selected="true"><i className="fas fa-car mr-2"/>Request
                        information
                    </a>
                    <div className="line"/>
                </li>
            </ul>
            <div className="row">
                <div className="col-12 tab-content pt-0">
                    <div className="tab-pane fade show active" id="RequestInformation" role="tabpanel">
                        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                            <div className="card card-shadow">
                                <div className="card-header-none text-right">
                                    <button type="submit" className="btn btn-primary btn-raised"><i
                                        className="fas fa-save mr-2"/>Save Request
                                    </button>
                                </div>
                                <div className="card-body">
                                    <input type="hidden" name="id" defaultValue={request.id} ref={register()}/>
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="user">User</label>*/}
                                            {/*    <Controller*/}
                                            {/*        name="user"*/}
                                            {/*        render={({}) => (*/}
                                            {/*            <ReactSelect*/}
                                            {/*                placeholder="User"*/}
                                            {/*                onChange={handleSetUser}*/}
                                            {/*                isSearchable*/}
                                            {/*                options={users}*/}
                                            {/*                value={user}*/}
                                            {/*            />*/}
                                            {/*        )}*/}
                                            {/*        rules={{required: 'User is required field'}}*/}
                                            {/*        control={control}/>*/}
                                            {/*    <span className="error">{errors.user && errors.user.message}</span>*/}
                                            {/*</div>*/}

                                            <div className="form-group">
                                                <label htmlFor="year">Year</label>
                                                <Controller
                                                    name="year"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Year"
                                                            onChange={handleSetYear}
                                                            isSearchable
                                                            options={years}
                                                            value={year}
                                                        />
                                                    )}
                                                    rules={{required: 'Year is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.year && errors.year.message}</span>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="maker">Make</label>
                                                <Controller
                                                    name="maker"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Make"
                                                            onChange={handleSetMaker}
                                                            isSearchable
                                                            options={makers}
                                                            value={maker}
                                                        />
                                                    )}
                                                    rules={{required: 'Make is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.maker && errors.maker.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="model">Model</label>
                                                <Controller
                                                    name="model"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Model"
                                                            onChange={handleSetModel}
                                                            isSearchable
                                                            options={models}
                                                            value={model}
                                                        />
                                                    )}
                                                    rules={{required: 'Model is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.model && errors.model.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="comment">Comment</label>
                                                <textarea name="comment" placeholder="Comment"
                                                          rows="4"
                                                          ref={register()}
                                                          defaultValue={request.comment}
                                                          className="form-control"/>
                                                <span
                                                    className="error">{errors.comment && errors.comment.message}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-raised"><i
                                        className="fas fa-save mr-2 bt-1"/>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};