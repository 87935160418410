import React, {createContext, useState} from 'react'

export const AuthContext = createContext({
    isLoggedIn: false,
    isLoading: false,
    userInfo: [],
    status: ""
})


export const AuthContextProvider = props => {
    const [state, setState] = useState({
        isLoggedIn: false,
        isLoading: false,
        userInfo: [],
    });
    const setLoggedIn = value => setState({...state, isLoggedIn: value})
    const setIsLoading = value => setState({...state, isLoading: value})
    const setStatus = value => setState({...state, status: value})

    return (
        <AuthContext.Provider
            value={{...state, setLoggedIn: setLoggedIn, setIsLoading: setIsLoading, setStatus: setStatus}}>
            {props.children}
        </AuthContext.Provider>
    );
}


const get = () => localStorage.getItem('token');
export const decode = payload => JSON.parse(atob(payload));
export const getPayload = (token) => {
    if (token) {
        const payload = token.split('.')[1];
        return decode(payload);
    } else {
        return false;
    }
}
export const isValidToken = () => {
    const token = get();
    if (!token) return false;
    if (token) {
        const payload = getPayload(token);
        const date = getTokenExpirationDate(payload);
        return new Date().valueOf() < date.valueOf();
    } else {
        return true;
    }
}
export const getUserInfo = () => {
    const token = localStorage.getItem('token');
    if (!token) return [];
    const payload = token.split('.')[1];
    return decode(payload);
};
const getTokenExpirationDate = (payload) => {
    if ('exp' in payload) {
        const date = new Date(0);
        date.setUTCSeconds(payload['exp']);
        return date;
    } else {
        return null;
    }
}
