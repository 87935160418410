import React from 'react'
import './button.scss'
import {Link} from "react-router-dom";

export const Button = props => {
    if (props.href) {
        return (
            <a className={props.className} href={props.href}>
                {props.children}
            </a>
        )
    }
    if (props.to) {
        return (
            <Link to={props.to} exact={props.exact} className={props.className}>
                {props.children}
            </Link>
        )
    }
    return (
        <button className={props.className} type={props.type} onClick={props.onClick} disabled={props.disabled}>{props.children}</button>
    )

}
