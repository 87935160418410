import React from "react";
import {useForm} from "react-hook-form";
import {http} from "../../../env";
import {toast} from "react-toastify";

export const ChangePassword = () => {
    const {register, handleSubmit, watch, errors} = useForm();
    const changePassHandler = async data => {
        console.log(data);
        let _res = await http('POST', 'auth/changePassword', data);
        console.log(_res);
        if (_res.ok) {
            toast.success('Update completed successfully')
        } else {
            toast.error(_res.message);
        }
    };
    return (
        <div className="row">
            <div className="col-12 col-md-7 col-lg-6 m-auto">
                <div className="card card-shadow mt-5 o-hidden mb-4">
                    <div className="p-3 text-center">
                        <i className="fas fa-lock fa-4x opacity-48"/>
                        <h4 className="my-2 text-secondary">Change Password</h4>
                    </div>
                    <div className="card-body">
                        <form action="#" id="changePasswordForm" onSubmit={handleSubmit(changePassHandler)}>
                            <span className="error">{errors.currentPassword && "Current password is required"}</span>
                            <div className="input-group  mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i
                                        className="fas fa-lock opacity-38"/></span>
                                </div>
                                <input type="password" name="currentPassword"
                                       className="form-control"
                                       placeholder="Current Password"
                                       ref={register({required: true, minLength: 3})}/>
                            </div>

                            <span
                                className="error">{errors.newPassword?.type === "required" && "New password is required"}</span>
                            <span
                                className="error">{errors.newPassword?.type === "minLength" && "Password required to be more than 3"}</span>

                            <div className="input-group  mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i
                                        className="fas fa-fingerprint opacity-38"/></span>
                                </div>
                                <input type="password" name="newPassword"
                                       className="form-control" placeholder="New Password"
                                       ref={register({required: true, minLength: 3})}/>

                            </div>
                            <span
                                className="error">{errors.confirmPassword?.type === "required" && "Confirm password is required"}</span>
                            <span
                                className="error">{errors.confirmPassword?.type === "validate" && "Passwords don't match."}</span>
                            <div className="input-group  mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i
                                        className="fas fa-fingerprint opacity-38"/></span>
                                </div>
                                <input type="password" name="confirmPassword"
                                       className="form-control" placeholder="Confirm Password"
                                       ref={register({
                                           required: true,
                                           validate: (value) => value === watch('newPassword') || "Passwords don't match."
                                       })}
                                />
                            </div>

                            <div className="form-group">
                                <button type="submit"
                                        className="btn btn-primary btn-raised btn-block"
                                ><i
                                    className="fas fa-save mr-2"/>Change Password
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

};
