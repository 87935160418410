import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter as Router, Link} from "react-router-dom";
import {RoutesAdmin} from "../routes/routes-admin";
import {AuthContext, AuthContextProvider, getUserInfo, isValidToken} from "../../../common/context/auth";
import {NavbarAdmin} from "../../admin/navbar-admin/navbar-admin";
import {Toast} from '../../common/toast/toast-container';
import '../../../assets/sass/admin.scss';
import '../../../assets/js/dashboard'
import {http} from "../../../env";

export const AdminLayout = () => {
    let {user_id, email, username, fullName, avatar, role} = '';
    const [path, setPath] = useState(window.location.pathname);
    const [countNotApprovedComment, setCountNotApprovedComment] = useState(0)
    const auth = useContext(AuthContext)
    const {setLoggedIn} = useContext(AuthContext)
    if (isValidToken()) {
        auth.isLoggedIn = true;
        auth.userInfo = getUserInfo();
        // username = auth.userInfo['username'];
        fullName = `${auth.userInfo['name']} ${auth.userInfo['family']}`;
        avatar = auth.userInfo['avatar'];
        role = auth.userInfo['role'];
        // email = auth.userInfo['email'];
        // user_id = auth.userInfo['id'];
    }
    const getCountNotApprovedComments = async () => {
        let _res = await http('GET', 'post_comment/get-count-not-approved')
        if (_res.ok) {
            setCountNotApprovedComment(_res.data)
            console.log(_res)
        }
    }
    const logout = () => {
        localStorage.clear()
        setLoggedIn(false)
        window.location = '/';
    }
    const navLinkActive = (value) => {
        setPath(value);
    }
    useEffect(() => {
        getCountNotApprovedComments();
        setPath(window.location.pathname);
    }, [window.location.pathname])
    return (
        <AuthContextProvider>
            <Router>
                <NavbarAdmin/>
                <Toast/>
                <main className="wrapper">
                    <div id="sidebarContainer mx-3">
                        <nav id="sidebar">
                            <div className="d-flex flex-column justify-content-center align-items-center my-4">
                                <img src={avatar ? `/uploads/${avatar}` : '/images/profile-avatar.svg'}
                                     alt={fullName} className="rounded"
                                     width="70"/>
                                <span className="font-weight-bold">{fullName}</span>
                                <span>{role}</span>
                            </div>
                            <ul className="accordion list-unstyled px-0 pb-5 " id="accordionMenu">
                                <li className="nav-head">
                                    <a data-toggle="collapse" data-target="#homeSubmenu"
                                       aria-expanded={`${(!!(path.match('/user/change-password') || path.match('/user/profile') || path.match('/admin/user-manage')))}`}
                                       className="collapsed">
                                        <h6 className="d-flex justify-content-between">
                                            <span><i
                                                className="fas fa-user-alt mr-2"/><span>User Accounting </span></span>
                                            <i className="fas fa-angle-down rotate-icon"/>
                                        </h6>
                                    </a>
                                    <ul className={`collapse nav pr-0${(path.match('/user/change-password') || path.match('/user/profile') || path.match('/admin/user-manage') || path.match('/admin/add-user') || path.match('/admin/edit-user') || path.match('/admin/role-manage')) ? " show" : ""}`}
                                        data-parent="#accordionMenu"
                                        id="homeSubmenu">
                                        <li className="nav-item">
                                            <Link to="/user/profile" onClick={() => navLinkActive('/user/profile')}
                                                  className={`${path.match('/user/profile') ? 'nav-link active' : 'nav-link'}`}>
                                                <i className="far fa-user mr-2"/>User Profile
                                            </Link>
                                        </li>
                                        <li className="nav-item ">
                                            <Link to="/user/change-password"
                                                  onClick={() => navLinkActive('/user/change-password')}
                                                  className={`${path.match('/user/change-password') ? 'nav-link active' : 'nav-link'}`}>
                                                <i className="fas fa-lock mr-2"/>Change Password
                                            </Link>
                                        </li>
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/add-user"*/}
                                                  {/*onClick={() => navLinkActive('/admin/add-user')}*/}
                                                  {/*className={`${path.match('/admin/add-user') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i className="fas fa-users mr-2"/>Add User*/}
                                            {/*</Link>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/user-manage"*/}
                                                  {/*onClick={() => navLinkActive('/admin/user-manage')}*/}
                                                  {/*className={`${path.match('/admin/user-manage') || path.match('/admin/edit-user') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i className="fas fa-users mr-2"/>User Manage*/}
                                            {/*</Link>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/role-manage"*/}
                                                  {/*onClick={() => navLinkActive('/admin/role-manage')}*/}
                                                  {/*className={`${path.match('/admin/role-manage') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i className="fas fa-person-booth mr-2"/>Role Manage*/}
                                            {/*</Link>*/}
                                        {/*</li>*/}
                                    </ul>
                                </li>
                                {/*<li className="nav-head">*/}
                                    {/*<a data-toggle="collapse" data-target="#page"*/}
                                       {/*aria-expanded={`${(!!(path.match('/admin/update-page/about') || path.match('/admin/update-page/team')))}`}*/}
                                       {/*className="collapsed">*/}
                                        {/*<h6 className="d-flex justify-content-between">*/}
                                            {/*<span>*/}
                                                {/*<i className="far fa-comment-alt mr-2"/>*/}
                                                {/*<span>Content Manage</span>*/}
                                            {/*</span>*/}
                                            {/*<i className="fas fa-angle-down rotate-icon"/>*/}
                                        {/*</h6>*/}
                                    {/*</a>*/}
                                    {/*<ul className={`collapse nav pr-0${(path.match('/admin/update-page/about') || path.match('/admin/update-page/team')) ? " show" : ""}`}*/}
                                        {/*data-parent="#accordionMenu" id="page">*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/update-page/about"*/}
                                                  {/*onClick={() => navLinkActive('/admin/update-page/about')}*/}
                                                  {/*className={`${path.match('/admin/update-page/about') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i className="far fa-comment-alt mr-2"/>About Us Page*/}
                                            {/*</Link>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/update-page/team"*/}
                                                  {/*onClick={() => navLinkActive('/admin/update-page/team')}*/}
                                                  {/*className={`${path.match('/admin/update-page/team') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i className="fas fa-users mr-2"/>Team Page</Link>*/}
                                        {/*</li>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                <li className="nav-head">
                                    <a data-toggle="collapse" data-target="#blog"
                                       aria-expanded={`${(!!(path.match('/admin/author-manage') || path.match('/admin/post-category-manage') || path.match('/admin/add-post') || path.match('/admin/edit-post') || path.match('/admin/post-manage') || path.match('/admin/comments-list') || path.match('/admin/newsletter_subscribe-list')))}`}
                                       className="collapsed">
                                        <h6 className="d-flex justify-content-between">
                                            <span><i className="fas fa-flag mr-2"/><span>Blog Manage</span></span>
                                            <i className="fas fa-angle-down rotate-icon"/>
                                        </h6>
                                    </a>
                                    <ul className={`collapse nav pr-0${(path.match('/admin/author-manage') || path.match('/admin/post-category-manage') || path.match('/admin/add-post') || path.match('/admin/edit-post') || path.match('/admin/post-manage') || path.match('/admin/comments-list') || path.match('/admin/newsletter_subscribe-list')) ? " show" : ""}`}
                                        data-parent="#accordionMenu" id="blog">
                                        <li className="nav-item">
                                            <Link to="/admin/author-manage"
                                                  onClick={() => navLinkActive('/admin/author-manage')}
                                                  className={`${path.match('/admin/author-manage') ? 'nav-link active' : 'nav-link'}`}>
                                                <i className="fas fa-user-circle mr-2"/>Author Manage</Link></li>
                                        <li className="nav-item">
                                            <Link to="/admin/post-category-manage"
                                                  onClick={() => navLinkActive('/admin/post-category-manage')}
                                                  className={`${path.match('/admin/post-category-manage') ? 'nav-link active' : 'nav-link'}`}>
                                                <i className="fas fa-project-diagram mr-2"/>Category Manage</Link></li>
                                        <li className="nav-item">
                                            <Link to="/admin/add-post"
                                                  onClick={() => navLinkActive('/admin/add-post')}
                                                  className={`${path.match('/admin/add-post') ? 'nav-link active' : 'nav-link'}`}>
                                                <i
                                                    className="fas fa-pen-alt mr-2"/>Add Post</Link></li>
                                        <li className="nav-item">
                                            <Link to="/admin/post-manage"
                                                  onClick={() => navLinkActive('/admin/post-manage')}
                                                  className={`${path.match('/admin/post-manage') || path.match('/admin/edit-post') ? 'nav-link active' : 'nav-link'}`}>
                                                <i
                                                    className="fas fa-book mr-2"/>Posts Manage</Link></li>
                                        <li className="nav-item">
                                            <Link to="/admin/comments-list"
                                                  onClick={() => navLinkActive('/admin/comments-list')}
                                                  className={`${path.match('/admin/comments-list') ? 'nav-link active' : 'nav-link'}`}>
                                                <i
                                                    className="far fa-comments mr-2"/>Comments
                                                List <span
                                                className="ml-2 badge badge-info">{countNotApprovedComment}</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/newsletter_subscribe-list"
                                                  onClick={() => navLinkActive('/admin/newsletter_subscribe-list')}
                                                  className={`${path.match('/admin/newsletter_subscribe-list') ? 'nav-link active' : 'nav-link'}`}>
                                                <i
                                                    className="far fa-envelope mr-2"/>subscribe List</Link></li>
                                    </ul>
                                </li>
                                {/*<li className="nav-head">*/}
                                    {/*<a data-toggle="collapse" data-target="#vehicle"*/}
                                       {/*aria-expanded={`${(!!(path.match('/admin/add-vehicle') || path.match('/admin/edit-vehicle') || path.match('/admin/vehicle-manage')))}`}*/}
                                       {/*className="collapsed">*/}
                                        {/*<h6 className="d-flex justify-content-between">*/}
                                            {/*<span><i className="fas fa-car mr-2"/><span>Vehicle Manage</span></span>*/}
                                            {/*<i className="fas fa-angle-down rotate-icon"/>*/}
                                        {/*</h6>*/}
                                    {/*</a>*/}
                                    {/*<ul className={`collapse nav pr-0${(path.match('/admin/add-vehicle') || path.match('/admin/edit-vehicle') || path.match('/admin/vehicle-manage')) ? " show" : ""}`}*/}
                                        {/*data-parent="#accordionMenu" id="vehicle">*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/add-vehicle"*/}
                                                  {/*onClick={() => navLinkActive('/admin/add-vehicle')}*/}
                                                  {/*className={`${path.match('/admin/add-vehicle') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i*/}
                                                    {/*className="fas fa-plus mr-2"/>Add Vehicle</Link></li>*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/vehicle-manage"*/}
                                                  {/*onClick={() => navLinkActive('/admin/vehicle-manage')}*/}
                                                  {/*className={`${path.match('/admin/vehicle-manage') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i*/}
                                                    {/*className="fas fa-car-side mr-2"/>Vehicles Manage</Link></li>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="nav-head">*/}
                                    {/*<a data-toggle="collapse" data-target="#dealer-ship"*/}
                                       {/*aria-expanded={`${(!!(path.match('/admin/add-dealer-ship') || path.match('/admin/edit-dealer-ship') || path.match('/admin/dealer-ship-manage')))}`}*/}
                                       {/*className="collapsed">*/}
                                        {/*<h6 className="d-flex justify-content-between">*/}
                                            {/*<span><i*/}
                                                {/*className="fas fa-business-time mr-2"/><span>Dealer Ship Manage</span></span>*/}
                                            {/*<i className="fas fa-angle-down rotate-icon"/>*/}
                                        {/*</h6>*/}
                                    {/*</a>*/}
                                    {/*<ul className={`collapse nav pr-0${(path.match('/admin/add-dealer-ship') || path.match('/admin/edit-dealer-ship') || path.match('/admin/dealer-ship-manage')) ? " show" : ""}`}*/}
                                        {/*data-parent="#accordionMenu" id="dealer-ship">*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/add-dealer-ship"*/}
                                                  {/*onClick={() => navLinkActive('/admin/add-dealer-ship')}*/}
                                                  {/*className={`${path.match('/admin/add-dealer-ship') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i*/}
                                                    {/*className="fas fa-plus mr-2"/>Add Dealer Ship</Link></li>*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/dealer-ship-manage"*/}
                                                  {/*onClick={() => navLinkActive('/admin/dealer-ship-manage')}*/}
                                                  {/*className={`${path.match('/admin/dealer-ship-manage') || path.match('/admin/edit-dealer-ship') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i*/}
                                                    {/*className="fas fa-business-time mr-2"/>Dealer Ships Manage</Link>*/}
                                        {/*</li>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="nav-head">*/}
                                    {/*<a data-toggle="collapse" data-target="#carFinderRequest"*/}
                                       {/*aria-expanded={`${(!!(path.match('/admin/car-finder')))}`}*/}
                                       {/*className="collapsed">*/}
                                        {/*<h6 className="d-flex justify-content-between">*/}
                                            {/*<span><i*/}
                                                {/*className="fas fa-file-signature mr-2"/><span>Request Center</span></span>*/}
                                            {/*<i className="fas fa-angle-down rotate-icon"/>*/}
                                        {/*</h6>*/}
                                    {/*</a>*/}
                                    {/*<ul className={`collapse nav pr-0${(path.match('/admin/car-finder')) ? " show" : ""}`}*/}
                                        {/*data-parent="#accordionMenu" id="carFinderRequest">*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<Link to="/admin/car-finder"*/}
                                                  {/*onClick={() => navLinkActive('/admin/car-finder')}*/}
                                                  {/*className={`${path.match('/admin/car-finder') ? 'nav-link active' : 'nav-link'}`}>*/}
                                                {/*<i*/}
                                                    {/*className="fas fa-file mr-2"/>Car Find Requests</Link></li>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                <hr className="my-0"/>
                                <li className="nav-head border-0">
                                    <a href="/" className="nav-link" onClick={logout}><i
                                        className="fas fa-power-off mr-2"/>Sign Out</a>

                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div id="content" className="p-4">
                        <div className="container-fluid px-0">
                            <RoutesAdmin/>
                        </div>
                    </div>
                </main>
            </Router>
        </AuthContextProvider>
    );
};