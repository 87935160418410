import React, {useEffect, useRef, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Result from "../barcode/Result";
import Scanner from "../barcode/Scanner";

export const BarcodeScannerModal = (props) => {
    const {setResults, results} = props;
    const [scanning, setScanning] = useState(false);
    const scannerRef = useRef(null);

    return (
        <div className="modal fade" id="barcodeScannerModal" aria-labelledby="barcodeScannerModal" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <h5 className="modal-title mr-2">Scan VIN Barcode</h5>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body p-3">
                        <button onClick={() => setScanning(!scanning)}>{scanning ? 'Stop' : 'Start'}</button>
                        <ul className="results">
                            {results.map((result) => (result.codeResult &&
                                <Result key={result.codeResult.code} result={result}/>))}
                        </ul>
                        <div ref={scannerRef} style={{border: '3px solid red'}}>
                            {/* <video style={{ width: window.innerWidth, height: 480, border: '3px solid orange' }}/> */}
                            <canvas className="drawingBuffer" style={{
                                // position: 'absolute',
                                top: '0px',
                                // left: '0px',
                                height: '100%',
                                width: '100%',
                                border: '3px solid green',
                            }}/>
                            {scanning ?
                                <Scanner scannerRef={scannerRef}
                                         onDetected={(result) => setResults([...results, result])}/> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


