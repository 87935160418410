import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm, Controller} from "react-hook-form";
import {CONDITION_LIST, http, SEARCH_PERIOD_LIST, VEHICLE_OPTION, VEHICLE_STATUS_LIST} from "../../../env";
import {toast} from "react-toastify";
import ReactSelect from "react-select";
import NumberFormat from "react-number-format";

export const CarFinderRequestForm = (props) => {

    const {register, handleSubmit, control, reset, errors} = useForm({});
    const [request_id] = useState(props.match.params.requestId);
    const [request, setRequest] = useState({});
    const [price, setPrice] = useState(null);
    const [bodyStyles, setBodyStyles] = useState([]);
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [years, setYears] = useState([]);
    const [trims, setTrims] = useState([]);
    const [colors, setColors] = useState([]);
    const [transmissionTypes, setTransmissionTypes] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [users, setUsers] = useState([]);
    const [conditions, setConditions] = useState(CONDITION_LIST);
    const [drivelines, setDrivelines] = useState();
    const [searchPeriods, setSearchPeriods] = useState(SEARCH_PERIOD_LIST);

    const [maker, setMaker] = useState(null);
    const [model, setModel] = useState(null);
    const [year, setYear] = useState(null);
    const [trim, setTrim] = useState(null);
    const [color, setColor] = useState(null);
    const [transmissionType, setTransmissionType] = useState(null);
    const [fuelType, setFuelType] = useState(null);
    const [bodyStyle, setBodyStyle] = useState(null);
    const [dataIsReady, setDataIsReady] = useState(false);
    const [user, setUser] = useState(null);
    const [condition, setCondition] = useState(null);
    const [driveline, setDriveline] = useState(null);
    const [searchPeriod, setSearchPeriod] = useState(null);

    const handleSetMaker = selectedOption => {
        setMaker(selectedOption);
        setModel(null);
        setModels(allModels.filter(key => key.option_type === VEHICLE_OPTION.MODEL && key.parent_id === selectedOption.value));
    };
    const handleSetModel = selectedOption => {
        setModel(selectedOption);
    };
    const handleSetYear = selectedOption => {
        setYear(selectedOption);
    };
    const handleSetTrim = selectedOption => {
        setTrim(selectedOption);
    };
    const handleSetColor = selectedOption => {
        setColor(selectedOption);
    };
    const handleSetTransmissionType = selectedOption => {
        setTransmissionType(selectedOption);
    };
    const handleSetFuelType = selectedOption => {
        setFuelType(selectedOption);
    };
    const handleSetBodyStyles = selectedOption => {
        setBodyStyle(selectedOption);
    };
    const handleSetCondition = selectedOption => {
        setCondition(selectedOption);
    };
    const handleSetDriveline = selectedOption => {
        setDriveline(selectedOption);
    };
    const handleSetSearchPeriod = selectedOption => {
        setSearchPeriod(selectedOption);
    };
    const handleSetUser = selectedOption => {
        setUser(selectedOption);
    };

    const onChangePrice = value => setPrice(value.value);

    const getRequest = async () => {
        try {
            if (request_id && request_id !== '') {
                const _res = await http('POST', 'car_finder_request/get-by-id', {id: request_id});
                if (_res.ok) {
                    _res.data = _res.data.request;
                    setRequest(_res.data);
                    reset(_res.data);
                    setPrice(_res.data.price ? _res.data.price : null);
                    if (_res.data.maker) {
                        setMaker(makers.filter(key => key.value === parseInt(_res.data.maker))[0]);
                        setModels(allModels.filter(key => key.option_type === VEHICLE_OPTION.MODEL && key.parent_id === parseInt(_res.data.maker)));
                    }
                    // if (_res.data.user_id)
                    //     setUser(users.filter(key => key.value === parseInt(_res.data.user_id))[0]);
                    if (_res.data.model)
                        setModel(allModels.filter(key => key.value === parseInt(_res.data.model))[0]);
                    if (_res.data.year)
                        setYear(years.filter(key => key.value === parseInt(_res.data.year))[0]);
                    if (_res.data.trim)
                        setTrim(trims.filter(key => key.value === parseInt(_res.data.trim))[0]);
                    if (_res.data.body_style)
                        setBodyStyle(bodyStyles.filter(key => key.value === parseInt(_res.data.body_style))[0]);
                    if (_res.data.color)
                        setColor(colors.filter(key => key.value === parseInt(_res.data.color))[0]);
                    if (_res.data.transmission)
                        setTransmissionType(transmissionTypes.filter(key => key.value === parseInt(_res.data.transmission))[0]);
                    if (_res.data.search_period)
                        setSearchPeriod(searchPeriods.filter(key => key.value === parseInt(_res.data.search_period))[0]);
                    if (_res.data.driveline)
                        setDriveline(drivelines.filter(key => key.value === parseInt(_res.data.driveline))[0]);
                    if (_res.data.condition)
                        setCondition(conditions.filter(key => key.value === _res.data.condition)[0]);
                    if (_res.data.fuel_type)
                        setFuelType(fuelTypes.filter(key => key.value === parseInt(_res.data.fuel_type))[0]);
                } else console.log(_res.messages)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getRequestOptionData = async () => {
        try {
            const _res = await http('POST', 'request_option_data/all');
            if (_res.ok) {
                const requestOptions = _res.data;
                setBodyStyles(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.BODY_STYLE));
                setMakers(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.MAKER));
                setAllModels(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.MODEL));
                setColors(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.COLOR));
                setTrims(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.TRIM));
                setTransmissionTypes(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.TRANSMISSION_TYPE));
                setFuelTypes(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.FUEL_TYPE));
                setDrivelines(requestOptions.filter(key => key.option_type === VEHICLE_OPTION.DRIVE_LINE));
                setDataIsReady(true);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    const getUsers = async () => {
        try {
            const _res = await http('POST', 'user/all');
            if (_res.ok) {
                setUsers(_res.data);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    const makeYears = () => {
        let currentYear = new Date().getFullYear();
        let rows = [];
        for (let i = currentYear; i >= currentYear - 50; i--) {
            rows.push({value: i, label: i});
        }
        setYears(rows);
    }

    const onSubmit = async (data) => {
        data.maker = maker.value;
        data.model = model.value;
        data.year = year.value;
        data.trim = trim.value;
        data.color = color.value;
        data.transmission = transmissionType.value;
        data.fuel_type = fuelType.value;
        data.body_style = bodyStyle.value;
        data.search_period = searchPeriod.value;
        data.driveline = driveline.value;
        data.condition = condition.value;
        data.price = price;
        // data.user_id = user.value;
        let _res;
        if (data.id)
            _res = await http('POST', 'car_finder_request/update', data);
        else
            _res = await http('POST', 'car_finder_request/save', data);

        if (_res.ok) {
            props.history.push({
                pathname: '/admin/car-finder-request-edit/' + _res.data.id,
                search: ''
            });
            setRequest(_res.data);
            toast.success('Request saved successfully');
        } else
            toast.error('System error occurs. Please try again.');

    };

    useEffect(() => {
        getRequestOptionData();
        // getUsers();
        makeYears();
    }, [props.match.params.requestId]);

    useEffect(() => {
        if (dataIsReady) {
            getRequest();
            setDataIsReady(false);
        }
    }, [dataIsReady]);

    return (
        <div>
            <div className="card card-shadow flex-grow-1 w-100">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className=""><i
                        className="fas fa-car opacity-78 mr-3"/>{request_id ? 'Edit Request' : 'Create Request'}
                    </h4>
                </div>
            </div>
            <ul className="nav nav-tabs border-bottom-0 align-items-center mt-4" id="BlogTab"
                role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#RequestInformation" role="tab"
                       aria-controls="RequestInformation" aria-selected="true"><i className="fas fa-car mr-2"/>Request
                        information
                    </a>
                    <div className="line"/>
                </li>
            </ul>
            <div className="row">
                <div className="col-12 tab-content pt-0">
                    <div className="tab-pane fade show active" id="RequestInformation" role="tabpanel">
                        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                            <div className="card card-shadow">
                                <div className="card-header-none text-right">
                                    <button type="submit" className="btn btn-primary btn-raised"><i
                                        className="fas fa-save mr-2"/>Save Request
                                    </button>
                                </div>
                                <div className="card-body">
                                    <input type="hidden" name="id" defaultValue={request.id} ref={register()}/>
                                    <div className="row">
                                        <div className="col-12 col-md-6">

                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="user">User</label>*/}
                                            {/*    <Controller*/}
                                            {/*        name="user"*/}
                                            {/*        render={({}) => (*/}
                                            {/*            <ReactSelect*/}
                                            {/*                placeholder="User"*/}
                                            {/*                onChange={handleSetUser}*/}
                                            {/*                isSearchable*/}
                                            {/*                options={users}*/}
                                            {/*                value={user}*/}
                                            {/*            />*/}
                                            {/*        )}*/}
                                            {/*        rules={{required: 'User is required field'}}*/}
                                            {/*        control={control}/>*/}
                                            {/*    <span className="error">{errors.user && errors.user.message}</span>*/}
                                            {/*</div>*/}

                                            <div className="form-group">
                                                <label htmlFor="year">Year</label>
                                                <Controller
                                                    name="year"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Year"
                                                            onChange={handleSetYear}
                                                            isSearchable
                                                            options={years}
                                                            value={year}
                                                        />
                                                    )}
                                                    rules={{required: 'Year is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.year && errors.year.message}</span>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="maker">Make</label>
                                                <Controller
                                                    name="maker"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Make"
                                                            onChange={handleSetMaker}
                                                            isSearchable
                                                            options={makers}
                                                            value={maker}
                                                        />
                                                    )}
                                                    rules={{required: 'Make is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.maker && errors.maker.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="model">Model</label>
                                                <Controller
                                                    name="model"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Model"
                                                            onChange={handleSetModel}
                                                            isSearchable
                                                            options={models}
                                                            value={model}
                                                        />
                                                    )}
                                                    rules={{required: 'Model is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.model && errors.model.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="body_style">Body Style</label>
                                                <Controller
                                                    name="body_style"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Body Style"
                                                            onChange={handleSetBodyStyles}
                                                            isSearchable
                                                            options={bodyStyles}
                                                            value={bodyStyle}
                                                        />
                                                    )}
                                                    rules={{required: 'Body Style is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.body_style && errors.body_style.message}</span>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="color">Color</label>
                                                <Controller
                                                    name="color"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Color"
                                                            onChange={handleSetColor}
                                                            isSearchable
                                                            options={colors}
                                                            value={color}
                                                        />
                                                    )}
                                                    rules={{required: 'Color is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.color && errors.color.message}</span>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="fuel_type">Fuel Type</label>
                                                <Controller
                                                    name="fuel_type"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetFuelType}
                                                            options={fuelTypes}
                                                            placeholder='Fuel Type'
                                                            value={fuelType}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Fuel Type is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.fuel_type && errors.fuel_type.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="transmission">Transmission</label>
                                                <Controller
                                                    name="transmission"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetTransmissionType}
                                                            options={transmissionTypes}
                                                            placeholder='Transmission'
                                                            value={transmissionType}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Transmission is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.transmission && errors.transmission.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="trim">Trim</label>
                                                <Controller
                                                    name="trim"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetTrim}
                                                            options={trims}
                                                            placeholder='Trim'
                                                            value={trim}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Trim is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.trim && errors.trim.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="condition">Condition</label>
                                                <Controller
                                                    name="condition"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetCondition}
                                                            options={conditions}
                                                            placeholder='Condition'
                                                            value={condition}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Condition is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.condition && errors.condition.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="driveline">Drive line</label>
                                                <Controller
                                                    name="driveline"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetDriveline}
                                                            options={drivelines}
                                                            placeholder='Drive line'
                                                            value={driveline}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Drive line is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.driveline && errors.driveline.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="search_period">Search Period</label>
                                                <Controller
                                                    name="search_period"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetSearchPeriod}
                                                            options={searchPeriods}
                                                            placeholder='Search Period'
                                                            value={searchPeriod}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Search Period is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.driveline && errors.driveline.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="kilometers">Kilometer</label>
                                                <input type="text" name="kilometers" placeholder="Kilometers"
                                                       ref={register()}
                                                       defaultValue={request.kilometers}
                                                       className="form-control"/>
                                                <span
                                                    className="error">{errors.kilometers && errors.kilometers.message}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="card card-shadow bg-gray-light my-4">
                                                <div className="card-header-none">
                                                    <h1>Financial</h1>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="price">Price</label>
                                                        <Controller
                                                            name="price"
                                                            render={({}) => (
                                                                <NumberFormat
                                                                    placeholder="Price"
                                                                    isNumericString={true}
                                                                    allowNegative="false"
                                                                    displayType={'input'}
                                                                    thousandSeparator
                                                                    value={price}
                                                                    className="form-control"
                                                                    onValueChange={onChangePrice}
                                                                />
                                                            )}
                                                            control={control}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <span>Description</span>
                                                <textarea name="description" placeholder="Description"
                                                          rows="4"
                                                          ref={register()}
                                                          defaultValue={request.description}
                                                          className="form-control"/>
                                                <span
                                                    className="error">{errors.description && errors.description.message}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-raised"><i
                                        className="fas fa-save mr-2 bt-1"/>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};