import React, {useContext, useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm, Controller} from "react-hook-form";
import {http, VENDOR_TYPE_LIST} from "../../../env";
import {toast} from "react-toastify";
import {AuthContext} from "../../../common/context/auth";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import ReactSelect from "react-select";

export const VendorForm = (props) => {

    const {register, handleSubmit, control, reset, errors} = useForm();
    let history = useHistory();
    const [vendor_id, setVendor_id] = useState(props.match.params.vendorId);
    const [vendor, setVendor] = useState({});
    const [type, setType] = useState({});
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(null);
    const [states, setStates] = useState([]);
    const [state, setState] = useState(null);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [types, setTypes] = useState(VENDOR_TYPE_LIST);

    const handleChangeCountry = selectedOption => {
        setCountry(selectedOption);
        getAddressData(selectedOption.value, 2);
        setCities([]);
        setState(null);
        setCity(null);
    };

    const handleChangeState = selectedOption => {
        setState(selectedOption);
        getAddressData(selectedOption.value, 3);
        setCity(null);
    };

    const handleChangeCity = selectedOption => {
        setCity(selectedOption);
    };

    const handleChangeType = selectedOption => {
        setType(selectedOption);
    };

    const getVendor = async () => {
        try {
            if (vendor_id && vendor_id !== '') {
                const _res = await http('POST', 'vendor/get-by-id', {id: vendor_id})
                if (_res.ok) {
                    types.map(item => {
                        if (item.value === parseInt(_res.data.vendor_type))
                            setType(item);
                    });
                    reset(_res.data);
                    setVendor(_res.data);
                    setCountry(_res.data.Country);
                    setState(_res.data.State);
                    setCity(_res.data.City);
                    getAddressData(_res.data.country, 2);
                    getAddressData(_res.data.state, 3);
                } else console.log(_res.messages)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getAddressData = async (parent, type) => {
        try {
            const _res = await http('POST', 'address_data/get-by-parent-id', {id: parent})
            if (_res.ok) {
                if (type === 1)
                    setCountries(_res.data);
                else if (type === 2)
                    setStates(_res.data);
                else if (type === 3)
                    setCities(_res.data);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    const onSubmit = async (data) => {
        data.country = country.value;
        data.state = state.value;
        data.city = city.value;
        data.vendor_type = type.value;
        let _res;
        if (vendor_id) {
            _res = await http('POST', 'vendor/update', data);
        } else
            _res = await http('POST', 'vendor/save', data);
        if (_res.ok) {
            history.push('/admin/edit-vendor/' + _res.data.id);
            setVendor(_res.data);
            toast.success('Dealer ship saved successfully');
        } else
            toast.error('System error occurs. Please try again.');

    };

    useEffect(() => {
        getVendor();
        getAddressData(0, 1);
    }, [props.match.params.vendorId])

    useEffect(() => {
        return () => {
            setVendor(null);
        };
    }, []);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className="card card-shadow flex-grow-1 w-100">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h4>{vendor_id ?
                            <div>
                                <span>Edit vendor</span>
                            </div>
                            : 'Create vendor'}
                        </h4>
                        <button type="submit" className="btn btn-primary btn-raised"><i
                            className="fas fa-save mr-2 bt-1"/>Save
                        </button>
                    </div>
                </div>
                <div
                    className="d-flex flex-md-row flex-column align-items-md-start justify-content-md-between align-items-stretch  flex-wrap mt-3">
                    <div className="card card-shadow flex-grow-1  mr-md-3">
                        <div className="card-header-none">
                            <a data-toggle="collapse" data-target="#VendorInformationBody" aria-expanded="true"
                               className="collapse-header">
                                <span className="d-block font-weight-bold text-secondary">Vendor Information</span>
                            </a>
                        </div>
                        <div className="collapse show" id="VendorInformationBody">
                            <div className="card-body">
                                <input type="hidden" name="id" defaultValue={vendor.id} ref={register()}/>
                                <div className="form-group">
                                    <label htmlFor="vendor_type">Vendor Type</label>
                                    <Controller
                                        name="state"
                                        render={({}) => (
                                            <ReactSelect
                                                placeholder="Vendor Type"
                                                value={type}
                                                options={types}
                                                onChange={handleChangeType}
                                                isSearchable
                                            />
                                        )}
                                        rules={{required: 'State is required field'}}
                                        control={control}/>
                                    <Controller
                                        name="vendor_type"
                                       
                                        rules={{required: 'Vendor is required field'}}
                                        isSearchable
                                        control={control}>
                                    </Controller>
                                    <span className="error">{errors.city && errors.city.message}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="company_name">Company Name</label>
                                    <input
                                        name="company_name"
                                        type="text"
                                        placeholder='Company Name'
                                        className='form-control'
                                        ref={register({required: 'Company Name is required field'})}
                                        defaultValue={vendor.company_name}
                                    />
                                    <span className="error">{errors.company_name && errors.company_name.message}</span>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="company_owner">Company Owner</label>
                                    <input
                                        name="company_owner"
                                        type="text"
                                        placeholder='Company Owner'
                                        className='form-control'
                                        ref={register({required: 'Company Owner is required field'})}
                                        defaultValue={vendor.company_owner}
                                    />
                                    <span
                                        className="error">{errors.company_owner && errors.company_owner.message}</span>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="tax_number">Tax Number</label>
                                    <input
                                        name="tax_number"
                                        type="number"
                                        placeholder='Tax Number'
                                        className='form-control'
                                        ref={register()}
                                        defaultValue={vendor.tax_number}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="commission">Commission</label>
                                    <input
                                        name="commission"
                                        type="number"
                                        placeholder='Commission'
                                        className='form-control'
                                        ref={register()}
                                        defaultValue={vendor.commission}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <div className="card card-shadow flex-grow-1 mt-3 mt-md-0 ">
                            <div className="card-header-none">
                                <a data-toggle="collapse" data-target="#VendorContactBody" aria-expanded="true"
                                   className="collapse-header">
                                    <span className="d-block font-weight-bold text-secondary">Contact information</span>
                                </a>
                            </div>
                            <div className="collapse show" id="VendorContactBody">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            name="email"
                                            type="email"
                                            placeholder='Email'
                                            className='form-control'
                                            ref={register({required: 'Email is required field'})}
                                            defaultValue={vendor.email}
                                        />
                                        <span className="error">{errors.email && errors.email.message}</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="website">Website</label>
                                        <input
                                            name="website"
                                            type="url"
                                            placeholder='Website'
                                            className='form-control'
                                            ref={register({required: 'Website is required field'})}
                                            defaultValue={vendor.website}
                                        />
                                        <span className="error">{errors.website && errors.website.message}</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone</label>
                                        <input
                                            name="phone"
                                            type="tel"
                                            placeholder='Phone'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={vendor.phone}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="fax">Fax</label>
                                        <input
                                            name="fax"
                                            type="tel"
                                            placeholder='Fax'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={vendor.fax}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-shadow flex-grow-1 mt-3">
                            <div className="card-header-none">
                                <a data-toggle="collapse" data-target="#VendorAddressInformationBody"
                                   aria-expanded="true"
                                   className="collapse-header">
                                    <span className="d-block font-weight-bold text-secondary">Address Information</span>
                                </a>
                            </div>
                            <div className="collapse show" id="VendorAddressInformationBody">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="country">Country</label>
                                        <Controller
                                            name="country"
                                            render={({}) => (
                                                <ReactSelect
                                                    placeholder="Country"
                                                    onChange={handleChangeCountry}
                                                    isSearchable
                                                    options={countries}
                                                    value={country}
                                                />
                                            )}
                                            rules={{required: 'Country is required field'}}
                                            control={control}/>
                                        <span className="error">{errors.country && errors.country.message}</span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="state">State</label>
                                        <Controller
                                            name="state"
                                            render={({}) => (
                                                <ReactSelect
                                                    placeholder="State"
                                                    onChange={handleChangeState}
                                                    isSearchable
                                                    options={states}
                                                    value={state}
                                                />
                                            )}
                                            rules={{required: 'State is required field'}}
                                            control={control}/>
                                        <span className="error">{errors.state && errors.state.message}</span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="city">City</label>
                                        <Controller
                                            name="city"
                                            render={({}) => (
                                                <ReactSelect
                                                    placeholder="City"
                                                    onChange={handleChangeCity}
                                                    isSearchable
                                                    options={cities}
                                                    value={city}
                                                />
                                            )}
                                            rules={{required: 'City is required field'}}
                                            control={control}/>
                                        <span className="error">{errors.city && errors.city.message}</span>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label htmlFor="post_code">Post Code</label>
                                        <input
                                            name="post_code"
                                            type="number"
                                            placeholder='Post Code'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={vendor.post_code}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="address">Address</label>
                                        <textarea name="address" id="address" rows="5" placeholder="address"
                                                  className="form-control" ref={register()}
                                                  defaultValue={vendor.address}/>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </form>
        </div>
    );
};

