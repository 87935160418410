import React, {useContext} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {AuthContext, getUserInfo, isValidToken} from "../../../common/context/auth";
import {SITE_NAME} from "../../../env";

export const Navbar = () => {
    let {user_id, email, username} = ''
    const auth = useContext(AuthContext)
    const {setLoggedIn} = useContext(AuthContext)
    if (isValidToken()) {
        auth.isLoggedIn = true
        auth.userInfo = getUserInfo();
        username = auth.userInfo['username'];
        email = auth.userInfo['email'];
        user_id = auth.userInfo['id'];
    }
    const logout = () => {
        localStorage.clear()
        setLoggedIn(false)
    }
    return (
        <div id='navbar-component'>
            <header id="basicHeader" className="container-fluid shadow-sm">
                <div className="d-flex justify-content-between align-items-center ">
                    <div className="text-white d-flex flex-column justify-content-start align-items-center">
                        <div className="d-flex  align-items-center">
                            <img src="/images/logo.png" alt="hillzdealers" width="55" className="py-2"/>
                            <Link className="pl-0" to={'/blog'}>
                            <span
                                className="font-weight-bold font-size-13 text-black d-inline-block">{SITE_NAME}</span>
                            </Link>
                        </div>

                    </div>
                </div>
            </header>
        </div>
    );
};
