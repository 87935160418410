import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {http} from "../../../env";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import $ from 'jquery';

export const PostCommentReplyModal = (props) => {
    const {post, comment} = props;
    const [postComment, setPostComment] = useState(comment);
    useEffect(() => {
        setPostComment(props.comment)
    }, [props.comment])
    const {register, handleSubmit, errors, control, formState} = useForm({
        mode: "onChange",
    });
    const addPostCommentReplyHandler = async (data,e) => {
        let _res = await http('POST', 'post_comment/save', data)
        if (_res.ok) {
            e.target.reset();
            toast.success('Your reply message was sent successfully.');
            $('#postCommentModal').modal('hide');
        } else
            toast.error(_res.message);
    }
    
    return (
        <div className="modal fade" id="postCommentModal" aria-labelledby="postCommentModal" aria-hidden="true">
            <div className="modal-dialog modal-xl" key={postComment.id}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Reply Comment({postComment.author_name})</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(addPostCommentReplyHandler)}>
                            <div className="form-group">
                                <input type="hidden" name="post_id" ref={register()} defaultValue={post.id}/>
                                <input type="hidden" name="parent_id" ref={register()} defaultValue={postComment.id}/>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <input type="text" name="author_name" id="authorName"
                                               className="form-control"
                                               placeholder="Full Name"
                                               ref={register({required: "Required"})}/>
                                        <span
                                            className="error">{errors.author_name && errors.author_name.message}</span>

                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="text" name="author_email" id="authorEmail"
                                               className="form-control"
                                               placeholder="Email" ref={register({
                                            required: "Required",
                                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })}/>
                                        <span
                                            className="error">{errors.author_email?.type === "required" && errors.author_email.message}
                                            {errors.author_email?.type === "pattern" && "Please enter valid email."}
                                                    </span>
                                    </div>
                                </div>
                                <textarea name="content" placeholder="write Your comment"
                                          rows="6"
                                          cols="10" ref={register({required: "Required"})}
                                          className="form-control"/>
                                <span className="error">{errors.content && errors.content.message}</span>

                            </div>
                            <div className="text-left">
                                <button type="submit"
                                        className="btn btn-primary btn-raised text-white">
                                    <i className="far fa-comment-alt mr-1" aria-hidden="true"/>
                                    Reply
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};


