import React, {useEffect, useState} from 'react';
import ReactHtmlParser from "react-html-parser";
import {http} from "../../../env";
import {Link} from "react-router-dom";
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

export const VehiclePage = (props) => {
    const [vehicle, setVehicle] = useState(null);
    const [options, setOptions] = useState([]);
    const [galleryItems, setGalleryItems] = useState([]);
    const [location, setLocation] = useState(window.location.href);
    const getVehicle = async (slug) => {
        try {
            const _res = await http('POST', 'vehicle/get-by-id', {id: slug});
            if (_res.ok) {
                setVehicle(_res.data.vehicle);
                setGalleryItems(_res.data.vehicle.vehicle_images.map(item => <img src={`/uploads/${item.image}`}/>))
                setOptions(_res.data.options);
                document.title = _res.data.options.filter(key => key.id === _res.data.vehicle.model)[0].label;
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }
    }

    const getOption = (id) => {
        if (options.length > 0)
            return options.filter(key => key.id === id)[0].label;
    }

    useEffect(() => {
        getVehicle(props.match.params.slug);
    }, [props.match.params.slug]);


    return (
        <>
            {!vehicle ? '' :
                <div className="container-fluid mt-4">
                    <div className="row">
                        <div className="col-12 col-md-9 pr-md-0">
                            <div className="card card-shadow">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h1>{getOption(vehicle.maker) + ' ' + getOption(vehicle.model) + ' ' + vehicle.year}</h1>
                                </div>
                                <div className="card-body">
                                    <AliceCarousel
                                        items={galleryItems}
                                        // responsive={this.responsive}
                                        autoPlayInterval={5000}
                                        autoPlay={true}
                                        fadeOutAnimation={true}
                                        mouseTrackingEnabled={true}
                                        playButtonEnabled={true}
                                        disableAutoPlayOnAction={true}
                                        // onSlideChange={this.onSlideChange}
                                        // onSlideChanged={this.onSlideChanged}
                                    />
                                    <p className="my-4">{vehicle.description}</p>
                                </div>
                                <div className="card-footer d-flex justify-content-between border-top-0">
                                        <span><i
                                            className="fas fa-tachometer-alt text-secondary opacity-88 mr-1"/>{vehicle.odometer} miles</span>
                                    <b className="font-size-13 font-weight-bold text-success">${vehicle.price}</b>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );

};
