import React, {useEffect, useState} from 'react';
import ReactHtmlParser from "react-html-parser";
import {http, httpDownloadFile} from "../../../env";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import StarRatings from "react-star-ratings";
import download from "downloadjs";
import {PostCommentReplyModal} from "./post_comment_reply_modal";
import DocumentMeta from 'react-document-meta';

export const PostPage = (props) => {
    const [post, setPost] = useState(null);
    const [location, setLocation] = useState(window.location.href);
    const [tags, setTags] = useState([]);
    const [comment, setComment] = useState({});
    const [rate, setRate] = useState(0);
    const [similarPosts, setSimilarPosts] = useState([]);
    const [breadCrumb, setBreadCrumb] = useState([]);
    const [meta, setMeta] = useState({});
    const {register, handleSubmit, errors, control, formState} = useForm({
        mode: "onChange",
    });

    // let meta = ;

    const downloadFile = async (item) => {
        const res = await httpDownloadFile("POST", "post_attach_file/download", item);
        const blob = res.blob().then(value => {
            download(value, item.file);
        });
    }

    // let {slug} = useParams();
    const addPostCommentHandler = async (values, e) => {
        const body = {
            post_id: post.id,
            author_name: values.author_name,
            author_email: values.author_email,
            content: values.content,
        }
        let _res = await http('POST', 'post_comment/save', body)
        if (_res.ok) {
            e.target.reset();
            toast.success('Your message was sent successfully.');
        } else
            toast.error(_res.message);
    }
    const ratingChanged = async (newRating, name) => {
        const body = {
            post_id: post.id,
            rate: newRating
        }
        let _res = await http('POST', 'post_rate/save', body)
        if (_res.ok) {
            setRate(_res.data.rate)
            toast.success('Your vote has been saved successfully');
        } else
            toast.error(_res.message);
    };
    const getPost = async (slug) => {
        try {
            const _res = await http('POST', 'post/get-by-slug', {slug: slug})
            console.log(_res)
            if (_res.ok) {
                setMeta({
                    title: _res.data.seo_title,
                    description: _res.data.seo_description,
                    meta: {
                        // charSet: 'utf-8',
                        name: {
                            keywords: _res.data.seo_keyword
                        },
                        // itemProp: {
                        //     name: 'The Name or Title Here',
                        //     description: 'This is the page description',
                        //     image: 'http://example.com/image.jpg'
                        // },
                        // property: {
                        //     'og:title': 'I am overriding!',
                        //     'og:type': 'article',
                        //     'og:image': 'http://example.com/image.jpg',
                        //     'og:site_name': 'Example Site Name',
                        //     'og:price:amount': '19.50',
                        //     'og:price:currency': 'USD',
                        //     'twitter:site': '@site',
                        //     'twitter:title': 'I am a Twitter title'
                        // }
                    },
                    // auto: {
                    //     ograph: true
                    // }
                })
                const _similar = await http('POST', 'post/get-related', _res.data);
                console.log(_similar)
                if (_similar.ok) {
                    setSimilarPosts(_similar.data)
                }
                setTimeout(async () => {
                    try {
                        await http('POST', 'post/update-visit', _res.data)
                    } catch (e) {
                        console.log(e.messages)
                    }
                }, 15000);

                const _categories = await http('POST', 'post_category/get-by-id', {id: _res.data.post_category_id});
                if (_categories.ok)
                    setBreadCrumb(_categories.data)
                setRate(_res.data.total_rate)
                setPost(_res.data)
                document.title = _res.data.seo_title;
                setTags(_res.data.tags.split(','))
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }
    }
    useEffect(() => {
        getPost(props.match.params.slug)
    }, [props.match.params.slug])

    useEffect(() => {
        if (post && post.id) {
            
        }
    }, [post])


    return (
        <>
            {!post ? '' :
                <DocumentMeta {...meta}>
                    <div className="container-fluid mt-4">
                        {<PostCommentReplyModal post={post} comment={comment}/>}
                        <div className="row">
                            <div className="col-12 col-md-9 pr-md-0">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        {breadCrumb.map((item, index) => (
                                            <li key={index} className="breadcrumb-item active"
                                                aria-current="page">   {item.title}</li>
                                        ))}
                                    </ol>
                                </nav>
                                <div className="card card-shadow">
                                    <div className="card-header d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                                        <h1>{post.title}</h1>
                                        <div className="d-flex col-auto align-items-center">
                                            <StarRatings
                                                rating={rate}
                                                starRatedColor="#FFBE0B"
                                                starHoverColor="#FFBE0B"
                                                starEmptyColor="rgba(0, 0, 0, .12)"
                                                changeRating={ratingChanged}
                                                numberOfStars={5}
                                                name='rating'
                                                starDimension="20px"
                                                starSpacing="2px"
                                            />
                                            <span className="ml-2 font-weight-bold">{rate}</span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <img src={'/uploads/' + post.image_file} className="w-100" alt={post.image_alt}
                                             title={post.image_title}/>
                                        <p className="my-4">{ReactHtmlParser(post.body)}</p>
                                        <div className="tags">
                                            {tags.map((item, index) => (
                                                <span className="badge badge-pill badge-primary font-size-12 mr-2"
                                                      key={index}>
                                        {item}
                                     </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="card-footer border-top-0 py-0 pr-1">
                                        <div className="d-flex justify-content-between align-items-center p-2">

                                            <small className="text-muted mr-4"><i className="far fa-clock mr-1"/>
                                                <span
                                                    className="mr-1">Published:</span> {new Intl.DateTimeFormat("en-GB", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "2-digit",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                }).format(new Date(post.publishAt).getTime())}

                                            </small>

                                            <small className="text-muted"><i
                                                className="far fa-eye mr-1"/><span>{post.visit_count}</span></small>
                                        </div>
                                    </div>

                                </div>
                                <div className="card card-shadow mt-3">
                                    <div className="card-header bg-white border-bottom-0"><h5
                                        className="mb-0 text-secondary">write
                                        your
                                        comment</h5>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit(addPostCommentHandler)}>
                                            <input type="hidden" name="post_id" value={post.id}/>
                                            <div className="form-group">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <input type="text" name="author_name" id="authorName"
                                                               className="form-control"
                                                               placeholder="Full Name"
                                                               ref={register({required: "Required"})}/>
                                                        <span
                                                            className="error">{errors.author_name && errors.author_name.message}</span>

                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="text" name="author_email" id="authorEmail"
                                                               className="form-control"
                                                               placeholder="Email" ref={register({
                                                            required: "Required",
                                                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                        })}/>
                                                        <span
                                                            className="error">{errors.author_email?.type === "required" && errors.author_email.message}
                                                            {errors.author_email?.type === "pattern" && "Please enter valid email."}
                                                    </span>
                                                    </div>
                                                </div>
                                                <textarea name="content" placeholder="write Your comment"
                                                          rows="6"
                                                          cols="10" ref={register({required: "Required"})}
                                                          className="form-control"/>
                                                <span
                                                    className="error">{errors.content && errors.content.message}</span>

                                            </div>
                                            <div className="text-left">
                                                <button type="submit"
                                                        className="btn btn-primary btn-raised text-white">
                                                    <i className="far fa-comment-alt mr-1" aria-hidden="true"/>
                                                    Add Comment
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="card card-shadow mt-3">
                                    <div
                                        className="card-header bg-white border-bottom-0 d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0 text-secondary">Comments</h5>
                                        <span id="commentCount"
                                              className="text-muted font-size-12 font-weight-bold">{post.post_comments.length}<span
                                            className="ml-1"><i className="far fa-user"/></span></span>
                                    </div>
                                    <div className="card-body comment p-sm-5" id="postCommentsContainer">
                                        {post.post_comments.length > 0 ? post.post_comments.map((item, key) =>
                                            <div>
                                                <div className="media mb-5" key={item.id}>
                                                    <img className="rounded-circle-70" src="/images/profile-avatar.svg"
                                                         alt=""/>
                                                    <div className="media-body">
                                                        <div
                                                            className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                            <div
                                                                className="d-flex justify-content-between align-items-start flex-grow-1 flex-wrap">
                                                                <div className="d-flex">
                                                                    <h5 className="font-weight-bold">{item.author_name}</h5>
                                                                    <div className="ml-1 ml-sm-2 text-muted">
                                                                        <i className="fas fa-clock opacity-58 text-muted font-size-11"/>
                                                                        <span> {new Intl.DateTimeFormat("en-GB", {
                                                                            year: "numeric",
                                                                            month: "long",
                                                                            day: "2-digit",
                                                                            hour: "numeric",
                                                                            minute: "numeric",
                                                                        }).format(new Date(item.createdAt).getTime())}</span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                           <span data-toggle="modal" data-target="#postCommentModal"
                                                                 onClick={() => setComment(item)}>
                                            <i className="material-icons hover pointer"
                                               data-toggle="tooltip" title="Reply">reply</i> </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="white-space-pre-line line-height-normal mb-1 text-justify">
                                                            {item.content}
                                                        </p>
                                                    </div>
                                                </div>
                                                {item.Replies.length > 0 ?
                                                    <div className="ml-5">
                                                        <h5 className="mb-3 text-secondary">Replies</h5>
                                                        {item.Replies.map((reply, replyKey) =>
                                                            <div className="media mb-5" key={reply.id}>
                                                                <img className="rounded-circle-70"
                                                                     src="/images/profile-avatar.svg"
                                                                     alt=""/>
                                                                <div className="media-body">
                                                                    <div
                                                                        className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                                        <div className="d-flex flex-grow-1 flex-wrap">
                                                                            <h5 className="font-weight-bold">{reply.author_name}</h5>
                                                                            <div className="ml-1 ml-sm-2 text-muted">
                                                                                <i className="fas fa-clock opacity-58 text-muted font-size-11"/>
                                                                                <span> {new Intl.DateTimeFormat("en-GB", {
                                                                                    year: "numeric",
                                                                                    month: "long",
                                                                                    day: "2-digit",
                                                                                    hour: "numeric",
                                                                                    minute: "numeric",
                                                                                }).format(new Date(reply.createdAt).getTime())}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p
                                                                        className="white-space-pre-line line-height-normal text-secondary font-weight-500  mb-1 text-justify"> {reply.content}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    : ''}
                                            </div>
                                        ) : <div>this post doesn't have comments</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 mt-3 mt-md-0">
                                <div className="card card-shadow mb-3">
                                    <div className="card-header bg-white d-flex align-items-center">
                                        <span className="font-size-12 text-secondary font-weight-bold">Author</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="media">
                                            <img className="rounded-circle-70 "
                                                 src={post.author.avatar === null ? '/images/profile-avatar.svg' : '/uploads/' + post.author.avatar}
                                                 alt=""/>
                                            <div className="media-body">
                                                <h5 className="mt-0">{post.author.name}</h5>
                                                {post.author.description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {post.post_attach_files != '' ?
                                    <div className="card card-shadow mt-4">
                                        <div className="card-header bg-white d-flex align-items-center">
                                        <span
                                            className="font-size-12 text-secondary font-weight-bold">Attach files</span>
                                        </div>
                                        <div className="card-body d-flex flex-wrap">
                                            <ul className="pl-0 w-100">
                                                <li className="d-flex justify-content-between align-items-center mb-3 text-muted">
                                                    <b>File</b>
                                                    <b>Download</b>
                                                </li>
                                                {post.post_attach_files.length > 0 && post.post_attach_files.map((item, index) => (
                                                    <li className="d-flex justify-content-between align-items-center">
                                                        <a onClick={() => downloadFile(item)} target="_blank"
                                                           className="badge badge-info font-size-11 font-weight-bold mr-1"
                                                           key={index} href={'/upload/' + item.file}>
                                                            {'Attach file ' + (index + 1) + ' '}
                                                        </a>
                                                        <b>{item.download_count}</b>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className="card card-shadow mt-4">
                                    <div className="card-header bg-white d-flex align-items-center">
                                        <span className="font-size-12 text-secondary font-weight-bold">Share</span>
                                    </div>
                                    <div className="card-body text-center px-3 pt-0">
                                        <div className="mb-3">
                                            <a href={'https://www.facebook.com/sharer.php?u=' + location}
                                               className="py-1 ml-2" target="_blank" title="Facebook">
                                                <img src="/images/social/facebook.svg" width="24px" alt="Facebook"
                                                     title="Facebook"/></a>

                                            <a href={'https://twitter.com/intent/tweet/?text=' + location}
                                               className="py-1 ml-2"
                                               target="_blank" title="Twitter">
                                                <img src="/images/social/twitter.svg" alt="Twitter" title="Twitter"
                                                     width="24px"/></a>
                                            <a href={'https://plus.google.com/share?url=' + location}
                                               className="py-1 ml-2"
                                               target="_blank" title="Google Plus">
                                                <img src="/images/social/google-plus.svg" alt="Google Plus"
                                                     title="Google Plus"
                                                     width="24px"/></a>

                                            <a href={'whatsapp://send?text=' + location} className="py-1 ml-2"
                                               target="_blank"
                                               title="WhatsApp">
                                                <img src="/images/social/whatsapp.svg" alt="WhatsApp" title="WhatsApp"
                                                     width="24px"/></a>

                                            <a href={'https://telegram.me/share/url?url=' + location}
                                               className="py-1 ml-2"
                                               target="_blank" title="Telegram">
                                                <img src="/images/social/telegram.svg" alt="Telegram" title="Telegram"
                                                     width="24px"/></a>
                                        </div>
                                        <form id="shareLinkEmailForm">
                                            <input type="hidden" value={location} name="link"/>
                                            <div className="form-group">
                                                <label htmlFor="shareEmail">
                                                    Your friend's email address...</label>
                                                <input type="email" id="shareEmail" placeholder="example@domain.com"
                                                       required
                                                       autoComplete="off" name="email"
                                                       className="form-control ltr en-font"
                                                       aria-required="true"/>
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-raised btn-block">Send
                                            </button>
                                        </form>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <h1 className="mb-4 mt-5">Related post</h1>
                        <div className="row">
                            {similarPosts.length > 0 ? similarPosts.map((item, key) =>
                                    <div className="col-12 col-md-3 h-auto" key={item.id}>
                                        <div className="card card-shadow mb-3">
                                            <div className="overflow-hidden">
                                                <div className="card-img-top"
                                                     style={{backgroundImage: `url(${'/uploads/' + item.image_file})`}}/>
                                            </div>
                                            <div className="card-body">
                                                <h5 className="font-weight-bold blog-title">
                                                    <Link className="card-title"
                                                          to={`/Blog/${item.post_category.title}/${item.slug}`}>{item.title}</Link>
                                                </h5>
                                                <p className="card-text min-height-70 text-secondary mt-3">
                                                    {item.description.length > 100 ? item.description.substring(0, 100) + '...' : item.description}
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <small className="text-muted">{new Intl.DateTimeFormat("en-GB", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "2-digit",
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    }).format(new Date(item.publishAt).getTime())}</small>
                                                    <Link className="card-title" to={`/Blog/${item.post_category.title}/${item.slug}`}>
                                                        <small className="mr-2">More</small>
                                                        <i className="fas fa-arrow-right pt-1"/></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                <div className="col-12">
                                    this post doesn't have similar post
                                </div>
                            }
                        </div>
                    </div>
                </DocumentMeta>
            }
        </>
    );
};
