import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {http} from "../../../env";
import {confirmAlert} from "react-confirm-alert";

export const PostEditingHistoriesModal = (props) => {
    const {post, histories} = props;
    return (
        <div className="modal fade" id="postEditingHistoriesModal" aria-labelledby="postEditingHistoriesModal"
             aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">List of histories</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <table
                            className="table font-size-11 table-light table-hover dataTable-collapse text-left  no-footer">
                            <thead className="bg-white">
                            <tr>
                                <th>Row</th>
                                <th>User</th>
                                <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {histories.length > 0 && histories.map((item, key) =>
                                <tr key={key}>
                                    <th>{key + 1}</th>
                                    <td>{item.user.user_name}</td>
                                    <td>{new Intl.DateTimeFormat("en-GB", {
                                        year: "numeric",
                                        month: "long",
                                        day: "2-digit",
                                        hour: "numeric",
                                        minute: "numeric",
                                    }).format(new Date(item.editingAt).getTime())}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};


