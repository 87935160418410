import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {API_ADMIN_URL, http} from "../../../env";
import {confirmAlert} from "react-confirm-alert";
import Dropzone from "react-dropzone";

export const PostAttachFilesModal = (props) => {
    const {post, attachedFiles} = props;
    const [files, setFiles] = useState(attachedFiles);
    const [previews, setPreviews] = useState([]);
    const [progressbar, setProgressbar] = useState(0);
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };
    const thumb = {
        display: 'inline-flex',
        marginBottom: 8,
        marginRight: 8,
        width: 120,
        height: 120,
        padding: 4,
        boxSizing: 'border-box'
    };
    const deleteBtn={
        top: 0,
        right:0
    }
    useEffect(() => {
        setFiles(props.attachedFiles)
    }, [props.attachedFiles])

    const onDrop = acceptedFiles => {
        console.log(acceptedFiles)
        let tempFiles = [];
        acceptedFiles.map(file => {
            let imgObj = {
                name: file.name,
                preview: URL.createObjectURL(file)
            };
            tempFiles.push(imgObj)
        });
        setPreviews(tempFiles)
        // console.log(tempFiles)
        const formData = new FormData();
        for (const file of acceptedFiles) formData.append('file', file);

        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = event => {
            console.log(event)
            const percentage = parseInt((event.loaded / event.total) * 100);
            setProgressbar(percentage); // Update progress here
            console.log(percentage); // Update progress here
        };
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) return;
            if (xhr.status !== 200) {
                console.log('error'); // Handle error here
            }
            console.log(xhr.response)
            let response = JSON.parse(xhr.response)
            if (response.ok) {
                setPreviews([])
                setFiles(response.data)
                console.log('success'); // Handle success here
            }
        };
        xhr.open('POST', API_ADMIN_URL + 'post_attach_file/save/' + post.id, true);
        xhr.setRequestHeader('access_token', `Bearer ${localStorage.getItem('token')}`);
        xhr.send(formData);
        // setFiles(tempFiles);
    }

    const thumbs = files.map(file => (
        
        <div style={thumb} key={file.id}>
            <div className="card card-shadow">
                <div className="card-body p-2">
                    <img
                        src={'/uploads/' + file.file}
                        className="w-100 h-100" alt="" />
                </div>
                <span onClick={() => deleteItem(file)} className="position-absolute" style={deleteBtn}>
                <i className="material-icons hover pointer mr-2"
                   data-toggle="tooltip" title="delete">delete</i> </span>
            </div>

        </div>
    ));

    const thumbsPreview = previews.map(file => (
        <div style={thumb} key={file.name}>
            <div className="card card-shadow">
                <div className="card-body p-2">
                    <img
                        src={file.preview}
                        className="w-100 h-100" alt="" />
                </div>
                {progressbar}
            </div>
        </div>
    ));

    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1 className="text-primary">Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-white-border btn-raised mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success btn-raised" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'post_attach_file/delete', item);
            if (_res.ok) {
                setFiles(_res.data);
            } else console.log(_res.messages);
        }
    };
    return (
        <div className="modal fade" id="postAttachFilesModal" aria-labelledby="postAttachFilesModal" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">List of files ({files.length})</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Dropzone onDrop={onDrop}>
                            {({getRootProps, getInputProps}) => (
                                <section className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <div className="progress mt-2">
                                        <div className="progress-bar"
                                             style={{width: progressbar + '%'}}>{(progressbar !== 0 && progressbar !== 100 ? 'uploading...' : progressbar === 100 ? 'done!' : '')}</div>
                                    </div>
                                    <aside style={thumbsContainer}>
                                        {thumbsPreview}
                                        {thumbs}
                                    </aside>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </div>
            </div>
        </div>
    );
};