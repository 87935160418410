import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm, Controller} from "react-hook-form";

import {http, VEHICLE_OPTION, VEHICLE_STATUS_LIST} from "../../../env";
import {toast} from "react-toastify";
import {VehicleImagesModal} from "./vehicle_images_modal";
import {useHistory} from "react-router";
import ReactSelect from "react-select";
import NumberFormat from 'react-number-format';
import {VehiclePurchaseForm} from "./form_vehicle_purchase";
import {BarcodeScannerModal} from "./barcode_scanner_modal";

export const VehicleForm = (props) => {
    const {register, handleSubmit, control, reset, setValue, errors} = useForm({});
    let history = useHistory();
    const [vehicle_id] = useState(props.match.params.vehicleId);
    const [vehicle, setVehicle] = useState({});
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [price, setPrice] = useState(null);
    const [msrp, setMSRP] = useState(null);
    const [special_price, setSpecial_price] = useState(null);
    const [status, setStatus] = useState({});
    const [statuses] = useState(VEHICLE_STATUS_LIST);
    const [is_active, setIs_active] = useState(1);
    const [certificatePreOwned, setCertificatePreOwned] = useState(1);
    const [sold_as_is, setSold_as_is] = useState(0);
    const [barcode, setBarcode] = useState(0);
    const [bodyStyles, setBodyStyles] = useState([]);
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [years, setYears] = useState([]);
    const [trims, setTrims] = useState([]);
    const [colors, setColors] = useState([]);
    const [transmissionTypes, setTransmissionTypes] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [doorCounts, setDoorCounts] = useState([]);

    const [maker, setMaker] = useState(null);
    const [model, setModel] = useState(null);
    const [year, setYear] = useState(null);
    const [trim, setTrim] = useState(null);
    const [color, setColor] = useState(null);
    const [exteriorColor, setExteriorColor] = useState(null);
    const [interiorColor, setInteriorColor] = useState(null);
    const [transmissionType, setTransmissionType] = useState(null);
    const [fuelType, setFuelType] = useState(null);
    const [doorCount, setDoorCount] = useState(null);
    const [bodyStyle, setBodyStyle] = useState(null);
    const [dataIsReady, setDataIsReady] = useState(false);

    const [results, setResults] = useState([]);

    const handleChangeStatus = selectedOption => {
        setStatus(selectedOption);
    };
    const handleSetMaker = selectedOption => {
        setMaker(selectedOption);
        setModel(null);
        setModels(allModels.filter(key => key.option_type === VEHICLE_OPTION.MODEL && key.parent_id === selectedOption.value));
    };
    const handleSetModel = selectedOption => {
        setModel(selectedOption);
    };
    const handleSetYear = selectedOption => {
        setYear(selectedOption);
    };
    const handleSetTrim = selectedOption => {
        setTrim(selectedOption);
    };
    const handleSetExteriorColor = selectedOption => {
        setExteriorColor(selectedOption);
    };
    const handleSetColor = selectedOption => {
        setColor(selectedOption);
    };
    const handleSetInteriorColor = selectedOption => {
        setInteriorColor(selectedOption);
    };
    const handleSetTransmissionType = selectedOption => {
        setTransmissionType(selectedOption);
    };
    const handleSetFuelType = selectedOption => {
        setFuelType(selectedOption);
    };
    const handleSetDoorCount = selectedOption => {
        setDoorCount(selectedOption);
    };
    const handleSetBodyStyles = selectedOption => {
        setBodyStyle(selectedOption);
    };

    const onChangeActive = value => setIs_active(value.target.checked);
    const onChangeCertificatePreOwned = value => setCertificatePreOwned(value.target.checked);
    const onChangeSoldAsIs = value => setSold_as_is(value.target.checked);
    const onChangePrice = value => setPrice(value.value);
    const onChangeMSRP = value => setMSRP(value.value);
    const onChangeSpecialPrice = value => setSpecial_price(value.value);

    const getVehicle = async () => {
        try {
            if (vehicle_id && vehicle_id !== '') {
                const _res = await http('POST', 'vehicle/get-by-id', {id: vehicle_id});
                if (_res.ok) {
                    _res.data = _res.data.vehicle;
                    setVehicle(_res.data);
                    reset(_res.data);
                    setIs_active(_res.data.is_active);
                    setCertificatePreOwned(_res.data.certificate_pre_owned);
                    setSold_as_is(_res.data.sold_as_is);
                    setPrice(_res.data.price ? _res.data.price : null);
                    setMSRP(_res.data.msrp ? _res.data.msrp : null);
                    setSpecial_price(_res.data.special_price ? _res.data.special_price : null);
                    if (_res.data.maker) {
                        setMaker(makers.filter(key => key.value === parseInt(_res.data.maker))[0]);
                        setModels(allModels.filter(key => key.option_type === VEHICLE_OPTION.MODEL && key.parent_id === parseInt(_res.data.maker)));
                    }
                    if (_res.data.model)
                        setModel(allModels.filter(key => key.value === parseInt(_res.data.model))[0]);
                    if (_res.data.year)
                        setYear(years.filter(key => key.value === parseInt(_res.data.year))[0]);
                    if (_res.data.trim)
                        setTrim(trims.filter(key => key.value === parseInt(_res.data.trim))[0]);
                    if (_res.data.body_style)
                        setBodyStyle(bodyStyles.filter(key => key.value === parseInt(_res.data.body_style))[0]);
                    if (_res.data.color)
                        setColor(colors.filter(key => key.value === parseInt(_res.data.color))[0]);
                    if (_res.data.interior_color)
                        setInteriorColor(colors.filter(key => key.value === parseInt(_res.data.interior_color))[0]);
                    if (_res.data.exterior_color)
                        setExteriorColor(colors.filter(key => key.value === parseInt(_res.data.exterior_color))[0]);
                    if (_res.data.transmission)
                        setTransmissionType(transmissionTypes.filter(key => key.value === parseInt(_res.data.transmission))[0]);
                    if (_res.data.fuel_type)
                        setFuelType(fuelTypes.filter(key => key.value === parseInt(_res.data.fuel_type))[0]);
                    if (_res.data.door_count)
                        setDoorCount(doorCounts.filter(key => key.value === parseInt(_res.data.door_count))[0]);

                    statuses.map(item => {
                        if (item.value === parseInt(_res.data.status))
                            setStatus(item);
                    });

                    setAttachedFiles(_res.data.vehicle_images);

                } else console.log(_res.messages)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getVehicleOptionData = async () => {
        try {
            const _res = await http('POST', 'vehicle_option_data/all');
            if (_res.ok) {
                const vehicleOptions = _res.data;
                setBodyStyles(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.BODY_STYLE));
                setMakers(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.MAKER));
                setAllModels(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.MODEL));
                setColors(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.COLOR));
                setTrims(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.TRIM));
                setTransmissionTypes(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.TRANSMISSION_TYPE));
                setFuelTypes(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.FUEL_TYPE));
                setDoorCounts(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.DOOR_COUNT));
                setDataIsReady(true);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    const makeYears = () => {
        let currentYear = new Date().getFullYear();
        let rows = [];
        for (let i = currentYear; i >= currentYear - 50; i--) {
            rows.push({value: i, label: i});
        }
        setYears(rows);
    }

    const onSubmit = async (data) => {
        data.is_active = is_active;
        data.certificate_pre_owned = certificatePreOwned;
        data.status = status.value;
        data.maker = maker.value;
        data.model = model.value;
        data.year = year.value;
        data.trim = trim.value;
        data.color = color.value;
        data.interior_color = interiorColor.value;
        data.exterior_color = exteriorColor.value;
        data.transmission = transmissionType.value;
        data.fuel_type = fuelType.value;
        data.door_count = doorCount.value;
        data.body_style = bodyStyle.value;
        data.sold_as_is = sold_as_is;
        data.price = price;
        data.msrp = msrp;
        data.special_price = special_price;
        let _res;
        if (data.id)
            _res = await http('POST', 'vehicle/update', data);
        else
            _res = await http('POST', 'vehicle/save', data);

        if (_res.ok) {
            props.history.push({
                pathname: '/admin/edit-vehicle/' + _res.data.id,
                search: ''
            });
            setVehicle(_res.data);
            toast.success('Vehicle saved successfully');
        } else
            toast.error('System error occurs. Please try again.');

    };

    useEffect(() => {
        getVehicleOptionData();
        makeYears();
    }, [props.match.params.vehicleId]);

    useEffect(() => {
        if (dataIsReady) {
            getVehicle();
            setDataIsReady(false);
        }
    }, [dataIsReady]);

    return (
        <div>
            {<BarcodeScannerModal setResults={setResults} results={results}/>}
            <div className="card card-shadow flex-grow-1 w-100">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className=""><i
                        className="fas fa-car opacity-78 mr-3"/>{vehicle_id ? 'Edit Vehicle' : 'Create Vehicle'}
                    </h4>
                </div>
            </div>
            <ul className="nav nav-tabs border-bottom-0 align-items-center mt-4" id="BlogTab"
                role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#VehicleInformation" role="tab"
                       aria-controls="VehicleInformation" aria-selected="true"><i className="fas fa-car mr-2"/>Vehicle
                        information
                    </a>
                    <div className="line"/>
                </li>
                <li className={vehicle_id ? "nav-item" : "nav-item disabled"}>
                    <a className="nav-link" data-toggle={vehicle_id ? "tab" : ""} href="#VehicleImage" role="tab"
                       aria-controls="VehicleImage" aria-selected="false"><i
                        className="far fa-images mr-2"/>add Vehicle Image
                    </a>
                    <div className="line"/>
                </li>
                <li className={vehicle_id ? "nav-item" : "nav-item disabled"}>
                    <a className="nav-link" data-toggle={vehicle_id ? "tab" : ""} href="#PurchaseManagement"
                       role="tab"
                       aria-controls="PurchaseManagement" aria-selected="false"><i
                        className="fas fa-file-invoice mr-2"/>Purchase Information
                    </a>
                    <div className="line"/>
                </li>
            </ul>
            <div className="row">
                <div className="col-12 tab-content pt-0">
                    <div className="tab-pane fade show active" id="VehicleInformation" role="tabpanel">
                        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                            <div className="card card-shadow">
                                <div className="card-header-none text-right">
                                    <button type="submit" className="btn btn-primary btn-raised"><i
                                        className="fas fa-save mr-2"/>Save Vehicle
                                    </button>
                                </div>
                                <div className="card-body">
                                    <input type="hidden" name="id" defaultValue={vehicle.id} ref={register()}/>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <button className="btn btn-info btn-raised btn-block"
                                                        data-toggle="modal"
                                                        data-target="#barcodeScannerModal"
                                                        type="button"><i className="fas fa-barcode mr-3"/><span>Scan Vin Barcode</span>
                                                </button>
                                            </div>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                            <span className="input-group-text"
                                                                  id="Vin">VIN</span>
                                                </div>
                                                <input
                                                    name="vin"
                                                    type="text"
                                                    placeholder='Enter Your Vin'
                                                    className='form-control'
                                                    aria-describedby="Vin"
                                                    aria-label="vin"
                                                    ref={register({required: 'vin is required field'})}
                                                    defaultValue={results && results.length > 0 ? results[0].codeResult.code : vehicle.vin}
                                                />
                                                <span className="error">{errors.vin && errors.vin.message}</span>
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-primary"
                                                            type="button">Fetch Vehicle Data
                                                    </button>

                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="year">Year</label>
                                                <Controller
                                                    name="year"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Year"
                                                            onChange={handleSetYear}
                                                            isSearchable
                                                            options={years}
                                                            value={year}
                                                        />
                                                    )}
                                                    rules={{required: 'Year is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.year && errors.year.message}</span>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="maker">Make</label>
                                                <Controller
                                                    name="maker"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Make"
                                                            onChange={handleSetMaker}
                                                            isSearchable
                                                            options={makers}
                                                            value={maker}
                                                        />
                                                    )}
                                                    rules={{required: 'Make is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.maker && errors.maker.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="model">Model</label>
                                                <Controller
                                                    name="model"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Model"
                                                            onChange={handleSetModel}
                                                            isSearchable
                                                            options={models}
                                                            value={model}
                                                        />
                                                    )}
                                                    rules={{required: 'Model is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.model && errors.model.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="body_style">Body Style</label>
                                                <Controller
                                                    name="body_style"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Body Style"
                                                            onChange={handleSetBodyStyles}
                                                            isSearchable
                                                            options={bodyStyles}
                                                            value={bodyStyle}
                                                        />
                                                    )}
                                                    rules={{required: 'Body Style is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.body_style && errors.body_style.message}</span>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="color">Color</label>
                                                <Controller
                                                    name="color"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Color"
                                                            onChange={handleSetColor}
                                                            isSearchable
                                                            options={colors}
                                                            value={color}
                                                        />
                                                    )}
                                                    rules={{required: 'Color is required field'}}
                                                    control={control}/>
                                                <span className="error">{errors.color && errors.color.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exterior_color">Exterior Color</label>
                                                <Controller
                                                    name="exterior_color"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            placeholder="Exterior Color"
                                                            onChange={handleSetExteriorColor}
                                                            isSearchable
                                                            options={colors}
                                                            value={exteriorColor}
                                                        />
                                                    )}
                                                    rules={{required: 'Exterior Color is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.exterior_color && errors.exterior_color.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="interior_color">Interior Color</label>
                                                <Controller
                                                    name="interior_color"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetInteriorColor}
                                                            options={colors}
                                                            placeholder='Interior Color'
                                                            value={interiorColor}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Interior Color is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.interior_color && errors.interior_color.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="fuel_type">Fuel Type</label>
                                                <Controller
                                                    name="fuel_type"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetFuelType}
                                                            options={fuelTypes}
                                                            placeholder='Fuel Type'
                                                            value={fuelType}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Fuel Type is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.fuel_type && errors.fuel_type.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="transmission">Transmission</label>
                                                <Controller
                                                    name="transmission"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetTransmissionType}
                                                            options={transmissionTypes}
                                                            placeholder='Transmission'
                                                            value={transmissionType}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Transmission is required field'}}
                                                    control={control}/>
                                                <span
                                                    className="error">{errors.transmission && errors.transmission.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="door_count">Door Count</label>
                                                <Controller
                                                    name="door_count"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetDoorCount}
                                                            options={doorCounts}
                                                            placeholder='Door Count'
                                                            value={doorCount}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Door Count is required field'}}
                                                    control={control}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="trim">Trim</label>
                                                <Controller
                                                    name="trim"
                                                    render={({}) => (
                                                        <ReactSelect
                                                            onChange={handleSetTrim}
                                                            options={trims}
                                                            placeholder='Trim'
                                                            value={trim}
                                                            isSearchable
                                                        />
                                                    )}
                                                    rules={{required: 'Trim is required field'}}
                                                    control={control}/>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="engine">Engine</label>
                                                <input
                                                    name="engine"
                                                    type="text"
                                                    placeholder='Engine'
                                                    className='form-control'
                                                    ref={register()}
                                                    defaultValue={vehicle.engine}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="engine_size">Engine Size</label>
                                                <input
                                                    name="engine_size"
                                                    type="text"
                                                    placeholder='Engine Size'
                                                    className='form-control'
                                                    ref={register()}
                                                    defaultValue={vehicle.engine_size}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="carfax_link">Carfax Link</label>
                                                <input
                                                    name="carfax_link"
                                                    type="text"
                                                    placeholder='Carfax Link'
                                                    className='form-control'
                                                    ref={register()}
                                                    defaultValue={vehicle.carfax_link}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="e_test">E Test</label>
                                                <input
                                                    name="e_test"
                                                    type="text"
                                                    placeholder='E Test'
                                                    className='form-control'
                                                    ref={register()}
                                                    defaultValue={vehicle.e_test}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="age">Age</label>
                                                <input
                                                    name="age"
                                                    type="text"
                                                    placeholder='Age'
                                                    className='form-control'
                                                    ref={register()}
                                                    defaultValue={vehicle.age}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="hwy_fuel">Hwy Fuel</label>
                                                <input
                                                    name="hwy_fuel"
                                                    type="text"
                                                    placeholder='Hwy Fuel'
                                                    className='form-control'
                                                    ref={register()}
                                                    defaultValue={vehicle.hwy_fuel}
                                                />
                                            </div>


                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="card card-shadow bg-gray-light">
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label className="switch switch-primary">
                                                                <span>Sold As Is</span>
                                                                <input type="checkbox" name="sold_as_is"
                                                                       checked={sold_as_is}
                                                                       onChange={onChangeSoldAsIs}
                                                                       value="0" ref={register}/>
                                                                <span className="slider"/>
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="switch switch-primary">
                                                                <span>Certificate Pre Owned</span>
                                                                <input type="checkbox" name="certificate_pre_owned"
                                                                       checked={certificatePreOwned}
                                                                       onChange={onChangeCertificatePreOwned}
                                                                       value="1" ref={register}/>
                                                                <span className="slider"/>
                                                            </label>
                                                        </div>

                                                        <label className="switch switch-primary">
                                                            <span>Is Active</span>
                                                            <input type="checkbox" name="is_active" checked={is_active}
                                                                   onChange={onChangeActive}
                                                                   value="1" ref={register}/>
                                                            <span className="slider"/>
                                                        </label>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="status">Status</label>
                                                        <Controller
                                                            name="status"
                                                            render={({}) => (
                                                                <ReactSelect
                                                                    onChange={handleChangeStatus}
                                                                    options={statuses}
                                                                    placeholder='Status'
                                                                    value={status}
                                                                    isSearchable
                                                                />
                                                            )}
                                                            rules={{required: 'Status is required field'}}
                                                            control={control}/>
                                                        <span
                                                            className="error">{errors.status && errors.status.message}</span>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="stock_number">Stock Number</label>
                                                        <input
                                                            name="stock_number"
                                                            type="text"
                                                            placeholder='Stock Number'
                                                            className='form-control'
                                                            ref={register()}
                                                            defaultValue={vehicle.stock_number}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="odometer">Odometer</label>
                                                        <input
                                                            name="odometer"
                                                            type="text"
                                                            placeholder='Odometer'
                                                            className='form-control'
                                                            ref={register()}
                                                            defaultValue={vehicle.odometer}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-shadow bg-gray-light my-4">
                                                <div className="card-header-none">
                                                    <h1>Financial</h1>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="price">Price</label>
                                                        <Controller
                                                            name="price"
                                                            render={({}) => (
                                                                <NumberFormat
                                                                    placeholder="Price"
                                                                    isNumericString={true}
                                                                    allowNegative="false"
                                                                    displayType={'input'}
                                                                    thousandSeparator
                                                                    value={price}
                                                                    className="form-control"
                                                                    onValueChange={onChangePrice}
                                                                />
                                                            )}
                                                            control={control}/>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="special_price">Special Price</label>
                                                        <Controller
                                                            render={({}) => (
                                                                <NumberFormat
                                                                    placeholder="Special Price"
                                                                    isNumericString={true}
                                                                    allowNegative="false"
                                                                    displayType={'input'}
                                                                    thousandSeparator
                                                                    value={special_price}
                                                                    className="form-control"
                                                                    onValueChange={onChangeSpecialPrice}
                                                                />
                                                            )}
                                                            name="special_price"
                                                            control={control}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="msrp">MSRP</label>
                                                        <Controller
                                                            render={({}) => (
                                                                <NumberFormat
                                                                    placeholder="MSRP"
                                                                    className="form-control"
                                                                    allowNegative="false"
                                                                    displayType={'input'}
                                                                    value={msrp}
                                                                    thousandSeparator
                                                                    isNumericString
                                                                    onValueChange={onChangeMSRP}
                                                                />
                                                            )}
                                                            name="msrp"
                                                            control={control}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="payment">Payment</label>
                                                        <input
                                                            name="payment"
                                                            type="text"
                                                            placeholder='Payment'
                                                            className='form-control'
                                                            ref={register()}
                                                            defaultValue={vehicle.payment}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="warranty">Warranty</label>
                                                <textarea name="warranty" placeholder="Warranty"
                                                          rows="4"
                                                          ref={register()}
                                                          defaultValue={vehicle.warranty}
                                                          className="form-control"/>
                                                <span
                                                    className="error">{errors.warranty && errors.warranty.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="dealer_comment">Dealer Comment</label>
                                                <textarea name="dealer_comment" placeholder="Dealer Comment"
                                                          rows="4"
                                                          ref={register()}
                                                          defaultValue={vehicle.dealer_comment}
                                                          className="form-control"/>
                                                <span
                                                    className="error">{errors.dealer_comment && errors.dealer_comment.message}</span>
                                            </div>
                                            <div className="form-group">
                                                <span>Description</span>
                                                <textarea name="description" placeholder="Description"
                                                          rows="4"
                                                          ref={register()}
                                                          defaultValue={vehicle.description}
                                                          className="form-control"/>
                                                <span
                                                    className="error">{errors.description && errors.description.message}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-raised"><i
                                        className="fas fa-save mr-2 bt-1"/>Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="tab-pane fade" id="VehicleImage" role="tabpanel">
                        {<VehicleImagesModal vehicle={vehicle} attachedFiles={attachedFiles}/>}
                    </div>
                    <div className="tab-pane fade" id="PurchaseManagement" role="tabpanel">
                        {<VehiclePurchaseForm vehicle={vehicle}/>}
                    </div>
                </div>
            </div>
        </div>
    );
};