export const SITE_NAME = 'HillzAutos';
export const SITE_URL = 'https://hillzautos.com/'
export const API_ADMIN_URL = SITE_URL + 'api/';
export const PAGE_SIZE_ROWS = 10;
export const PROFILE_IMAGE_URL = SITE_URL + 'uploads/user_profile_images/';

export const http = async (method, url, data) => {
    let response = await fetch(`${API_ADMIN_URL + url}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'access_token': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data)
    });
    const status_code = response.status;
    const status_message = response.statusText;
    response = await response.json();
    if (response.ok) return response;
    else {
        if (status_code === 503) {
        } else if (typeof response.messages === "string") {
        } else if (status_message) {
        }
    }
    return response;
};

export const httpUploadFile = async (method, url, data) => {
    let response = await fetch(`${API_ADMIN_URL + url}`, {
        method: method,
        headers: {
            'access_token': `Bearer ${localStorage.getItem('token')}`,
        },
        body: data
    });
    const status_code = response.status;
    const status_message = response.statusText;
    response = await response.json();
    if (response.ok) return response;
    else {
        if (status_code === 503) {
        } else if (typeof response.messages === "string") {
        } else if (status_message) {
        }
    }
    return response;
};

export const httpDownloadFile = async (method, url, data) => {
    let response = await fetch(`${API_ADMIN_URL + url}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'access_token': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data)
    });
    const status_code = response.status;
    const status_message = response.statusText;
    response = await response;
    if (status_code === 503) {
    } else if (typeof response.messages === "string") {
    } else if (status_message) {
    }
    return response;
};

//basic data
export const VEHICLE_OPTION = {
    MAKER: 1,
    MODEL: 2,
    BODY_STYLE: 3,
    TRIM: 4,
    COLOR: 5,
    TRANSMISSION_TYPE: 6,
    FUEL_TYPE: 7,
    DOOR_COUNT: 8,
    DRIVE_LINE: 9,
};
export const VEHICLE_OPTIONS_ARRAY = [
    // {label: 'Maker', field: 'maker', value: 1},
    // {label: 'Model', field: 'model', value: 2},
    {label: 'Body Style', field: 'body_style', value: 3},
    {label: 'Trim', field: 'trim', value: 4},
    {label: 'Color', field: 'color', value: 5},
    {label: 'Transmission Type', field: 'transmission_type', value: 6},
    {label: 'Fuel Type', field: 'fuel_type', value: 7},
    {label: 'Door Count', field: 'door_count', value: 8},
    // {label: 'Drive Line', field: 'driveline', value: 9},
];
export const VEHICLE_STATUS = {
    SOLD: 1,
    NOT_READY: 2,
    UNDER_REPAIR: 3
};
export const VEHICLE_STATUS_LIST = [
    {value: 1, label: 'Sold'},
    {value: 2, label: 'Not Ready'},
    {value: 3, label: 'Under Repair'}
];
export const SEARCH_PERIOD_LIST = [
    {value: 1, label: '1 Week Search'},
    {value: 2, label: '2 Week Search'},
    {value: 4, label: '4 Week Search'},
    {value: 6, label: '6 Week Search'},
    {value: 8, label: '8 Week Search'},
    {value: 16, label: '16 Week Search'}
];
export const CONDITION_LIST = [
    {value: 'Excellent', label: 'Excellent'},
    {value: 'Good', label: 'Good'},
    {value: 'Fair', label: 'Fair'},
    {value: 'Poor', label: 'Poor'},
]
export const VENDOR_TYPE_LIST = [
    {value: 1, label: "Bank"},
    {value: 2, label: 'Dealer Trader'},
    {value: 3, label: 'Warranty Company'},
];