import React, {useContext} from "react";
import {AuthContext} from "../../../common/context/auth";

export const Dashboard = () => {
    const auth = useContext(AuthContext)
    return (
        <div className="container" id='dashboard'>
            <h1>Dashboard</h1>
            <code>
                {JSON.stringify(auth.userInfo)}
            </code>
        </div>
    );
};

