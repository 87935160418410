import React, {useContext, useState} from "react";
import {AuthContext, isValidToken} from "../../../common/context/auth";
import {useForm} from "../../../common/hooks/forms-hook";
import {http} from "../../../env";
import {SignIn} from "./sign-in";
import {SignUp} from "./sign-up";

export const SignInUp = props => {
    const {setLoggedIn, setIsLoading} = useContext(AuthContext)
    const [formState, inputHandler] = useForm({
        username: {value: '', isValid: false},
        password: {value: '', isValid: false},
        first_name: {value: '', isValid: false},
        last_name: {value: '', isValid: false},
        email: {value: '', isValid: false},
        mobile: {value: '', isValid: false}
    }, false)

    const signupHandler = async evt => {
        evt.preventDefault()
        const body = {
            username: formState.inputs.username.value,
            password: formState.inputs.password.value,
            first_name: formState.inputs.first_name.value,
            last_name: formState.inputs.last_name.value,
            email: formState.inputs.email.value,
            mobile: formState.inputs.mobile.value
        };
        const _res = await http('POST', 'auth/signup', body)
        if (_res.ok) return handleResponse(_res.data)
        console.log(_res.message)
    }
    const handleResponse = (data) => {
        setIsLoading(false)
        localStorage.setItem('token', data['access_token']);
        setUserInfo()
    }
    const setUserInfo = () => {
        if (isValidToken()) {
            setLoggedIn(true)
            props.history.push('/dashboard')
        }
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-6">
                    <SignIn/>
                </div>
                <div className="col-6">
                    <SignUp/>
                </div>
            </div>
        </div>
    );
};

