import React, {useEffect, useState} from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {useForm, Controller} from "react-hook-form";
import {http, httpUploadFile} from "../../../env";
import {toast} from "react-toastify";
import {useHistory} from "react-router";
import ReactSelect from "react-select";

export const UserForm = props => {
    const {register, handleSubmit, control, errors} = useForm();

    let history = useHistory();
    const [user, setUser] = useState('');
    const [user_id, setUser_id] = useState(props.match.params.userId);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState({});
    const [isActive, setIsActive] = useState(1);
    const [avatarPreview, setAvatarPreview] = useState('');
    const [fileName, setFileName] = useState('Select avatar');
    const [avatar, setAvatar] = useState();
    const changeAvatarHandler = (e) => {
        const file = e.target.files[0];
        setAvatar(file);
        setFileName(file.name);
        setAvatarPreview(URL.createObjectURL(e.target.files[0]));
    };

    const handleChangeRole = selectedOption => {
        setRole(selectedOption);
    };

    const onChangeActive = value => setIsActive(value.target.checked);

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('avatar', avatar);
        formData.append('role_id', role.value);
        for (let key in data)
            formData.append(key, data[key]);
        let _res;
        if (user_id) {
            _res = await httpUploadFile('POST', 'user/update', formData);
        } else
            _res = await httpUploadFile('POST', 'user/save', formData);

        if (_res.ok) {
            history.push('/admin/edit-user/' + _res.data.id);
            toast.success('User saved successfully')
        } else
            toast.error('System error occurs. Please try again.');
    };

    const getUser = async () => {
        try {
            if (user_id) {
                const _res = await http('POST', 'user/getById', {id: user_id});
                if (_res.ok) {
                    setUser(_res.data);
                    setRole(_res.data.Role);
                    setIsActive(_res.data.is_active);
                    setAvatarPreview('/uploads/' + _res.data.avatar);

                } else console.log('message', _res.messages)
            }
        } catch (e) {
            toast.error(e.messages);
        }
    }

    const getRoles = async () => {
        try {
            const _res = await http('POST', 'role/get-all')
            if (_res.ok) setRoles(_res.data);
            else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getUser();
        getRoles();
    }, [props.match.params.userId])

    return (
        <form id="userProfileForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="breadcrumb d-flex flex-row justify-content-between align-items-center">
                <h4 className="mb-0 text-secondary"><i className="far fa-user ml-2"/> User Profile</h4>
                <button type="submit" className="btn btn-primary btn-raised"><i
                    className="fas fa-save mr-2"/>Save Changes
                </button>
            </div>
            <div className="divider border-top"/>
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="card card-shadow card-profile-1 mb-4">
                        <div className="card-body text-center">
                            <div className="form-group">
                                <div className="form-group">
                                    <img src={avatarPreview || '/images/profile-avatar.svg'} alt=''
                                         className="w-75 rounded"/>
                                </div>
                                <div className="custom-file">
                                    <input type="file" name="avatar" onChange={changeAvatarHandler}
                                           className="custom-file-input"/>
                                    <label className="custom-file-label" htmlFor="image">{fileName}</label>
                                    {errors.avatar && errors.avatar.message}
                                </div>

                            </div>
                            <span className="text-muted font-size-10 pr-2">Last Login date :</span>
                            <span className="text-muted font-size-11" id="last_login_date">{user.last_login_date}</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="card card-shadow mb-4">
                        <div className="card-header">
                            User Information
                        </div>
                        <input type="hidden" name="id" defaultValue={user.id} ref={register()}/>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="user_name">User Name</label>
                                <input type="text" name="user_name" id="user_name"
                                       defaultValue={user.user_name}
                                       ref={register({required: 'User Name is required field'})}
                                       className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password" name="password" id="password"
                                       ref={register()}
                                       className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="role">Role</label>
                                <Controller
                                    name="role"
                                    placeholder="Role"
                                    as={ReactSelect}
                                    defaultValue={role}
                                    options={roles}
                                    onChange={handleChangeRole}
                                    isSearchable
                                    rules={{required: 'Role is required field'}}
                                    control={control}>
                                </Controller>
                                <span className="error">{errors.role && errors.role.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="first_name">First Name</label>
                                <input type="text" name="first_name" id="first_name"
                                       className="form-control" placeholder="First Name" defaultValue={user.first_name}
                                       ref={register({required: 'First name is required.'})}/>
                                <span className="error">{errors.first_name && errors.first_name.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="last_name">Last Name</label>
                                <input type="text" name="last_name" id="last_name"
                                       defaultValue={user.last_name}
                                       className="form-control" placeholder="Last Name"
                                       ref={register({required: 'Last name is required.'})}/>
                                <span className="error">{errors.last_name && errors.last_name.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="commission">Commission</label>
                                <input type="text" name="commission" id="commission"
                                       defaultValue={user.commission}
                                       className="form-control" placeholder="Commission"
                                       ref={register({required: 'Commission is required.'})}/>
                                <span className="error">{errors.commission && errors.commission.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <input type="text" name="address" id="address"
                                       defaultValue={user.address}
                                       className="form-control" placeholder="Address"
                                       ref={register()}/>
                                <span className="error">{errors.address && errors.address.message}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="card card-shadow mb-4">
                        <div className="card-header">
                            Contact
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" dir="ltr" name="email" id="email" defaultValue={user.email}
                                       ref={register({required: 'Email is required field'})}
                                       className="form-control" placeholder="Email"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile</label>
                                <input type="text" dir="ltr" name="mobile"
                                       defaultValue={user.mobile}
                                       id="mobile" className="form-control " placeholder="Mobile"
                                       ref={register({required: 'Mobile is required.'})}/>
                                <span className="error">{errors.mobile && errors.mobile.message}</span>

                            </div>
                            <div className="form-group">
                                <label className="switch switch-primary">
                                    <span>Is Active</span>
                                    <input type="checkbox" name="is_active" checked={isActive}
                                           onChange={onChangeActive}
                                           value="1" ref={register}/>
                                    <span className="slider"/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
