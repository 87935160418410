import React, {useContext, useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm, Controller} from "react-hook-form";
import JoditEditor from "jodit-react";
import {TreeSelect} from 'antd';
import {API_ADMIN_URL, http, httpUploadFile} from "../../../env";
import {toast} from "react-toastify";
import {AuthContext} from "../../../common/context/auth";
import {PostEditingHistoriesModal} from "./post_editing_histories_modal";
import {PostAttachFilesModal} from "./post_attach_files_modal";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";

export const AddPost = (props) => {

    const {register, control, handleSubmit, errors} = useForm();
    // const {formData, data} = props;
    let history = useHistory();
    const auth = useContext(AuthContext);
    const [post_id, setPost_id] = useState(props.match.params.postId);
    const [post, setPost] = useState({
        "author": {id: 0, name: ""},
        "id": 0,
        "user_id": 0,
        "post_category_id": "",
        "post_category": {id: 0, title: ""},
        "author_id": "",
        "post_type": "",
        "total_rate": null,
        "title": "",
        "tags": "",
        "slug": "",
        "image_file": "",
        "description": "",
        "body": "",
        "image_alt": "",
        "image_title": "",
        "is_show": true,
        "visit_count": 0,
        "publishAt": "",
        "createdAt": "",
        "updatedAt": ""
    });

    const [postsCategoryTree, setPostsCategoryTree] = useState([]);
    const [post_category_id, setPost_category_id] = useState(parseInt(props.match.params.catId) || null);
    const [author_id, setAuthor_id] = useState(0);
    const [postCategory, setPostCategory] = useState({});
    const [post_type, setPost_type] = useState(0);
    const [is_show, setIs_show] = useState(1);
    const [body, setBody] = useState('');
    const [authors, setAuthors] = useState([]);
    const [histories, setHistories] = useState([]);
    const [attachedFiles, setAttachedFiles] = useState([]);

    // const config = {readonly: false};
    const initialList = [];
    const [tag, setTag] = useState('');
    const [slug, setSlug] = useState('');
    const [tags, setTags] = useState(initialList);

    const [imagePreview, setImagePreview] = useState('');
    const [fileName, setFileName] = useState('Select image');
    const [image, setImage] = useState();
    const config = {
        filebrowser: {
            createNewFolder: true,
            deleteFolder: true,
            ajax: {
                url: `${API_ADMIN_URL}post/files`,
                headers: {
                    'access_token': `Bearer ${localStorage.getItem('token')}`
                },
                method: 'POST',
                processData: true,
                dataType: 'json',
                contentType: 'application/json',

                prepareData: function (data) {
                    return JSON.stringify(data);
                },

                process: function (resp) {
                    return resp;
                }
            },
            uploader: {
                url: `${API_ADMIN_URL}post/uploads`,
                headers: {
                    'access_token': `Bearer ${localStorage.getItem('token')}`
                },
                method: 'POST',
                processData: true,
                dataType: 'json',
                prepareData: function (data) {
                    data.forEach((temp, temp1) => {
                        if (temp1.indexOf('files') !== -1) {
                            console.log(temp1)
                            data.append('file', temp);
                        }
                    })
                    data.forEach((temp, temp1) => {
                        if (temp1.indexOf('files') !== -1) {
                            data.delete(temp1);
                        }
                    })
                    if (data.has("files[1]"))
                        data.delete('files[1]');
                    return data;
                },
                process: function (resp) {
                    return {
                        files: resp.data.files || [],
                        path: resp.data.path,
                        baseurl: resp.data.baseurl,
                        error: resp.error,
                        msg: resp.msg
                    };
                },
            },
            // it defaults but still show their
        }
    };
    const handleChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setFileName(file.name);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
    };

    const getPost = async () => {
        try {
            if (post_id && post_id !== '') {
                const _res = await http('POST', 'post/get-by-id', {id: post_id})
                console.log(_res);
                if (_res.ok) {
                    setPost(_res.data)
                    setPostCategory(_res.data.post_category);
                    setPost_type(_res.data.post_type);
                    setTags(_res.data.tags.split(','))
                    setSlug(_res.data.slug);
                    setImagePreview('/uploads/' + _res.data.image_file)
                    setIs_show(_res.data.is_show)
                    setHistories(_res.data.post_editing_histories)
                    setPost_category_id(parseInt(_res.data.post_category_id))
                    setAuthor_id(_res.data.author_id)
                    setBody(_res.data.body)
                    const _files = await http('POST', 'post_attach_file/get-by-post-id', {id: post_id})
                    if (_files.ok)
                        setAttachedFiles(_files.data);

                } else console.log(_res.messages)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('image_file', image);
        formData.append('post_category_id', data.post_category_id);
        formData.append('author_id', data.author_id);
        formData.append('post_type', data.post_type);
        formData.append('title', data.title);
        formData.append('seo_title', data.seo_title);
        formData.append('seo_description', data.seo_description);
        formData.append('seo_keyword', data.seo_keyword);
        formData.append('tags', data.tags);
        formData.append('slug', data.slug);
        formData.append('image_file', data.image_file);
        formData.append('attach_files', attachedFiles);
        formData.append('description', data.description);
        formData.append('body', data.body);
        formData.append('image_alt', data.image_alt);
        formData.append('image_title', data.image_title);
        formData.append('is_show', data.is_show ? 1 : 0);
        formData.append('publishAt', data.publishAt);
        let _res = {};
        if (post_id && post_id !== '') {
            formData.append('id', post_id);
            formData.append('user_id', auth.userInfo.id);
            _res = await httpUploadFile('POST', 'post/update', formData);
        } else
            _res = await httpUploadFile('POST', 'post/save', formData);
        if (_res.ok) {
            history.push('/admin/edit-post/' + _res.data.id + "/" + _res.data.post_category_id);
            setPost(_res.data)
            setImagePreview('/uploads/' + _res.data.image_file)
            toast.success('Post saved successfully');
        } else
            toast.error('System error occurs. Please try again.');

    };
    const addTag = () => {
        if (tag) {
            setTags(tags.concat(tag));
            setTag('');
        }
    }
    const handleDeleteTag = (value) => {
        setTags(tags.filter(item => item !== value));
    }
    const onChange = value => setPost_category_id(value);
    const onChangeAuthor = value => setAuthor_id(value.target.value);
    const onChangePostType = value => setPost_type(value.target.value);

    const onChangeShow = value => setIs_show(value.target.checked);
    const getPostCategory = async () => {
        try {
            const _res = await http('GET', 'post_category/all')
            if (_res.ok) {
                setPostsCategoryTree(_res.data)
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }
    }
    const getAuthors = async () => {
        try {
            const _res = await http('GET', 'authors/all');
            if (_res.ok) setAuthors(_res.data);
            else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages);
        }
    }
    useEffect(() => {
        if (!postsCategoryTree.length)
            getPostCategory();
        if (!authors.length)
            getAuthors();
        if (!post.id)
            getPost();
    }, [props.match.params.postId])

    useEffect(() => {
        return () => {
            setPostsCategoryTree([]);
            setAuthors([]);
            setPost(null);
        };
    }, []);

    return (
        <div>
            {<PostEditingHistoriesModal post={post} histories={histories}/>}
            {<PostAttachFilesModal post={post} attachedFiles={attachedFiles}/>}
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className="d-flex flex-column flex-lg-row align-items-start ">
                    <div className="card card-shadow flex-grow-1 w-100">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h4>{post_id ?
                                <div>
                                    <span>Edit post</span>
                                    <Link className="font-size-13 ml-2" to={`/Blog/${postCategory.title}/${post.slug}`} target="_blank">
                                    Preview
                                    <i className="fas fa-eye pt-1 ml-1"/></Link>

                                </div>
                                : 'Create post'}
                            </h4>
                            <button type="submit" className="btn btn-primary btn-raised"><i
                                className="fas fa-save mr-2 bt-1"/>Publish
                            </button>
                        </div>
                        <div className="card-body">
                            <input type="hidden" name="tags" value={tags} ref={register()}/>
                            <div className="form-group">
                                <label htmlFor="Section">Post Section</label>
                                <select name="post_type" className="custom-select"
                                        value={post_type}
                                        onChange={onChangePostType}
                                        ref={register({required: 'Post Section is required field'})}>
                                    <option value="">Select Section</option>
                                    <option value="true">Article</option>
                                    <option value="false">News</option>
                                </select>
                                <span className="error">{errors.post_type && errors.post_type.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Category</label>
                                <Controller
                                    as={TreeSelect}
                                    defaultValue={post_category_id}
                                    showSearch
                                    style={{width: '100%'}}
                                    rules={{required: 'Category is required field.'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    placeholder="Please select"
                                    allowClear
                                    treeDefaultExpandAll
                                    name="post_category_id"
                                    onChange={onChange}
                                    treeData={postsCategoryTree}
                                    control={control}>
                                </Controller>
                                <span
                                    className="error">{errors.post_category_id && errors.post_category_id.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="title">Title</label>
                                <input
                                    name="title"
                                    type="text"
                                    placeholder='Title'
                                    className='form-control'
                                    ref={register({required: 'Title is required field'})}
                                    defaultValue={post.title}
                                />
                                <span className="error">{errors.title && errors.title.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="seo_title">Seo Title</label>
                                <input
                                    name="seo_title"
                                    type="text"
                                    placeholder='Seo Title'
                                    className='form-control'
                                    ref={register({required: 'Seo Title is required field'})}
                                    defaultValue={post.seo_title}
                                />
                                <span className="error">{errors.seo_title && errors.seo_title.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="seo_keyword">Seo Keyword</label>
                                <input
                                    name="seo_keyword"
                                    type="text"
                                    placeholder='Seo Keyword'
                                    className='form-control'
                                    ref={register({required: 'Seo Keyword is required field'})}
                                    defaultValue={post.seo_keyword}
                                />
                                <span className="error">{errors.seo_keyword && errors.seo_keyword.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Author">Author</label>
                                <select name="author_id" className="custom-select"
                                        onChange={onChangeAuthor}
                                        value={author_id}
                                        ref={register({required: 'Author is required field'})}>
                                    <option value="">Select Author</option>
                                    {authors.map((item) =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )};
                                </select>
                                <span className="error">{errors.author_id && errors.author_id.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="slug">Slug</label>
                                <input type="text" name="slug" id="slug" className="form-control" value={slug}
                                       onChange={(e) => setSlug(e.target.value.replace(/ /g, "-"))}
                                       dir="ltr"
                                       placeholder="slug" ref={register({required: 'slug is required field.'})}/>
                                <span className="error">{errors.slug && errors.slug.message}</span>

                            </div>
                            <div className="form-group">
                                <label className="switch switch-primary">
                                    <span>Publish</span>
                                    <input type="checkbox" name="is_show" checked={is_show}
                                           onChange={onChangeShow}
                                           value="1" ref={register}/>
                                    <span className="slider"/>
                                </label>
                            </div>
                            <div className="form-group">
                                <span>Seo description:</span>
                                <textarea name="seo_description" placeholder="Seo description"
                                          rows="4"
                                          ref={register({required: true, maxLength: 250})}
                                          defaultValue={post.seo_description}
                                          className="form-control"/>
                                <span
                                    className="error">{errors.seo_description?.type === "required" && "seo description is required"}</span>
                                <span
                                    className="error">{errors.seo_description?.type === "maxLength" && "seo description max length is 255 char"}</span>
                            </div>
                            <div className="form-group">
                                <span>Short description:</span>
                                <textarea name="description" placeholder="Short description"
                                          rows="4"
                                          ref={register({required: true, maxLength: 250})}
                                          defaultValue={post.description}
                                          className="form-control"/>
                                <span
                                    className="error">{errors.description?.type === "required" && "description is required"}</span>
                                <span
                                    className="error">{errors.description?.type === "maxLength" && "description max length is 255 char"}</span>
                            </div>
                            <div className="form-group">
                                <JoditEditor
                                    name="body"
                                    value={body}
                                    ref={register({required: 'Content is required field.'})}
                                    config={config}
                                    onBlur={newContent => setBody(newContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newContent => {
                                    }}
                                />
                                <span className="error">{errors.body && errors.body.message}</span>

                            </div>
                            <button type="submit" className="btn btn-primary btn-raised"><i
                                className="fas fa-save mr-2 bt-1"/>Publish
                            </button>
                        </div>
                    </div>
                    <div className="d-flex flex-column ml-0 mt-3 mt-lg-0 ml-lg-3  w-50">
                        <div className="card card-shadow mb-3">
                            <div className="card-body">
                                {/*<span data-toggle="modal" data-target="#postEditingHistoriesModal">*/}
                                {/*<i className="material-icons hover  ml-2 pointer"*/}
                                {/*data-toggle="tooltip"*/}
                                {/*title="Post editing history">history</i>*/}
                                {/*</span>*/}
                                <button type="button" data-toggle="modal" disabled={post.id == 0}
                                        data-target="#postAttachFilesModal"
                                        className="btn btn-outline-primary btn-raised btn-block"><i
                                    className="fas fa-file-upload mr-2 bt-1"/>Attach Files
                                </button>
                            </div>
                        </div>
                        <div className="card card-shadow ">
                            <div className="card-header">
                                <h4>Tags</h4>
                            </div>
                            <div className="card-body">
                                <div className="input-group mb-3">
                                    <input type="text" name="tag" id="Tag" className="form-control" value={tag}
                                           onChange={(e) => setTag(e.target.value)}
                                           placeholder="Tag name"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-primary" type="button" onClick={addTag}>
                                            <i className="fas fa-plus"/>
                                        </button>
                                    </div>
                                </div>
                                <div className="tags">
                                    {tags.map((item, index) => (
                                        <span className="badge badge-light font-size-13 mr-1" key={index}>
                                        {item}
                                            <span className="tag mr-1" title="Remove"/>
                                     <span className="pointer" onClick={() => handleDeleteTag(item)}>
                                         <i className="fas fa-times text-secondary" data-toggle="tooltip"/></span>
                                     </span>
                                    ))}
                                </div>

                            </div>
                        </div>
                        <div className="card card-shadow  mt-3">
                            <div className="card-header">
                                <h4>Image</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="image_file">Article Image:</label>
                                    <div className="custom-file">
                                        <input type="file" id="image_file" name="image_file" onChange={handleChange}
                                               className="custom-file-input"
                                               ref={register()}/>
                                        <label className="custom-file-label" htmlFor="image">{fileName}</label>
                                        {errors.image_file && errors.image_file.message}
                                    </div>
                                    <div className="form-group">
                                        <img src={imagePreview} alt='' className="w-100 rounded"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="imgAlt">image alternative</label>
                                    <input type="text" name="image_alt" ref={register()}
                                           id="imgAlt"
                                           className="form-control"
                                           defaultValue={post.image_alt}
                                           placeholder="image alternative"/>
                                    {errors.image_alt && errors.image_alt.message}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="imageTitle">image title</label>
                                    <input type="text" name="image_title" id="imageTitle"
                                           ref={register()}
                                           className="form-control"
                                           defaultValue={post.image_title}
                                           placeholder="image title"/>
                                    {errors.image_title && errors.image_title.message}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );
};

