import React, {useEffect, useState} from "react";
import {AuthorModal} from "./_add-edit-author_modal";
import {http, httpUploadFile} from "../../../env";
import $ from "jquery";
import {confirmAlert} from "react-confirm-alert";


export const AuthorManage = () => {
    const [isEdit, setIsEdit] = useState(false)
    const [modalTitle, setModalTitle] = useState('Add Author')
    const [author, setAuthor] = useState({id: '', title: '', body: '', description: ''})
    const [authors, setAuthors] = useState([])
    const [divKey, setDivKey] = useState(Date.now())
    const getAuthors = async () => {
        try {
            const _res = await http('GET', 'authors/all')
            console.log(_res)
            if (_res.ok) setAuthors(_res.data)
            else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }
    }

    useEffect(() => {
        getAuthors()
    }, [])

    const callBack = async (data) => {
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("name", data.name);
        formData.append("avatar", data.avatar);
        formData.append("avatar", data.avatarObj);
        formData.append("description", data.description);
        if (isEdit) await updateHandler(formData);
        else await saveHandler(formData)
        setIsEdit(false)
        setAuthor({id: '', name: '', avatar: '', description: ''})
    }
    const openNewModal = (status, item) => {
        setDivKey(Date.now)
        if (status) {
            setModalTitle(item.name)
            setAuthor(item)
            setModalTitle('Edit ' + item.name + ' Author')
            $("#authorModal").modal('show')
        } else {
            setAuthor({id: '', name: '', avatar: '', description: ''})
            setModalTitle('Add Author')
        }
        setIsEdit(status)
    }
    const saveHandler = async (data) => {
        $("#authorModal").modal('hide')
        const _res = await httpUploadFile('POST', 'authors/save', data)
        if (_res.ok) setAuthors(_res.data)
        // setAuthor({id: '', name: '', avatar: '', description: ''})

    }
    const updateHandler = async (data) => {
        $("#authorModal").modal('hide')
        const _res = await httpUploadFile('POST', 'authors/update', data)
        if (_res.ok) setAuthors(_res.data)
    }
    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-info mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'authors/delete', item)
            if (_res.ok) setAuthors(_res.data)
            else console.log(_res.messages)
        }
    }
    return (
        <div>
            <AuthorModal data={author} divKey={divKey} formData={callBack} modalTitle={modalTitle} isEdit={isEdit}/>
            <div className="row">
                <div className="col-12">
                    <div className="card card-shadow">
                        <div className="card-header"><h4>Author manage</h4></div>
                        <div className="card-body">
                            {/*<div className="row">*/}
                                {/*<div className="col-12 col-md-6 col-xl-7">*/}
                                    {/*<div className="input-group">*/}
                                        {/*<div className="input-group-prepend">*/}
                                            {/*<span className="input-group-text"><i className="fas fa-search"/></span>*/}
                                        {/*</div>*/}
                                        {/*<input type="text" id="" autoComplete="off" className="form-control"*/}
                                               {/*placeholder="search"/>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className="panel-box">
                                        {authors.map((item, key) =>
                                            <div className="card card-shadow text-center" key={key}>
                                                <div className="card-body">
                                                    <img
                                                        src={item.avatar === null ? '/images/profile-avatar.svg' : '/uploads/' + item.avatar}
                                                        alt="" width="70" className="rounded-circle-100"/>
                                                    <h5 className="pt-3 pb-2">{item.name}</h5>
                                                    <p className="text-muted font-size-11">{item.description}</p>
                                                    <div
                                                        className="d-flex  justify-content-end align-items-center mt-3">
                                                        <i className="material-icons hover pointer"
                                                           data-toggle="tooltip" title="Delete"
                                                           onClick={() => deleteItem(item)}>delete</i>
                                                        <i className="material-icons hover pointer"
                                                           data-toggle="tooltip" title="Edit"
                                                           onClick={() => openNewModal(true, item)}>edit</i>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="add-circle-btn-fix" data-toggle="modal" data-target="#authorModal"
                                         onClick={() => openNewModal(false)}>
                                        <i className="material-icons md-36 text-white">add</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}