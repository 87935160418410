import './inc/bootstrap'
import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import {AuthContext, isValidToken} from "./common/context/auth";
import {AdminLayout} from "./components/common/layout/admin_layout";
import {WebLayout} from "./components/common/layout/web_layout";

export const Index = () => {
    const auth = useContext(AuthContext)
    auth.isLoggedIn = isValidToken();
    console.log(auth.isLoggedIn)
    return (
        <>
            {auth.isLoggedIn ? <AdminLayout/> : <WebLayout/>}
        </>
    )
};
ReactDOM.render(<Index/>, document.getElementById('root'));

