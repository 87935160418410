import React, {useEffect, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import {http, PAGE_SIZE_ROWS, VEHICLE_OPTION} from "../../../env";
import {Link} from "react-router-dom";
import ReactTable from "react-table-v6";
import ReactSelect from "react-select";

export const CarFinderRequestManagement = props => {
    const [carFinderRequests, setCarFinderRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [tableState, setTableState] = useState(null);

    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [colors, setColors] = useState([]);
    const [bodyStyles, setBodyStyles] = useState([]);
    const [years, setYears] = useState([]);
    const [options, setOptions] = useState([]);

    const getVehicleOptionData = async () => {
        try {
            const _res = await http('POST', 'vehicle_option_data/all');
            if (_res.ok) {
                const vehicleOptions = _res.data;
                setOptions(_res.data);
                setBodyStyles(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.BODY_STYLE));
                setMakers(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.MAKER));
                setModels(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.MODEL));
                setColors(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.COLOR));
                // setTrims(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.TRIM));
                // setTransmissionTypes(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.TRANSMISSION_TYPE));
                // setFuelTypes(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.FUEL_TYPE));
                // setDoorCounts(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.DOOR_COUNT));
                // setDataIsReady(true);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    const makeYears = () => {
        let currentYear = new Date().getFullYear();
        let rows = [];
        for (let i = currentYear; i >= currentYear - 50; i--) {
            rows.push({value: i, label: i});
        }
        setYears(rows);
    }

    const getCarFinderRequests = async (state, instance) => {
        try {
            setLoading(true);
            const _res = await http('POST', 'car_finder_request/all', {
                pageSize: state.pageSize,
                page: state.page,
                sorted: state.sorted,
                filtered: state.filtered
            });
            if (_res.ok) {
                setCarFinderRequests(_res.data.carFinderRequests);
                setTableState(state);
                setLoading(false);
                setTotalPages(_res.data.totalPages);
            } else console.log(_res.messages);
        } catch (e) {
            console.log(e.messages)
        }
    };

    useEffect(() => {
        getVehicleOptionData();
        makeYears();
    }, []);

    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1 className="text-primary">Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-white-border btn-raised mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success btn-raised" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'car_finder_request/delete', item);
            if (_res.ok) getCarFinderRequests(tableState, null);
            else console.log(_res.messages);
        }
    };
    return (
        <div id='create-post-component'>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card border-0 shadow-3 rounded-0">
                        <div className="card-header">
                            <h4>List of car requests</h4>
                        </div>
                        <div className="card-body">
                            <ReactTable
                                getTheadFilterThProps={(state, rowInfo, column) => {
                                    return {
                                        style: {
                                            overflow: 'visible',
                                        }
                                    };
                                }}
                                data={carFinderRequests}
                                filterable
                                sortable
                                manual
                                pages={totalPages}
                                loading={loading} // Display the loading overlay when we need it
                                onFetchData={getCarFinderRequests} // Request new data when things change
                                collapseOnPageChange={true}
                                defaultPageSize={PAGE_SIZE_ROWS}
                                className="-striped -highlight"
                                loadingText="Loading ..."
                                defaultSorted={[
                                    {
                                        id: 'updatedAt',
                                        desc: true
                                    },
                                ]}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                defaultExpanded={{0: {index: true}}}
                                SubComponent={row => <span>{row.original.description}</span>}
                                columns={[
                                    {
                                        Header: 'Row',
                                        filterable: false,
                                        sortable: false,
                                        id: "row",
                                        maxWidth: 50,
                                        style: {textAlign: 'center'},
                                        className: "font-weight-bold",
                                        Cell: row => <div>{(row.index + 1) + (row.page * row.pageSize)}</div>
                                    },
                                    {
                                        Header: "Name",
                                        accessor: "id",
                                        Cell: row =>
                                            <div>{`${row.original.user.first_name} ${row.original.user.last_name}`}</div>
                                    },
                                    {
                                        Header: "Model",
                                        accessor: "model",
                                        Filter: ({filter, onChange}) =>
                                            <ReactSelect
                                                placeholder="Model"
                                                options={models}
                                                isSearchable
                                                onChange={event => onChange(event)}
                                                style={{width: "100%"}}
                                                value={filter ? filter.value : null}
                                            />,
                                        Cell: row => options && options.length > 0 && options.filter(key => key.value === row.value)[0].label
                                    },
                                    {
                                        Header: "Make",
                                        accessor: "maker",
                                        Filter: ({filter, onChange}) =>
                                            <ReactSelect
                                                placeholder="Maker"
                                                options={makers}
                                                isSearchable
                                                onChange={event => onChange(event)}
                                                style={{width: "100%"}}
                                                value={filter ? filter.value : null}
                                            />,
                                        Cell: row => options && options.length > 0 && options.filter(key => key.value === row.value)[0].label
                                    },
                                    {
                                        Header: "Color",
                                        accessor: "color",
                                        Filter: ({filter, onChange}) =>
                                            <ReactSelect
                                                placeholder="Color"
                                                options={colors}
                                                isSearchable
                                                onChange={event => onChange(event)}
                                                style={{width: "100%"}}
                                                value={filter ? filter.value : null}
                                            />,
                                        Cell: row => options && options.length > 0 && options.filter(key => key.value === row.value)[0].label
                                    },
                                    {
                                        Header: "Body Style",
                                        accessor: "body_style",
                                        Filter: ({filter, onChange}) =>
                                            <ReactSelect
                                                placeholder="Body Style"
                                                options={bodyStyles}
                                                isSearchable
                                                onChange={event => onChange(event)}
                                                style={{width: "100%"}}
                                                value={filter ? filter.value : null}
                                            />,
                                        Cell: row => options && options.length > 0 && options.filter(key => key.value === row.value)[0].label
                                    },
                                    {
                                        Header: "Year",
                                        accessor: "year",
                                        Filter: ({filter, onChange}) =>
                                            <ReactSelect
                                                placeholder="Year"
                                                options={years}
                                                isSearchable
                                                onChange={event => onChange(event)}
                                                style={{width: "100%"}}
                                                value={filter ? filter.value : null}
                                            />,
                                    },
                                    {
                                        Header: "Last Update",
                                        accessor: "updatedAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="updatedAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Action",
                                        filterable: false,
                                        sortable: false,
                                        // maxWidth: 90,
                                        Cell: row => (
                                            <span>
                                            <span onClick={() => deleteItem(row.original)}>
                                            <i
                                                className="material-icons hover pointer mr-2"
                                                data-toggle="tooltip" title="delete">delete</i> </span>
                                            <Link
                                                to={`/admin/edit-car-finder-request/${row.original.id}`}><i
                                                className="material-icons hover pointer ml-2"
                                                data-toggle="tooltip"
                                                title="Edit">edit</i> </Link>
                                                </span>
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

