import React, {useEffect, useContext} from 'react';
import {AuthContext, isValidToken} from "../../../common/context/auth";
import {useForm} from "react-hook-form";
import {http} from "../../../env";
import {toast} from "react-toastify";

export const SignIn = props => {
    const {setLoggedIn, setIsLoading} = useContext(AuthContext)
    const {register, handleSubmit, errors} = useForm();
    
    const onSubmit = async (data) => {
        const _res = await http('POST', 'auth/signin', data)
        if (_res.ok) {
            toast.success('Login completed successfully');
            handleResponse(_res.data)
        } else
            toast.error(_res.message);

    }
    const handleResponse = (data) => {
        setIsLoading(false)
        localStorage.setItem('token', data['access_token']);
        setUserInfo()
    }
    const setUserInfo = () => {
        if (isValidToken()) {
            setLoggedIn(true)
            window.location = '/user/profile'
        }
    }
    useEffect(() => {
        document.getElementById("user_name").focus();
        document.title = 'Sign in'
    }, []);
    return (
        <div className="container mt-5" id='login-component'>
            <div className="row">
                <div className="col-12">
                    <div className="card card-shadow shadow-sm">
                        <div className="card-header"><h4 className="text-primary">Sign in</h4></div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-3">
                                    <label htmlFor="username">UserName: </label>
                                    <input type="text" name="user_name" id="user_name" className="form-control"
                                           placeholder="User name"
                                           ref={register({required: 'User name is required.'})}/>
                                    <span className="error">{errors.user_name && errors.user_name.message}</span>
                                </div>
                                <div className="form-group mb-4">
                                    <label>Password: </label>

                                    <input type="password" name="password" id="password" className="form-control"
                                           placeholder="password" ref={register({required: 'Password is required.'})}/>
                                    <span className="error">{errors.password && errors.password.message}</span>
                                </div>
                                <button id="btnLogin" type="submit"
                                        className="btn btn-primary btn-lg btn-raised btn-block">
                                    Sign in
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

