import $ from 'jquery';

$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
        $(this).toggleClass('active');
    });
    $('.dropdown').on('click', function () {
        $(this).children('div.dropdown-content').toggleClass('dropdown-content-animate');
        $('header').find('div.dropdown-content').not($(this).children('div.dropdown-content')).removeClass('dropdown-content-animate');
    });
    $('.wrapper').click(function () {
        $('div.dropdown-content').removeClass('dropdown-content-animate');
    });
    // $("[data-fullscreen]").on("click", function () {
    //     var e = document.body;
    //     return document.fullScreenElement && null !== document.fullScreenElement || document.mozFullScreen || document.webkitIsFullScreen ? function (e) {
    //         var n = e.cancelFullScreen || e.webkitCancelFullScreen || e.mozCancelFullScreen || e.exitFullscreen;
    //         if (n) n.call(e); else if (void 0 !== window.ActiveXObject) {
    //             var l = new ActiveXObject("WScript.Shell");
    //             null !== l && l.SendKeys("{F11}")
    //         }
    //     }(document) : function (e) {
    //         var n = e.requestFullScreen || e.webkitRequestFullScreen || e.mozRequestFullScreen || e.msRequestFullscreen;
    //         if (n) n.call(e); else if (void 0 !== window.ActiveXObject) {
    //             var l = new ActiveXObject("WScript.Shell");
    //             null !== l && l.SendKeys("{F11}")
    //         }
    //     }(e), !1
    // });
});