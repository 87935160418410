import React, {useContext, useEffect, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {RoleFormModal} from "./role_form_modal.js";
import 'react-sortable-tree/style.css';
import {AuthContext} from "../../../common/context/auth";
import {http, PAGE_SIZE_ROWS} from "../../../env";
import {Link} from "react-router-dom";
import ReactTable from "react-table-v6";

export const RoleManagement = props => {
    const auth = useContext(AuthContext)
    const [isEdit, setIsEdit] = useState(false)
    const [modalTitle, setModalTitle] = useState('Add Role')
    const [role, setRole] = useState({id: '', title: '', parent_id: 0})
    const [divKey, setDivKey] = useState(Date.now())
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [tableState, setTableState] = useState(null);
    const getRole = async (state, instance) => {
        try {
            const _res = await http('POST', 'role/all', {
                pageSize: state.pageSize,
                page: state.page,
                sorted: state.sorted,
                filtered: state.filtered
            });
            if (_res.ok) {
                setTableState(state);
                setLoading(false);
                setTotalPages(_res.data.totalPages);
                setRoles(_res.data.roles)
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }
    }

    const callBack = async (data) => {
        if (isEdit) {
            await updateHandler(data);
        } else await saveHandler(data)
        setIsEdit(false)
        setRole({id: '', name: ''})
    }

    const openNewRoleModal = (status, item) => {
        setDivKey(Date.now)
        if (status) {
            setModalTitle(item.name)
            setRole(item)
            setModalTitle('Edit ' + item.name + ' Role')
        } else {
            setRole({id: '', name: ''});
            setModalTitle('Add Role')
        }
        setIsEdit(status)
    }
    const saveHandler = async (data) => {
        const _res = await http('POST', 'role/save', data);
        if (_res.ok) getRole(tableState, null);
    }
    const updateHandler = async (data) => {
        const _res = await http('POST', 'role/update', data);
        if (_res.ok) {
            getRole(tableState, null);
        }
    }
    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-info mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'role/delete', item)
            if (_res.ok) getRole(tableState, null);
            else console.log(_res.messages)
        }
    }

    return (
        <div id='create-post-component'>
            <RoleFormModal data={role} divKey={divKey} formData={callBack} modalTitle={modalTitle}
                           isEdit={isEdit}/>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card card-shadow">
                        <div className="card-header"><h4>List of roles</h4></div>
                        <div className="card-body">
                            <ReactTable
                                data={roles}
                                filterable
                                sortable
                                manual
                                pages={totalPages}
                                loading={loading} // Display the loading overlay when we need it
                                onFetchData={getRole} // Request new data when things change
                                collapseOnPageChange={true}
                                defaultPageSize={PAGE_SIZE_ROWS}
                                className="-striped -highlight"
                                loadingText="Loading ..."
                                defaultSorted={[
                                    {
                                        id: 'name',
                                        desc: false
                                    }
                                ]}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                columns={[
                                    {
                                        Header: 'Row',
                                        filterable: false,
                                        sortable: false,
                                        id: "row",
                                        maxWidth: 50,
                                        style: {textAlign: 'center'},
                                        className: "font-weight-bold",
                                        Cell: row => <div>{(row.index + 1) + (row.page * row.pageSize)}</div>
                                    },
                                    {
                                        Header: "Name",
                                        accessor: "name",
                                    },
                                    {
                                        Header: "Created date",
                                        accessor: "createdAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="createdAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Last Update",
                                        accessor: "updatedAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="updatedAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Action",
                                        filterable: false,
                                        sortable: false,
                                        Cell: row => (
                                            <span>
                                            <span onClick={() => deleteItem(row.original)}>
                                            <i
                                                className="material-icons hover pointer mr-2"
                                                data-toggle="tooltip" title="delete">delete</i> </span>
                                            <span data-toggle="modal" data-target="#roleModal"
                                                  onClick={() => openNewRoleModal(true, row.original)}>
                                            <i
                                                className="material-icons hover pointer"
                                                data-toggle="tooltip" title="Edit">edit</i> </span>
                                                </span>
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-circle-btn-fix" data-toggle="modal" data-target="#roleModal"
                 onClick={() => openNewRoleModal(false, {id: '', title: '', parent_id: 0})}>
                <i className="material-icons md-36 text-white">add</i>
            </div>
        </div>
    );
};

