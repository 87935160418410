import React, {useContext} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {AuthContext, getUserInfo, isValidToken} from "../../../common/context/auth";

const Menus = [
    {name: 'Home', url: '/home', private: true},
    {name: 'Signin', url: '/signin', private: false},
    {name: 'Signup', url: '/signup', private: false},
];
export const NavbarAdmin = props => {
    let {user_id, email, username} = ''
    const auth = useContext(AuthContext)
    const {setLoggedIn} = useContext(AuthContext)
    if (isValidToken()) {
        auth.isLoggedIn = true
        auth.userInfo = getUserInfo();
        username = auth.userInfo['username'];
        email = auth.userInfo['email'];
        user_id = auth.userInfo['id'];
    }
    const logout = () => {
        localStorage.clear()
        setLoggedIn(false)
        window.location='/';
    }
    return (
        <header className="shadow-sm">
            <nav className="navbar">
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <button type="button" id="sidebarCollapse" className="navbar-btn">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <div>
                        <div className="dropdown">
                            <i className="material-icons text-primary hover md-24" data-toggle="tooltip"
                               title="User Account">person_outline</i>
                            <div className="dropdown-content">
                                <Link to="/user/profile" className="nav-link">
                                    <i className="fas fa-user mr-2"/>User Profile
                                </Link>
                                <Link to="/user/change-password" className="nav-link">
                                    <i className="fas fa-fingerprint mr-2"/>Change Password
                                </Link>
                                <a href="/" className="nav-link" onClick={logout}><i className="fas fa-power-off mr-2"/>Sign Out</a>
                            </div>
                        </div>
                    </div>

                </div>
            </nav>
        </header>

    );
};
