import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {http} from "../../../env";
import ReactHtmlParser from "react-html-parser";

export const Page = () => {

    const [page, setPage] = useState('');
    const {slug} = useParams();
    document.title = slug;

    const getPage = async () => {
        try {
            const _res = await http('POST', 'page/getBySlug', {slug: slug});
            if (_res.ok) {
                setPage(_res.data)
                console.log(_res);
            }
        } catch (e) {
        }
    };

    useEffect(() => {
        getPage()
    }, []);

    return (
        <div className="container-fluid my-4">
            <div className="card mb-4 contact-bg">
                <div className="card-header bg-white">
                    <h3 className="text-primary">{page.title}</h3>
                </div>
                <div className="card-body" style={{height: "500px"}}>
                    <div className="row">
                        <div className="col-8">
                            <p className="line-height-normal">
                                {ReactHtmlParser(page.content)}
                            </p>
                        </div>
                        <div className="col-4"><img src="/images/contact.svg" alt="" /></div>
                    </div>
                </div>
            </div>
        </div>

    );
};
