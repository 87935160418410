import React, {useEffect, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import {http, PAGE_SIZE_ROWS} from "../../../env";
import ReactTable from "react-table-v6";
import {PostCommentAdminReplyModal} from "./post_comment_admin_reply_modal";

export const CommentsList = () => {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState({});
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [tableState, setTableState] = useState(null);

    const getComments = async (state, instance) => {
        try {
            setLoading(true);
            const _res = await http('POST', 'post_comment/all', {
                pageSize: state.pageSize,
                page: state.page,
                sorted: state.sorted,
                filtered: state.filtered
            });
            if (_res.ok) {
                setTableState(state);
                setLoading(false);
                setTotalPages(_res.data.totalPages);
                setComments(_res.data.comments);
            } else console.log(_res.messages);
        } catch (e) {
            console.log(e.messages)
        }
    };

    const onChangeIsApprove = async value => {
        try {
            const _res = await http('POST', 'post_comment/update-approve', {
                id: value.target.value,
                is_approve: value.target.checked
            });
            if (!_res.ok) console.log(_res.messages);
        } catch (e) {
            console.log(e.messages)
        }
    }

    useEffect(() => {
        // getComments()
    }, []);

    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1 className="text-primary">Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-white-border btn-raised mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success btn-raised" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'post_comment/delete', item);
            if (_res.ok) getComments(tableState, null);
            else console.log(_res.messages);
        }
    };

    return (
        <div id='create-post-component'>
            {<PostCommentAdminReplyModal comment={comment} setComments={setComments} setTotalPage={setTotalPages}
                                         pageSize={PAGE_SIZE_ROWS}/>}
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card border-0 shadow-3 rounded-0">
                        <div className="card-header">
                            <h4>List of comments</h4>
                        </div>
                        <div className="card-body">
                            <ReactTable
                                data={comments}
                                filterable
                                sortable
                                manual
                                pages={totalPages}
                                loading={loading} // Display the loading overlay when we need it
                                onFetchData={getComments} // Request new data when things change
                                collapseOnPageChange={true}
                                defaultPageSize={PAGE_SIZE_ROWS}
                                className="-striped -highlight"
                                loadingText="Loading ..."
                                defaultSorted={[
                                    {
                                        id: 'createdAt',
                                        desc: true
                                    },
                                    {
                                        id: 'updatedAt',
                                        desc: true
                                    },
                                ]}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                defaultExpanded={{0: {index: true}}}
                                SubComponent={row => <div>
                                    <fieldset>
                                        <legend>Comment</legend>
                                        <p
                                            className="white-space-pre-line line-height-normal mb-1 text-justify">
                                            {row.original.content}
                                        </p>
                                    </fieldset>

                                </div>}
                                columns={[
                                    {
                                        Header: 'Row',
                                        filterable: false,
                                        sortable: false,
                                        id: "row",
                                        maxWidth: 50,
                                        style: {textAlign: 'center'},
                                        className: "font-weight-bold",
                                        Cell: row => <div>{(row.index + 1) + (row.page * row.pageSize)}</div>
                                    },
                                    {
                                        Header: "Author",
                                        accessor: "author_name",
                                    },
                                    {
                                        Header: "Post",
                                        accessor: "post.title",
                                    },
                                    {
                                        Header: "Author Email",
                                        accessor: "author_email",
                                    },
                                    {
                                        Header: "Author Ip",
                                        accessor: "author_ip",
                                        maxWidth: 70,
                                        filterable: false
                                    },
                                    {
                                        Header: "Approve",
                                        accessor: "is_approve",
                                        Filter: ({filter, onChange}) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{width: "100%"}}
                                                value={filter ? filter.value : "all"}
                                            >
                                                <option value="all">Show All</option>
                                                <option value="1">Approved</option>
                                                <option value="0">Not Approved</option>
                                            </select>,
                                        Cell: row => <label className="switch switch-primary">
                                            <span>&nbsp;</span>
                                            <input key={row.original.id} type="checkbox" name="is_show"
                                                   defaultChecked={!!row.value}
                                                   onChange={onChangeIsApprove}
                                                   defaultValue={row.original.id}/>
                                            <span className="slider"/>
                                        </label>
                                    },
                                    {
                                        Header: "Created date",
                                        accessor: "createdAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="createdAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Last Update",
                                        accessor: "updatedAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="updatedAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Action",
                                        filterable: false,
                                        sortable: false,
                                        maxWidth: 90,
                                        Cell: row => {
                                            return [<span key={row.original.id}
                                                          onClick={() => deleteItem(row.original)}>
                                            <i
                                                className="material-icons hover pointer mr-2"
                                                data-toggle="tooltip" title="delete">delete</i> </span>,
                                                row.original.parent_id === 0 ?
                                                    <span key={row.original.id * -1} data-toggle="modal"
                                                          data-target="#postCommentModal"
                                                          onClick={() => setComment(row.original)}>
                                            <i className="material-icons hover pointer"
                                               data-toggle="tooltip" title="Reply">reply</i> </span> : '']
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

