import React from 'react'
import {Route, Switch} from 'react-router-dom';
import {Dashboard} from "../../admin/dashbaord/dashbaord";
import {RoutesCommon} from "./routes-common";

export const RoutesWeb = () => {
    return (
        <>
            <Switch>
                <RoutesCommon/>
                <Route exact path={'/dashboard'} component={Dashboard}/>
            </Switch>
        </>
    )
};