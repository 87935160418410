import React, {useContext, useEffect, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import {http, PAGE_SIZE_ROWS} from "../../../env";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

export const NewslettersSubscribeList = props => {
    const [subscribes, setSubscribes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const getSubscribes = async (state, instance) => {
        try {
            setLoading(true);
            const _res = await http('POST', 'newsletter_subscribe/all', {
                pageSize: state.pageSize,
                page: state.page,
                sorted: state.sorted,
                filtered: state.filtered
            });
            if (_res.ok) {
                setLoading(false);
                setTotalPages(_res.data.totalPages);
                setSubscribes(_res.data.subscribes);
            } else console.log(_res.messages);
        } catch (e) {
            console.log(e.messages)
        }
    };

    useEffect(() => {
    }, []);

    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1 className="text-primary">Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-white-border btn-raised mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success btn-raised" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'newsletter_subscribe/delete', item);
            if (_res.ok) setSubscribes(_res.data);
            else console.log(_res.messages);
        }
    };
    return (
        <div id='newsletters'>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card border-0 card-shadow rounded-0">
                        <div className="card-header">
                            <h4>List of newsletters subscribe</h4>
                        </div>
                        <div className="card-body">
                            <ReactTable
                                data={subscribes}
                                filterable
                                sortable
                                manual
                                pages={totalPages}
                                loading={loading} // Display the loading overlay when we need it
                                onFetchData={getSubscribes} // Request new data when things change
                                collapseOnPageChange={true}
                                defaultPageSize={PAGE_SIZE_ROWS}
                                className="-striped -highlight"
                                loadingText="Loading ..."
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                columns={[
                                    {
                                        Header: 'Row',
                                        filterable: false,
                                        sortable: false,
                                        id: "row",
                                        maxWidth: 50,
                                        style: {textAlign: 'center'},
                                        className: "font-weight-bold",
                                        Cell: row => <div>{(row.index + 1) + (row.page * row.pageSize)}</div>
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                        minWidth: 160,
                                    },
                                    {
                                        Header: "Full name",
                                        accessor: "full_name",
                                        minWidth: 130,

                                    },
                                    {
                                        Header: "Mobile",
                                        accessor: "mobile",
                                        minWidth: 130,
                                    },
                                    {
                                        Header: "Created date",
                                        accessor: "createdAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="createdAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Last Update",
                                        accessor: "updatedAt",
                                        minWidth: 150,
                                        filterable: false,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Action",
                                        filterable: false,
                                        sortable: false,
                                        Cell: row => (
                                            <span>
                                            <span onClick={() => deleteItem(row.original)}>
                                            <i
                                                className="material-icons hover pointer mr-2"
                                                data-toggle="tooltip" title="delete">delete</i> </span>
                                                </span>
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

