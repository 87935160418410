import React from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm} from "react-hook-form";

export const RoleFormModal = (props) => {
    const {formData, data, divKey, modalTitle, isEdit} = props;

    const callBackHandler = values => formData({
        id: data.id,
        name: values.name,
    })

    const {register, handleSubmit, errors} = useForm();

    return (
        <div className="modal fade" id="roleModal" aria-labelledby="roleModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content" key={divKey}>
                    <div className="modal-header">
                        <h5 className="modal-title">{modalTitle}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="name">Role name</label>
                        <div className="form-group">
                            <input
                                name="name"
                                type="text"
                                placeholder='Name'
                                className='form-control'
                                ref={register({required: "Required"})}
                                defaultValue={data.name}
                            />
                            {errors.name && errors.name.message}
                        </div>

                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <button type="button" className='btn btn-primary btn-raised' data-dismiss="modal"
                                onClick={handleSubmit(callBackHandler)}>
                            {isEdit ? <i className="fas fa-save mr-2"/> : <i className="fas fa-plus mr-2"/>}
                            {isEdit ? 'Update' : 'Add'}
                        </button>
                        <button type="button" className="btn btn-white-border" data-dismiss="modal">Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

