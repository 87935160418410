import React, {useContext, useState} from "react";
import {AuthContext, isValidToken} from "../../../common/context/auth";
import {useForm} from "../../../common/hooks/forms-hook";
import {http} from "../../../env";
import {Input} from "../../../common/form-elements/input/input";
import {validatorRequire} from "../../../common/helper/validators";
import {Button} from "../../../common/form-elements/button/button";

export const Signup = props => {
    const {setLoggedIn, setIsLoading} = useContext(AuthContext)
    const [formState, inputHandler] = useForm({
        username: {value: '', isValid: false},
        password: {value: '', isValid: false},
        first_name: {value: '', isValid: false},
        last_name: {value: '', isValid: false},
        email: {value: '', isValid: false},
        mobile: {value: '', isValid: false}
    }, false)

    const signupHandler = async evt => {
        evt.preventDefault()
        const body = {
            username: formState.inputs.username.value,
            password: formState.inputs.password.value,
            first_name: formState.inputs.first_name.value,
            last_name: formState.inputs.last_name.value,
            email: formState.inputs.email.value,
            mobile: formState.inputs.mobile.value
        };
        const _res = await http('POST', 'auth/signup', body)
        if (_res.ok) return handleResponse(_res.data)
        console.log(_res.message)
    }
    const handleResponse = (data) => {
        setIsLoading(false)
        localStorage.setItem('token', data['access_token']);
        setUserInfo()
    }
    const setUserInfo = () => {
        if (isValidToken()) {
            setLoggedIn(true)
            props.history.push('/dashboard')
        }
    }

    return (
        <div className="container" id='signin-component'>
            <div className="row justify-content-center">
                <div className="col-4">
                    <div className="card border-0 shadow-3 rounded-0">
                        <div className="card-header border-bottom  border-secondary bg-white">Signup</div>
                        <div className="card-body">
                            <form onSubmit={signupHandler}>
                                <div className="form-group">
                                    <Input
                                        id='username'
                                        element="input"
                                        type="text"
                                        placeholder='Username'
                                        className='form-control rounded-0 border-0'
                                        errorText='Enter valid username.'
                                        onInput={inputHandler}
                                        validators={[validatorRequire()]}
                                    />
                                </div>
                                <div className="form-group">
                                    <Input
                                        id='password'
                                        element="input"
                                        type="password"
                                        placeholder='password'
                                        className='form-control rounded-0 border-0'
                                        errorText='Enter valid password.'
                                        onInput={inputHandler}
                                        validators={[validatorRequire()]}
                                    />
                                </div>
                                <div className="form-group">
                                    <Input
                                        id='first_name'
                                        element="input"
                                        type="text"
                                        placeholder='First Name'
                                        className='form-control rounded-0 border-0'
                                        errorText='Enter valid first name.'
                                        onInput={inputHandler}
                                        validators={[validatorRequire()]}
                                    />
                                </div>
                                <div className="form-group">
                                    <Input
                                        id='last_name'
                                        element="input"
                                        type="text"
                                        placeholder='Last Name'
                                        className='form-control rounded-0 border-0'
                                        errorText='Enter valid last name.'
                                        onInput={inputHandler}
                                        validators={[validatorRequire()]}
                                    />
                                </div>
                                <div className="form-group">
                                    <Input
                                        id='email'
                                        element="input"
                                        type="text"
                                        placeholder='Email'
                                        className='form-control rounded-0 border-0'
                                        errorText='Enter valid email.'
                                        onInput={inputHandler}
                                        validators={[validatorRequire()]}
                                    />
                                </div>
                                <div className="form-group">
                                    <Input
                                        id='mobile'
                                        element="input"
                                        type="text"
                                        placeholder='Mobile'
                                        className='form-control rounded-0 border-0'
                                        errorText='Enter valid mobile.'
                                        onInput={inputHandler}
                                        validators={[validatorRequire()]}
                                    />
                                </div>
                                <Button className='btn btn-accent rounded-0 w-100' type='submit' disabled={!formState.isValid}>Signup</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

