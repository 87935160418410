import React, {useContext} from 'react'
import {useForm} from 'react-hook-form'
import {AuthContext} from '../../../common/context/auth'
import {http} from '../../../env'
import {toast} from 'react-toastify'

export const ContactUs = ({history}) => {
    const fakeInfo = ["alex", 'nori', 'alex@gmail.com', '0987654321'];
    const {isLoggedIn, userInfo} = useContext(AuthContext);
    document.title = "Contact Us";

    return (
        <>
            {!isLoggedIn ? <Form userInfo={fakeInfo}/> : history.push('/signin')}
        </>
    )
};

function Form({userInfo}) {
    const {handleSubmit, errors, register} = useForm();
    const onSubmit = async data => {
        await http('POST', 'request-form/save', data)
            .then(resp => {

                if (!resp.ok) return toast.error(resp);
                else return toast.success('Contact Request Successfully Sent')

            }).catch(err => {
                return toast.error(err.message)
            })
    }
    return (
        <div className="container-fluid my-3">
            <div className="row d-flex justify-content-md-around mt-5">
                <div className="col-12 col-md-6 h-100-vh d-flex flex-column align-items-center justify-content-start">
                    <h2 className="font-weight-bold font-size-17 text-primary my-3">Contact Us</h2>
                    <img  src="/images/mail-sent-animate.svg"
                          width="300" alt=""/>
                    <div className="card card-shadow my-3">
                        <div className="card-body d-flex justify-content-start align-items-center">
                            <i className="material-icons md-36 contact mr-4">location_on</i>
                            <div className="d-flex flex-column flex-wrap">
                                <span
                                    className="font-weight-bold font-size-13 text-secondary">address</span>
                                <p
                                    className="text-muted font-size-12">2400 - 1055 West Georgia Street,
                                    Vancouver, BC , V6E 3P3</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-12 col-md-6 h-100-vh px-0">
                    <div id="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.5251383009845!2d-123.12373878478144!3d49.28539457850022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486718198bee59f%3A0x81fbdd1a9caa915d!2s1055%20W%20Georgia%20St%2C%20Vancouver%2C%20BC%20V6E%203P3!5e0!3m2!1sen!2sca!4v1602593371626!5m2!1sen!2sca"
                             frameBorder="0" className="border-0 h-100-vh w-100" allowFullScreen=""
                            aria-hidden="false" tabIndex="0"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
