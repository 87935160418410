import React from "react";
import {Redirect} from "react-router";

export const Logout = () => {

    return (
        <Redirect to={'/signin'}/>
    );
};

