import React, {useState} from 'react';
import {ViewPost} from "../blog/post";
import {Link} from 'react-router-dom';
import {useHistory} from "react-router";

export const Home = () => {
    document.title = "Hillz Autos |  Car Technical Data";
    let history = useHistory();
    const searchBox =
        {
            top: 70
        }
    const [keyword, setKeyword] = useState(null);

    const searchButtonOnClick = () => {
        if (keyword) history.push('/vehicles?keyword=' + keyword);
    }

    const searchBoxOnChange = (value) => setKeyword(value.target.value);

    return (
        <div className='w-100'>
            {/*<div className="d-flex position-relative justify-content-center align-items-center px-0">*/}
                {/*/!*<span className="background-mask"/>*!/*/}
                {/*<section*/}
                    {/*className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center index-9 px-3 pt-5">*/}
                    {/*<p className="line-height-1  font-size-16 mb-3 text-white ">*/}
                    {/*</p>*/}
                {/*</section>*/}
            {/*</div>*/}
            <img src="/images/hillzautos-banner.jpg" alt="" className="w-100 h-auto"/>

            <div>
                <ViewPost/>
            </div>
        </div>

    );
};
