import React, {useContext, useEffect, useState} from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {useForm} from "react-hook-form";
import {http, httpUploadFile} from "../../../env";
import {toast} from "react-toastify";
import {AuthContext} from "../../../common/context/auth";

export const UserProfile = () => {
    document.title = 'Admin Panel';
    
    const {register, handleSubmit, errors} = useForm();
    const auth = useContext(AuthContext)
    const [user, setUser] = useState('');
    const [avatarPreview, setAvatarPreview] = useState('');
    const [fileName, setFileName] = useState('Select avatar');
    const [avatar, setAvatar] = useState();
    const changeAvatarHandler = (e) => {
        const file = e.target.files[0];
        setAvatar(file);
        setFileName(file.name);
        setAvatarPreview(URL.createObjectURL(e.target.files[0]));
    };
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('avatar', avatar);
        for (let key in data)
            formData.append(key, data[key]);
        const _res = await httpUploadFile('POST', 'user/updateProfile', formData);
        if (_res.ok) {
            localStorage.setItem('token', _res.data.access_token);
            toast.success('Update completed successfully')
        } else
            toast.error('System error occurs. Please try again.');
    };

    const getUser = async () => {
        try {
            const _res = await http('POST', 'user/getById', {id: auth.userInfo.id});
            if (_res.ok) {
                setUser(_res.data)
                setAvatarPreview('/uploads/' + _res.data.avatar);
            } else console.log('message', _res.messages)
        } catch (e) {
            toast.error(e.messages);
        }
    }
    
    useEffect(() => {
        getUser()
    }, [])

    return (
        <form id="userProfileForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="breadcrumb d-flex flex-row justify-content-between align-items-center">
                <h4 className="mb-0 text-secondary"><i className="far fa-user ml-2"/> User Profile</h4>
                <button type="submit" className="btn btn-primary btn-raised"><i
                    className="fas fa-save mr-2"/>Save Changes
                </button>
            </div>
            <div className="divider border-top"/>
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="card card-shadow card-profile-1 mb-4">
                        <div className="card-body text-center">
                            <div className="form-group">
                                <div className="form-group">
                                    <img src={avatarPreview || '/images/profile-avatar.svg'} alt=''
                                         className="w-75 rounded"/>
                                </div>
                                <div className="custom-file">
                                    <input type="file" name="avatar" onChange={changeAvatarHandler}
                                           className="custom-file-input"/>
                                    <label className="custom-file-label" htmlFor="image">{fileName}</label>
                                    {errors.avatar && errors.avatar.message}
                                </div>

                            </div>
                            <span className="text-muted font-size-10 pr-2">Last Login date :</span>
                            <span className="text-muted font-size-11"
                                  id="last_login_date">{new Intl.DateTimeFormat("en-GB", {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                                hour: "numeric",
                                minute: "numeric",
                            }).format(new Date(user.last_login_date ? user.last_login_date : null).getTime())}</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="card card-shadow mb-4">
                        <div className="card-header">
                            User Information
                        </div>
                        <input type="hidden" name="id" defaultValue={user.id} ref={register()}/>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="user_name">User Name</label>
                                <input type="text" name="user_name" id="user_name" readOnly disabled
                                       defaultValue={user.user_name}
                                       className="form-control"/>

                            </div>
                            <div className="form-group">
                                <label htmlFor="first_name">First Name</label>
                                <input type="text" name="first_name" id="first_name"
                                       className="form-control" placeholder="First Name" defaultValue={user.first_name}
                                       ref={register({required: 'First name is required.'})}/>
                                <span className="error">{errors.first_name && errors.first_name.message}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="last_name">Last Name</label>
                                <input type="text" name="last_name" id="last_name"
                                       defaultValue={user.last_name}
                                       className="form-control" placeholder="Last Name"
                                       ref={register({required: 'Last name is required.'})}/>
                                <span className="error">{errors.last_name && errors.last_name.message}</span>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="card card-shadow mb-4">
                        <div className="card-header">
                            Contact
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" dir="ltr" name="email" id="email" defaultValue={user.email}
                                       readOnly disabled
                                       className="form-control" placeholder="Email"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile</label>
                                <input type="text" dir="ltr" name="mobile"
                                       defaultValue={user.mobile}
                                       id="mobile" className="form-control " placeholder="Mobile"
                                       ref={register({required: 'Mobile is required.'})}/>
                                <span className="error">{errors.mobile && errors.mobile.message}</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};
