import React from "react";
import {BrowserRouter as Router, Link} from "react-router-dom";
import {AuthContextProvider} from "../../../common/context/auth";
import {Navbar} from "../../web/navbar/navbar";
import {RoutesWeb} from "../routes/routes-web";
import '../../../assets/sass/app.scss';
import {Toast} from "../toast/toast-container";
import {http, SITE_NAME} from "../../../env";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";

export const WebLayout = () => {
    const {register, handleSubmit, errors} = useForm();
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const subscribeHandle = async (data, e) => {
        let _res = await http('POST', 'newsletter_subscribe/add', data)
        if (_res.ok) {
            e.target.reset();
            toast.success('You have successfully completed the newsletter.');
        } else
            toast.error(_res.message);
    }

    return (
        <AuthContextProvider>
            <Router>
                <Navbar/>
                <Toast/>
                <main>
                    <RoutesWeb/>
                </main>
                <footer>
                    <div className="container-fluid">
                        <div className="justify-content-around align-items-center p-4 ">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <address className="mt-3 mt-md-0">
                                        <h5 className="font-weight-bold text-white  pb-2">Address</h5>
                                        <div className="d-flex justify-content-start align-items-center mt-4 ">
                                            <i className="material-icons md-24 circle-bg-white mr-3">location_on</i>
                                            <p className="text-white mb-0">2400 - 1055 West Georgia Street,
                                                Vancouver, BC , V6E 3P3</p>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center mt-4 ">
                                            <i className="material-icons md-24 circle-bg-white mr-3">phone</i>
                                            <p className="mb-0"><a className="text-white font-size-13" href="tel:+18887254455">+18887254455</a></p>
                                        </div>
                                    </address>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="mt-3 mt-md-0">
                                        <h5 className="font-weight-bold text-white  pb-2">Find us in google map</h5>
                                        <div id="map">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.5251383009845!2d-123.12373878478144!3d49.28539457850022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486718198bee59f%3A0x81fbdd1a9caa915d!2s1055%20W%20Georgia%20St%2C%20Vancouver%2C%20BC%20V6E%203P3!5e0!3m2!1sen!2sca!4v1602593371626!5m2!1sen!2sca"
                                                frameBorder="0" height="200" className="border-0 w-100"
                                                allowFullScreen=""
                                                aria-hidden="false" tabIndex="0"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="mt-3 mt-md-0">
                                        <h5 className="font-weight-bold text-white  pb-2">Subscribe</h5>
                                        <form onSubmit={handleSubmit(subscribeHandle)}>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i
                                        className="far fa-envelope opacity-38"/></span>
                                                </div>
                                                <input type="email" name="email"
                                                       className="form-control"
                                                       placeholder="Email" ref={register({
                                                    required: "Required",
                                                    pattern: emailPattern
                                                })}/>
                                            </div>
                                            <div className="form-group">
                                                     <span
                                                         className="error">{errors.email?.type === "required" && errors.email.message}
                                                         {errors.email?.type === "pattern" && "Please enter valid email."}
                                                    </span>
                                            </div>

                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i
                                        className="far fa-user opacity-38"/></span>
                                                </div>
                                                <input type="text" name="full_name" id="full_name"
                                                       className="form-control"
                                                       placeholder="Full Name"
                                                       ref={register}/>
                                            </div>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i
                                        className="fas fa-phone-alt opacity-38"/></span>
                                                </div>
                                                <input type="text" dir="ltr" name="mobile"
                                                       id="mobile" className="form-control " placeholder="Phone"
                                                       ref={register}/>
                                            </div>
                                            <button type="submit"
                                                    className="btn btn-outline-light">
                                                Send
                                                <i className="fa fa-paper-plane ml-1" aria-hidden="true"/>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center pt-3">
                            <p className="text-white opacity-48">© 2020 {SITE_NAME}.com. All rights reserved</p>
                        </div>
                        {/*<div className="pt-3 d-flex flex-column flex-md-row  justify-content-md-around">*/}
                        {/*<div className="col d-flex flex-row align-items-end mb-3 mr-5">*/}
                        {/*<a className="facebook" href="https://www.facebook.com/test" target="_blank"*/}
                        {/*title="facebook">*/}
                        {/*<i className="fab fa-facebook fa-2x"/>*/}
                        {/*</a>*/}
                        {/*<a className="twitter" href="https://twitter.com/test" target="_blank"*/}
                        {/*title="twitter">*/}
                        {/*<i className="fab fa-twitter fa-2x"/>*/}
                        {/*</a>*/}
                        {/*<a className="telegram" href="https://t.me/TVmacrotel" target="_blank" title="telegram">*/}
                        {/*<i className="fab fa-telegram fa-2x"/>*/}
                        {/*</a>*/}
                        {/*<a className="instagram" href="https://www.instagram.com/test.com"*/}
                        {/*target="_blank"*/}
                        {/*title="instagram">*/}
                        {/*<i className="fab fa-instagram fa-2x"/>*/}
                        {/*</a>*/}
                        {/*<a className="pinterest" href="https://www.pinterest.com/test/" target="_blank"*/}
                        {/*title="pinterest">*/}
                        {/*<i className="fab fa-pinterest fa-2x"/>*/}
                        {/*</a>*/}
                        {/*<a className="linkedin" href="https://www.linkedin.com/in/test/"*/}
                        {/*target="_blank"*/}
                        {/*title="linkedin">*/}
                        {/*<i className="fab fa-linkedin fa-2x"/>*/}
                        {/*</a>*/}
                        {/*</div>*/}
                        {/*<div className="text-center text-md-right mr-md-3 text-white text-light">*/}
                        {/*<p>copy Right 2020 tests.com</p>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </footer>
            </Router>
        </AuthContextProvider>
    );
};