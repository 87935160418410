import React, {useContext, useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Controller, useForm} from "react-hook-form";
import {http} from "../../../env";
import {toast} from "react-toastify";
import {AuthContext} from "../../../common/context/auth";
import NumberFormat from "react-number-format";

export const VehiclePurchaseForm = (props) => {

        const {vehicle} = props;
        const {register, control, handleSubmit, reset, errors} = useForm();
        const auth = useContext(AuthContext);
        const [vehiclePurchase, setVehiclePurchase] = useState({});
        const [vehicleCost, setVehicleCost] = useState(null);

        const onChangeVehicleCost = value => setVehicleCost(value.value);

        const getVehiclePurchase = async () => {
            try {
                if (vehicle && vehicle.id) {
                    const _res = await http('POST', 'vehicle_purchase_information/get-by-vehicle-id', {id: vehicle.id});
                    if (_res.ok) {
                        _res.data.purchase_date = getDate(_res.data.purchase_date);
                        setVehiclePurchase(_res.data);
                        reset(_res.data);
                        setVehicleCost(_res.data.vehicle_cost);
                    } else console.log(_res.messages)
                }
            } catch (e) {
                console.log(e)
            }
        }

        const getDate = (date) => {
            if (date) {
                let result1 = new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }).format(new Date(date).getTime());
                let res = result1.split('/')
                return `${res[2]}-${res[1]}-${res[0]}`;
            }
        }

        const onSubmit = async (data) => {
            data.vehicle_cost = vehicleCost;
            let _res;
            if (data.id)
                _res = await http('POST', 'vehicle_purchase_information/update', data);
            else
                _res = await http('POST', 'vehicle_purchase_information/save', data);

            if (_res.ok) {
                setVehiclePurchase(_res.data);
                toast.success('Vehicle saved successfully');
            } else
                toast.error('System error occurs. Please try again.');

        };

        useEffect(() => {
            getVehiclePurchase();
        }, [vehicle])

        return (
            <div>
                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                    <div className="d-flex flex-column flex-lg-row align-items-start ">
                        <div className="card card-shadow flex-grow-1 w-100">
                            <div className="card-header-none text-right">
                                <button type="submit" className="btn btn-primary btn-raised"><i
                                    className="fas fa-save mr-2"/>Save Information
                                </button>
                            </div>
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <input type="hidden" name="id" defaultValue={vehiclePurchase.id} ref={register()}/>
                                        <input type="hidden" name="vehicle_id" defaultValue={vehicle.id} ref={register()}/>
                                        <div className="form-group">
                                            <label htmlFor="vehicle_cost">Vehicle Cost</label>
                                            <Controller
                                                render={({}) => (
                                                    <NumberFormat
                                                        placeholder="Vehicle Cost"
                                                        className="form-control"
                                                        allowNegative="false"
                                                        displayType={'input'}
                                                        value={vehicleCost}
                                                        thousandSeparator
                                                        isNumericString
                                                        onValueChange={onChangeVehicleCost}
                                                    />
                                                )}
                                                name="vehicle_cost"
                                                rules={{required: 'Vehicle Cost is required field'}}
                                                control={control}/>
                                            <span
                                                className="error">{errors.vehicle_cost && errors.vehicle_cost.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="purchase_date">Purchase Date</label>
                                            <input
                                                name="purchase_date"
                                                type="date"
                                                placeholder='Purchase Date'
                                                className='form-control'
                                                ref={register({required: 'Purchase Date is required field'})}
                                                defaultValue={vehiclePurchase.purchase_date}
                                            />
                                            <span
                                                className="error">{errors.purchase_date && errors.purchase_date.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="purchase_form">Purchase Form</label>
                                            <input
                                                name="purchase_form"
                                                type="text"
                                                placeholder='Purchase Form'
                                                className='form-control'
                                                ref={register({required: 'Purchase Form is required field'})}
                                                defaultValue={vehiclePurchase.purchase_form}
                                            />
                                            <span
                                                className="error">{errors.purchase_form && errors.purchase_form.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="purchase_invoice_number">Purchase Invoice Number</label>
                                            <input
                                                name="purchase_invoice_number"
                                                type="text"
                                                placeholder='Purchase Invoice Number'
                                                className='form-control'
                                                ref={register({required: 'Purchase Invoice Number is required field'})}
                                                defaultValue={vehiclePurchase.purchase_invoice_number}
                                            />
                                            <span
                                                className="error">{errors.purchase_invoice_number && errors.purchase_invoice_number.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="tax">Tax</label>
                                            <input
                                                name="tax"
                                                type="text"
                                                placeholder='Tax'
                                                className='form-control'
                                                ref={register()}
                                                defaultValue={vehiclePurchase.tax}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <span>Description</span>
                                            <textarea name="description" placeholder="Description"
                                                      rows="4"
                                                      ref={register()}
                                                      defaultValue={vehiclePurchase.description}
                                                      className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <img src="/images/credit-card-animate.svg" alt=""/>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </form>
            </div>

        );
    }
;

