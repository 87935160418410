import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import JoditEditor from "jodit-react";
import {API_ADMIN_URL, http} from "../../../env";
import {toast} from "react-toastify";

export const EditPage = (props) => {
    const {register, handleSubmit} = useForm();
    const [page, setPage] = useState('');
    
    const onSubmit = async (data) => {
        const _res = await http('POST', 'page/update', data);
        if (_res.ok) {
            toast.success('Update completed successfully')
        } else
            toast.error('System error occurs. Please try again.');

    };

    const getPage = async (pageName) => {
        try {
            const _res = await http('POST', 'page/getBySlug', {slug: pageName});
            if (_res.ok) {
                setPage(_res.data)
            } else console.log('message', _res.messages)
        } catch (e) {
            toast.error(e.messages);
        }
    }

    const config = {
        filebrowser: {
            createNewFolder: true,
            deleteFolder: true,
            ajax: {
                url: `${API_ADMIN_URL}post/files`,
                headers: {
                    'access_token': `Bearer ${localStorage.getItem('token')}`
                },
                method: 'POST',
                processData: true,
                dataType: 'json',
                contentType: 'application/json',

                prepareData: function (data) {
                    return JSON.stringify(data);
                },

                process: function (resp) {
                    return resp;
                }
            },
            uploader: {
                url: `${API_ADMIN_URL}post/uploads`,
                headers: {
                    'access_token': `Bearer ${localStorage.getItem('token')}`
                },
                method: 'POST',
                processData: true,
                dataType: 'json',
                prepareData: function (data) {
                    data.forEach((temp, temp1) => {
                        if (temp1.indexOf('files') !== -1) {
                            console.log(temp1)
                            data.append('file', temp);
                        }
                    })
                    data.forEach((temp, temp1) => {
                        if (temp1.indexOf('files') !== -1) {
                            data.delete(temp1);
                        }
                    })
                    if (data.has("files[1]"))
                        data.delete('files[1]');
                    return data;
                },
                process: function (resp) {
                    return {
                        files: resp.data.files || [],
                        path: resp.data.path,
                        baseurl: resp.data.baseurl,
                        error: resp.error,
                        msg: resp.msg
                    };
                },
            },
            // it defaults but still show their
        }
    };
    
    useEffect(() => {
        getPage(props.match.params.slug)
    }, [props.match.params.slug])

    return (
        <div>
            <div className="card card-shadow">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4>Page Management</h4>
                    <button type="submit" form="PageForm" className="btn btn-primary btn-raised"><i
                        className="fas fa-save mr-2 bt-1"/>Save changes
                    </button>
                </div>
                <div className="card-body" key={page.id}>
                    <form action="" id="PageForm" onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" name="id" id="id" defaultValue={page.id} ref={register}/>
                        <div className="form-group mb-3">
                            <label>Title</label>
                            <input type="text" name="title" id="" className="form-control"
                                   defaultValue={page.title}
                                   ref={register({required: "Required"})}
                                   placeholder="Page Title" required/>
                        </div>
                        <div className="form-group mb-3">
                            <label>Slug</label>
                            <input type="text" name="slug" id="" className="form-control"
                                   defaultValue={page.slug}
                                   ref={register({required: "Required"})}
                                   placeholder="Page Slug" required/>
                        </div>
                        <div className="form-group">
                            <label>Content Page</label>
                            <JoditEditor
                                config={config}
                                name="content"
                                value={page.content}
                                ref={register({required: "Required"})}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

};
