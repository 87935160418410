import React, {useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {http} from "../../../env";
import {confirmAlert} from "react-confirm-alert";
import StarRatings from "react-star-ratings";


export const PostCommentModal = (props) => {
    const {post, comments, rates} = props;
    const [postComments, setPostComments] = useState(comments);
    useEffect(() => {
        setPostComments(props.comments)
    }, [props.comments])

    return (
        <div className="modal fade" id="postCommentsModal" aria-labelledby="postCommentsModal" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <h5 className="modal-title mr-2">List of comments ({postComments.length})</h5>
                            <StarRatings
                                rating={post.total_rate}
                                starRatedColor="#FFBE0B"
                                starHoverColor="#FFBE0B"
                                starEmptyColor="rgba(0, 0, 0, .12)"
                                numberOfStars={5}
                                isSelectable={false}
                                name='rating'
                                starDimension="20px"
                                starSpacing="2px"
                            />
                            <span className="ml-2 font-weight-bold">{post.total_rate}</span>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body p-3">
                        <table
                            className="table font-size-11 table-light table-hover dataTable-collapse text-left  no-footer">
                            <thead className="bg-white">
                            <tr>
                                <th>Rate</th>
                                <th>Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rates.length > 0 && rates.map((item, key) =>
                                <tr key={key}>
                                    <td>
                                        <StarRatings
                                            rating={item.rate}
                                            starRatedColor="#FFBE0B"
                                            starHoverColor="#FFBE0B"
                                            starEmptyColor="rgba(0, 0, 0, .12)"
                                            numberOfStars={5}
                                            isSelectable={false}
                                            name='rating'
                                            starDimension="20px"
                                            starSpacing="2px"
                                        /></td>
                                    <td>     <span
                                        className="text-muted font-size-12 font-weight-bold">{item.count}<span
                                        className="ml-1"><i className="far fa-user"/></span></span></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <hr/>
                        {postComments.length > 0 ? postComments.map((item, key) =>
                            <div>
                                <div className="media mb-3 p-3" key={key}>
                                    <img className="rounded-circle-70" src="/images/profile-avatar.svg" alt=""/>
                                    <div className="media-body">
                                        <div
                                            className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                            <div className="d-flex flex-grow-1 flex-wrap">
                                                <h5 className="font-weight-bold">{item.author_name}</h5>
                                                <span className="ml-3 font-weight-bold">{item.author_email}</span>
                                                <div className="ml-1 ml-sm-2 text-muted">
                                                    <i className="fas fa-clock opacity-58 text-muted font-size-11"/>
                                                    <span> {new Intl.DateTimeFormat("en-GB", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "2-digit",
                                                        hour: "numeric",
                                                        minute: "numeric",
                                                    }).format(new Date(item.createdAt).getTime())}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="white-space-pre-line line-height-normal mb-1 text-justify">
                                            {item.content}
                                        </p>
                                    </div>
                                </div>
                                {item.Replies.length > 0 ?
                                    <div className="ml-5 mt-3 mb-5">
                                        <h5 className="mb-3 text-secondary">Replies</h5>
                                        {item.Replies.map((reply, replyKey) =>
                                            <div className="media mb-5" key={reply.id}>
                                                <img className="rounded-circle-70"
                                                     src="/images/profile-avatar.svg"
                                                     alt=""/>
                                                <div className="media-body">
                                                    <div
                                                        className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                        <div className="d-flex flex-grow-1 flex-wrap">
                                                            <h5 className="font-weight-bold">{reply.author_name}</h5>
                                                            <div className="ml-1 ml-sm-2 text-muted">
                                                                <i className="fas fa-clock opacity-58 text-muted font-size-11"/>
                                                                <span> {new Intl.DateTimeFormat("en-GB", {
                                                                    year: "numeric",
                                                                    month: "long",
                                                                    day: "2-digit",
                                                                    hour: "numeric",
                                                                    minute: "numeric",
                                                                }).format(new Date(reply.createdAt).getTime())}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="white-space-pre-line line-height-normal mb-1 text-justify">
                                                        {reply.content}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    : ''}
                            </div>
                        ) : <div>this post doesn't have comments</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};


