import React from 'react'
import {Route} from 'react-router-dom';
import {Home} from "../../web/home/home";
import {Logout} from "../logout/login";
import {Signup} from "../../web/signup/signup";
import {Signin} from "../../web/signin/signin";
import {PostPage} from "../../web/blog/post-page";
import {Page} from "../../web/page/page";
import {ContactUs} from "../../web/lead-request/contact_us";
import {ViewBlog} from "../../web/blog/blog";
import {VehiclesSearch} from "../../web/vehicle/vehicle-search";
import {VehiclePage} from "../../web/vehicle/vehicle-page";
import {CarFinder} from "../../web/lead-request/car_finder";
import {AppointmentRequest} from "../../web/lead-request/appointment_request";
import {SignInUp} from "../../web/sign/sign-in-up";

export const RoutesCommon = () => {
    return (
        <>
            <Route exact path="/" component={Home}/>
            <Route exact path={'/blog'} component={Home}/>
            <Route exact path={'/signup'} component={Signup}/>
            <Route exact path={'/signin'} component={Signin}/>
            <Route exact path={'/logout'} component={Logout}/>
            <Route exact path={'/blog/:post_type'} component={ViewBlog}/>
            <Route exact path={'/blog/:category/:slug'} component={PostPage}/>
            <Route exact path={'/page/:slug'} component={Page}/>
            <Route exact path={'/vehicles'} component={VehiclesSearch}/>
            <Route exact path={'/vehicle/:slug'} component={VehiclePage}/>
            <Route exact path={'/contact-us'} component={ContactUs}/>
            <Route exact path={'/car-finder-request'} component={CarFinder}/>
            <Route exact path={'/appointment-request'} component={AppointmentRequest}/>
            <Route exact path={'/sign'} component={SignInUp}/>
        </>
    )
};