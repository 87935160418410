import {useForm, Controller} from "react-hook-form";
import {http, VEHICLE_OPTION, SEARCH_PERIOD_LIST, CONDITION_LIST} from '../../../env'
import {toast} from 'react-toastify'
import React, {useEffect, useState, useContext} from "react";
import {AuthContext} from '../../../common/context/auth'
import ReactSelect from "react-select";
import NumberFormat from 'react-number-format';
import {SignInUp} from "../sign/sign-in-up";

export const CarFinder = ({history}) => {
    const {isLoggedIn, userInfo, isLoading} = useContext(AuthContext);
    console.log(isLoggedIn)
    return (
        <>
            {
                isLoggedIn ? <FinderForm user={userInfo}/> : <SignInUp/>
            }
        </>
    )
}

const FinderForm = ({user}) => {
    const FAKE_INFO = ['test', 'test2', 'test@gmail.com', '09876543321'];
    const {register, handleSubmit, control, errors} = useForm();
    const [price, setPrice] = useState(null);
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [allModels, setAllModels] = useState([]);
    const [maker, setMaker] = useState(null);
    const [model, setModel] = useState(null);
    const [year, setYear] = useState(null);
    const [years, setYears] = useState([]);
    const [bodyStyles, setBodyStyles] = useState([]);
    const [trims, setTrims] = useState([]);
    const [colors, setColors] = useState([]);
    const [transmissionTypes, setTransmissionTypes] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [condition, setCondition] = useState({});
    const [conditions] = useState(CONDITION_LIST);
    const [searchPeriod, setSearchPeriod] = useState({});
    const [searchPeriods] = useState(SEARCH_PERIOD_LIST);
    const [trim, setTrim] = useState(null);
    const [color, setColor] = useState(null);
    const [transmissionType, setTransmissionType] = useState(null);
    const [fuelType, setFuelType] = useState(null);
    const [bodyStyle, setBodyStyle] = useState(null);

    const handleChangeCondition = selectedOption => {
        setCondition(selectedOption);
    };
    const handleChangeSearchPeriod = selectedOption => {
        setSearchPeriod(selectedOption);
    };
    const handleSetMaker = selectedOption => {
        setMaker(selectedOption);
        setModel(null);
        setModels(allModels.filter(key => key.option_type === VEHICLE_OPTION.MODEL && key.parent_id === selectedOption.value));
    };

    const handleSetModel = selectedOption => {
        setModel(selectedOption);
    };
    const handleSetTrim = selectedOption => {
        setTrim(selectedOption);
    };
    const handleSetColor = selectedOption => {
        setColor(selectedOption);
    };
    const handleSetYear = selectedOption => {
        setYear(selectedOption);
    };
    const handleSetBodyStyles = selectedOption => {
        setBodyStyle(selectedOption);
    };
    const handleSetTransmissionType = selectedOption => {
        setTransmissionType(selectedOption);
    };
    const handleSetFuelType = selectedOption => {
        setFuelType(selectedOption);
    };
    const onChangePrice = value => setPrice(value.value);

    const makeYears = () => {
        let currentYear = new Date().getFullYear();
        let rows = [];
        for (let i = currentYear; i >= currentYear - 50; i--) {
            rows.push({value: i, label: i});
        }
        setYears(rows);
    }
    const getVehicleOptionData = async () => {
        try {
            const _res = await http('POST', 'vehicle_option_data/all');
            if (_res.ok) {
                const vehicleOptions = _res.data;
                setBodyStyles(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.BODY_STYLE));
                setMakers(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.MAKER));
                setAllModels(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.MODEL));
                setColors(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.COLOR));
                setTrims(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.TRIM));
                setTransmissionTypes(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.TRANSMISSION_TYPE));
                setFuelTypes(vehicleOptions.filter(key => key.option_type === VEHICLE_OPTION.FUEL_TYPE));
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getVehicleOptionData();
        makeYears();
    }, [])
    const onSubmit = async data => {
        data.maker = maker.value;
        data.model = model.value;
        data.year = year.value;
        data.trim = trim.value;
        data.color = color.value;
        data.transmission = transmissionType.value;
        data.fuel_type = fuelType.value;
        data.body_style = bodyStyle.value;
        data.price = price;
        data.searchPeriod = searchPeriod.value;

        const _res = await http('POST', 'car-finder/save', data)
        console.log(_res)
        if (_res.ok) {
            toast.success('car finded ');
        } else
            toast.error(_res.message);
    }
    return (
        <>
            <div className="container mt-5" id='login-component'>
                <div className="row">
                    <div className="col-12 col-md-7">
                        <div className="card card-shadow shadow-sm">
                            <div className="card-header"><h4 className="text-primary">Car Finder</h4></div>
                            <div className="card-body p-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <fieldset>
                                        <legend>Personal Information:</legend>
                                        <div className="form-group">
                                            <label>First name: <span className="error">*</span></label>
                                            <input type="text" name="first_name" autoFocus id="first_name"
                                                   className="form-control"
                                                   placeholder="Last name"
                                                   ref={register({required: 'First name is required.'})}/>
                                            <span
                                                className="error">{errors.first_name && errors.first_name.message}</span>
                                        </div>
                                        <div className="form-group ">
                                            <label>Last name: <span className="error">*</span></label>
                                            <input type="text" name="last_name" id="last_name" className="form-control"
                                                   placeholder="Last name"
                                                   ref={register({required: 'Last name is required.'})}/>
                                            <span
                                                className="error">{errors.last_name && errors.last_name.message}</span>
                                        </div>
                                        <div className="form-group ">
                                            <label>email: </label>
                                            <input type="email" name="email" id="email" className="form-control"
                                                   placeholder="Email"
                                                   ref={register({required: 'Email is required.'})}/>
                                            <span className="error">{errors.email && errors.email.message}</span>
                                        </div>
                                        <div className="form-group ">
                                            <label>phone: </label>
                                            <input type="tel" name="phone" id="phone" className="form-control"
                                                   placeholder="phone"
                                                   ref={register({required: 'phone is required.'})}/>
                                            <span className="error">{errors.phone && errors.phone.message}</span>
                                        </div>
                                    </fieldset>
                                    <fieldset className="mt-5">
                                        <legend>Vehicle Information</legend>
                                        <div className="form-group">
                                            <label htmlFor="maker">Make</label>
                                            <Controller
                                                name="maker"
                                                placeholder="Make"
                                                as={ReactSelect}
                                                defaultValue={maker}
                                                options={makers}
                                                rules={{required: 'Make is required field'}}
                                                onChange={handleSetMaker}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span className="error">{errors.maker && errors.maker.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="model">Model</label>
                                            <Controller
                                                name="model"
                                                placeholder="Model"
                                                as={ReactSelect}
                                                defaultValue={model}
                                                options={models}
                                                rules={{required: 'Model is required field'}}
                                                onChange={handleSetModel}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span className="error">{errors.model && errors.model.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="year">Year</label>
                                            <Controller
                                                name="year"
                                                placeholder="Year"
                                                as={ReactSelect}
                                                defaultValue={year}
                                                options={years}
                                                rules={{required: 'Year is required field'}}
                                                onChange={handleSetYear}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span className="error">{errors.year && errors.year.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="body_style">Body Style</label>
                                            <Controller
                                                as={ReactSelect}
                                                name="bodyStyle"
                                                onChange={handleSetBodyStyles}
                                                options={bodyStyles}
                                                placeholder='Body Style'
                                                defaultValue={bodyStyle}
                                                rules={{required: 'Body Style is required field'}}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span
                                                className="error">{errors.body_style && errors.body_style.message}</span>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="color">Color</label>
                                            <Controller
                                                as={ReactSelect}
                                                name="color"
                                                onChange={handleSetColor}
                                                options={colors}
                                                placeholder='Color'
                                                defaultValue={color}
                                                rules={{required: 'Color is required field'}}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span className="error">{errors.color && errors.color.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="fuel_type">Fuel Type</label>
                                            <Controller
                                                as={ReactSelect}
                                                name="fuelType"
                                                onChange={handleSetFuelType}
                                                options={fuelTypes}
                                                placeholder='Fuel Type'
                                                defaultValue={fuelType}
                                                rules={{required: 'Fuel Type is required field'}}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span
                                                className="error">{errors.fuel_type && errors.fuel_type.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="transmission">Transmission</label>
                                            <Controller
                                                as={ReactSelect}
                                                name="transmissionType"
                                                onChange={handleSetTransmissionType}
                                                options={transmissionTypes}
                                                placeholder='Transmission'
                                                defaultValue={transmissionType}
                                                rules={{required: 'Transmission is required field'}}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span
                                                className="error">{errors.transmission && errors.transmission.message}</span>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="trim">Trim</label>
                                            <Controller
                                                as={ReactSelect}
                                                name="trim"
                                                onChange={handleSetTrim}
                                                options={trims}
                                                placeholder='Trim'
                                                defaultValue={trim}
                                                rules={{required: 'Trim is required field'}}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="price">Price</label>
                                            <NumberFormat
                                                value={price}
                                                name="price"
                                                placeholder="Price"
                                                isNumericString={true}
                                                className="form-control"
                                                allowNegative="false"
                                                displayType={'input'}
                                                thousandSeparator={true}
                                                onValueChange={onChangePrice}
                                                getInputRef={register()}/>
                                        </div>
                                        <div className="form-group mb-4">
                                            <label>kilometers: </label>
                                            <input className="form-control" type="text" name="kilometers"
                                                   ref={register({required: 'vin is required'})}/>
                                            <span
                                                className="error">{errors.kilometers && errors.kilometers.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="searchPeriod">Search Period</label>
                                            <Controller
                                                as={ReactSelect}
                                                name="search_period"
                                                onChange={handleChangeSearchPeriod}
                                                options={searchPeriods}
                                                placeholder='Search Period'
                                                defaultValue={searchPeriod}
                                                rules={{required: 'Search Period is required field'}}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span
                                                className="error">{errors.search_period && errors.search_period.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="searchPeriod">Condition</label>
                                            <Controller
                                                as={ReactSelect}
                                                name="condition"
                                                onChange={handleChangeCondition}
                                                options={conditions}
                                                placeholder='Condition'
                                                defaultValue={condition}
                                                rules={{required: 'Condition is required field'}}
                                                isSearchable
                                                control={control}>
                                            </Controller>
                                            <span
                                                className="error">{errors.condition && errors.condition.message}</span>
                                        </div>
                                        <div className="form-group">
                                            <span>Additional Information</span>
                                            <textarea name="description" placeholder="Description"
                                                      rows="8"
                                                      ref={register()}
                                                      className="form-control"/>
                                            <span
                                                className="error">{errors.description && errors.description.message}</span>
                                        </div>
                                    </fieldset>

                                    <button id="btnFinder" type="submit"
                                            className="btn btn-primary btn-lg btn-raised btn-block">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 d-md-inline d-none text-center pt-4">
                        <img className="w-75" src="/images/vehicle-sale-animate.svg" alt=""/>
                        <h1 className="font-size-16 font-weight-bold text-primary"> Car Finder</h1>
                        <p> VFC AUTO GROUP offers a free, no obligation and confidential CarFinder tool,We Have Access
                            to The Largest Inventory Of Luxury Vehicles ,Our CARFINDER tool allows you to specify your
                            desired Luxury or sports Car or SUV and submit the vehicle configuration to us. Once we
                            locate matching automobiles, you automatically receive details of those models, with
                            photographs included. Let us handle the task of finding your next dream car!</p>
                    </div>
                </div>
            </div>

        </>
    )
}

