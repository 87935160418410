import React, {useEffect, useReducer} from 'react'
import './input.scss'
import {validate} from '../../helper/validators'

const inputReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE' :
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators)
            }
        case 'BLUR' :
            return {
                ...state,
                isBlured: true
            }
        default:
            return state
    }
}

export const Input = props => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: '',
        isValid: false,
        isBlured: false
    })
    const {id, onInput, defaultVal} = props;
    const {value, isValid} = inputState
    useEffect(() => {
        onInput(id, value, isValid)
    }, [id, value, isValid, onInput])
    const changeHandler = evt => {
        dispatch({
            type: 'CHANGE',
            val: evt.target.value,
            validators: props.validators
        })
    }
    const blurHandler = evt => {
        dispatch({
            type: 'BLUR',
            val: evt.target.value,
            validators: props.validators
        })
    }
    const element =
        props.element === 'input' ?
            (
                <input
                    id={props.id}
                    type={props.type}
                    placeholder={props.placeholder}
                    className={props.className}
                    onChange={changeHandler}
                    onBlur={blurHandler}
                />
            ) : (
                <textarea
                    name={props.name}
                    id={props.id}
                    cols={props.cols}
                    rows={props.rows || 3}
                    className={props.className}
                    onChange={changeHandler}
                    value={inputState.value}
                    onBlur={blurHandler}
                />
            )
    return (<>
        {element}
        {!inputState.isValid && inputState.isBlured &&
        <div id={props.id} className="invalid-feedback">
            {props.errorText}
        </div>
        }
    </>)
}
