import React from 'react';
import {ToastContainer ,  Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Toast = () => {
    return (
        <>
            <ToastContainer
                position="top-center"
                transition={Bounce}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                pauseOnFocusLoss
                draggable
                rtl={false}
                pauseOnHover
            />
        </>
    )
};
