import React, {useEffect, useState} from 'react';
import {http, PAGE_SIZE_ROWS, VEHICLE_STATUS_LIST, VEHICLE_OPTIONS_ARRAY, VEHICLE_OPTION} from "../../../env";
import {Link} from 'react-router-dom';
import {isObject} from "formik";
import {ReactBootstrapSlider} from "react-bootstrap-slider";
import "bootstrap-slider/dist/css/bootstrap-slider.css"

export const VehiclesSearch = (props) => {
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [filters, setFilters] = useState([]);
    const [sort, setSort] = useState('updatedAt');
    const [sortType, setSortType] = useState('DESC');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [perPageRows, setPerPageRows] = useState(PAGE_SIZE_ROWS);
    const [doSearch, setDoSearch] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [makers, setMakers] = useState([]);
    const [models, setModels] = useState([]);
    const [searchFilers, setSearchFilers] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const priceSliderConfigDefault = {
        min: 1000,
        max: 10000000,
        step: 1000,
    };
    const [priceSliderConfig, setPriceSliderConfig] = useState([priceSliderConfigDefault.min, priceSliderConfigDefault.max]);
    const yearSliderConfigDefault = {
        min: new Date().getFullYear() - 50,
        max: new Date().getFullYear(),
        step: 1,
    };
    const [yearSliderConfig, setYearSliderConfig] = useState([yearSliderConfigDefault.min, yearSliderConfigDefault.max]);

    const sortOptions = [
        {value: 'updatedAt', label: 'New created'},
        {value: 'price', label: 'Price'},
        {value: 'mileage', label: 'Mileage'},
        {value: 'maker', label: 'Make'},
        {value: 'year', label: 'Year'},
    ];

    const perPageOptions = [
        {value: 10, label: '10'},
        {value: 20, label: '20'},
        {value: 30, label: '30'},
        {value: 40, label: '40'},
        {value: 50, label: '50'},
    ];

    const priceChangeValue = (target) => {
        if (priceSliderConfigDefault.min === target.target.value[0]) {
            removeQuery('priceFrom');
        } else if (priceSliderConfig[0] !== target.target.value[0]) {
            addQuery([{key: 'priceFrom', value: target.target.value[0]}]);
        }
        if (priceSliderConfigDefault.max === target.target.value[1]) {
            removeQuery('priceTo');
        } else if (priceSliderConfig[1] !== target.target.value[1]) {
            addQuery([{key: 'priceTo', value: target.target.value[1]}]);
        }
        setPriceSliderConfig(target.target.value);
    };

    const yearChangeValue = (target) => {
        if (yearSliderConfigDefault.min === target.target.value[0]) {
            removeQuery('yearFrom');
        } else if (yearSliderConfig[0] !== target.target.value[0]) {
            addQuery([{key: 'yearFrom', value: target.target.value[0]}]);
        }
        if (yearSliderConfigDefault.max === target.target.value[1]) {
            removeQuery('yearTo');
        } else if (yearSliderConfig[1] !== target.target.value[1]) {
            addQuery([{key: 'yearTo', value: target.target.value[1]}]);
        }
        setYearSliderConfig(target.target.value);
    };

    const searchBoxOnInput = (value) => {
        if (value.target.value)
            addQuery([{key: 'keyword', value: value.target.value}]);
        else
            removeQuery('keyword');
    }

    const filtersOnChange = (value, item, parent) => {
        if (value.target.checked) {
            if (isObject(parent))
                // if (parent.field === 'model') {
                //     addQuery([{
                //         key: `${parent.field}[${item.value}]`,
                //         value: item.label
                //     }, {
                //         key: `maker[${item.parent_id}]`,
                //         value: searchFilers.filter(key => key.value === item.parent_id)[0].label
                //     }]);
                // } else
                addQuery([{key: `${parent.field}[${item.value}]`, value: item.label}]);
            else {
                if (parent === 'status')
                    addQuery({key: `status[${item.value}]`, value: item.label});
            }
        } else {
            if (isObject(parent))
                removeQuery(`${parent.field}[${item.value}]`);
            else {
                if (parent === 'status')
                    removeQuery(`status[${item.value}]`);
            }
        }
    }

    const getVehicles = async () => {
        try {
            setLoading(true);
            const _res = await http('POST', 'vehicle/search', {
                pageSize: perPageRows,
                page: currentPage,
                sorted: sort,
                sortType: sortType,
                filtered: filters
            });
            if (_res.ok) {
                setVehicles(_res.data.vehicles);
                setLoading(false);
                setTotalPages(_res.data.totalPages);
                setTotalRecords(_res.data.totalRecords);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }
    }

    const getSearchFilters = async () => {
        try {
            const _res = await http('POST', 'vehicle_option_data/all-search');
            if (_res.ok) {
                setSearchFilers(_res.data);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }
    }

    const checkIsInFilter = (child, parent) => {
        let searchParams = new URLSearchParams(props.location.search);
        for (var value of searchParams.keys()) {
            if (isObject(parent)) {
                if (value === `${parent.field}[${child.value}]`)
                    return true;
            } else {
                if (parent === 'status')
                    if (value === `status[${child}]`)
                        return true;
            }
        }
        return false;
    }

    const resetFiltersOnClick = () => {
        let pathname = props.location.pathname;
        props.history.push({
            pathname: pathname,
            search: ''
        });
    }

    const addQuery = (items) => {
        let pathname = props.location.pathname;
        // returns path: '/app/books'
        let searchParams = new URLSearchParams(props.location.search);
        // returns the existing query string: '?type=fiction&author=fahid'
        if (items.length > 0) {
            items.map(item => {
                searchParams.set(item.key, item.value);
                if (item.key.replace(/\[\d+\]/g, '') === 'maker') {
                    searchFilers.filter(key2 => key2.parent_id !== parseInt(item.key.match(/(\d+)/)[0])).map(item2 => {
                        searchParams.delete(`model[${item2.value}]`);
                    })
                }
            });
            searchParams.sort();
            props.history.push({
                pathname: pathname,
                search: decodeURIComponent(searchParams.toString())
            });
        }
    };

    const removeQuery = (key) => {
        let pathname = props.location.pathname;
        // returns path: '/app/books'
        let searchParams = new URLSearchParams(props.location.search);
        // returns the existing query string: '?type=fiction&author=fahid'
        if (searchParams.has(key)) {
            searchParams.delete(key);
            searchParams.sort();
            props.history.push({
                pathname: pathname,
                search: decodeURIComponent(searchParams.toString())
            });
        }
    };

    const handleQueryStringSearchParams = () => {
        if (searchFilers.length > 0) {
            let searchParams = new URLSearchParams(props.location.search);
            let tempFilters = [];
            let price = [priceSliderConfigDefault.min, priceSliderConfigDefault.max];
            let year = [yearSliderConfigDefault.min, yearSliderConfigDefault.max];
            let filterItemsTemp = [];
            let tempMakers = [];
            let tempModels = [];
            setMakers([]);
            setModels([]);
            setSort('updatedAt');
            setSortType('DESC');
            setCurrentPage(0);
            setPerPageRows(PAGE_SIZE_ROWS);
            setKeyword('');
            searchParams.forEach((value, key) => {
                switch (key) {
                    case 'perPage':
                        setPerPageRows(parseInt(value));
                        break;
                    case 'keyword':
                        tempFilters.push({id: 'keyword', value: value});
                        filterItemsTemp.push({key: key, value: `Search:${value}`});
                        setKeyword(value);
                        break;
                    case 'sort':
                        setSort(value);
                        break;
                    case 'sortType':
                        setSortType(value);
                        break;
                    case 'page':
                        setCurrentPage(parseInt(value));
                        break;
                    case 'priceFrom':
                        price[0] = parseInt(value);
                        tempFilters.push({id: key, value: parseInt(value)});
                        filterItemsTemp.push({key: key, value: `From Price:$${value}`});
                        break;
                    case 'priceTo':
                        price[1] = parseInt(value);
                        tempFilters.push({id: key, value: parseInt(value)});
                        filterItemsTemp.push({key: key, value: `To Price:$${value}`});
                        break;
                    case 'yearFrom':
                        year[0] = parseInt(value);
                        tempFilters.push({id: key, value: parseInt(value)});
                        filterItemsTemp.push({key: key, value: `From Year:${value}`});
                        break;
                    case 'yearTo':
                        year[1] = parseInt(value);
                        tempFilters.push({id: key, value: parseInt(value)});
                        filterItemsTemp.push({key: key, value: `To Year:${value}`});
                        break;
                    default:
                        // filterItemsTemp.push(`${key.replace(/\[\d+\]/g, '')}:${value}`);
                        filterItemsTemp.push({key: key, value: `${value}`});
                        tempFilters.push({id: key.replace(/\[\d+\]/g, ''), value: key.match(/(\d+)/)[0]});
                        if (key.replace(/\[\d+\]/g, '') === 'maker')
                            tempMakers.push(parseInt(key.match(/(\d+)/)[0]));
                        if (key.replace(/\[\d+\]/g, '') === 'model')
                            tempModels.push(searchFilers.filter(key2 => key2.value === parseInt(key.match(/(\d+)/)[0]))[0].parent_id);
                        break;
                }
            });
            setFilters(tempFilters);
            setFilterItems(filterItemsTemp);
            setPriceSliderConfig(price);
            setYearSliderConfig(year);
            setMakers(tempMakers);
            setModels(tempModels);
            setDoSearch(true);
        }
    }

    const perPageOnChange = (selected) => {
        setPerPageRows(parseInt(selected.target.value));
        addQuery([{key: 'perPage', value: parseInt(selected.target.value)}]);
    };

    const sortOnChange = (selected) => {
        setSort(selected.target.value);
        addQuery([{key: 'sort', value: selected.target.value}]);
    }

    const sortTypeOnChange = (selected) => {
        setSortType(selected.target.value);
        addQuery([{key: 'sortType', value: selected.target.value}]);
    }

    const makePagination = () => {
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        return pages;
    }

    useEffect(() => {
        if (searchFilers.length === 0)
            getSearchFilters();
        handleQueryStringSearchParams();
    }, [searchFilers, props.location.search]);

    useEffect(() => {
        if (doSearch) {
            getVehicles();
            setDoSearch(false);
        }
    }, [doSearch]);

    return (
        <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-between my-3 px-4">
                <h1 className="pr-3 font-size-16 text-secondary">Search Result </h1>
                <span className="text-muted font-size-13 font-weight-500"><b
                    className="badge badge-dark opacity-78 badge-pill">{totalRecords}</b>  Vehicles found</span>
            </div>
            <div className="card card-shadow">
                <div className="card-body p-2">

                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                            <span className="mr-2 flex-grow-1 col-auto">Sort By: </span>
                            <select name="sort" value={sort} onChange={sortOnChange} className="custom-select">
                                {sortOptions.map(item => <option key={item.value}
                                                                 value={item.value}>{item.label}</option>)}
                            </select>
                            <select name="sort-type" onChange={sortTypeOnChange} className="custom-select ml-3">
                                <option value="DESC">Descending</option>
                                <option value="ASC">Ascending</option>
                            </select>
                        </div>

                        <div className="d-flex flex-row align-items-center">
                            <span className="col-auto">Per Page:</span>
                            <select name="per-page" value={perPageRows} onChange={perPageOnChange}
                                    className="custom-select">
                                {perPageOptions.map(item => <option key={item.value}
                                                                    value={item.value}>{item.label}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex">
                {filterItems.length > 0 ?
                    <div className="row mt-3">
                        <div className="col-12">
                            <span className="font-weight-bold mr-1">Filters</span>
                            {filterItems.map((item, key) =>
                                <div key={key} className="badge badge-white-border mr-3 mb-2">{item.value}
                                    <i onClick={value => removeQuery(item.key)} className="fas fa-times ml-2 pointer opacity-88"/>
                                </div>
                            )}
                            <button type="button" onClick={resetFiltersOnClick}
                                    className="btn btn-outline-info btn-sm btn-raised badge-pill">
                                <i className="fas fa-times"/> Clear All
                            </button>
                        </div>
                    </div>
                    : ''}
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-3 col-xl-2 pr-md-0">
                    <div className="card card-shadow border-0">
                        <div className="card-body p-3">
                            <form action="">
                                <div className="input-group mb-2">
                                    <input type="text" className="form-control" onChange={searchBoxOnInput}
                                           value={keyword}
                                           id="businessSearch" autoFocus
                                           placeholder="Search"/>
                                    <div className="input-group-append">
                                        <button type="button" id="businessSearchBtn" className="btn btn-primary"><i
                                            className="fa fa-search ml-1"/>
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <div className="pt-4">
                                {VEHICLE_STATUS_LIST.map(item =>
                                    <div className="form-group" key={item.value}>
                                        <label className="switch switch-primary">
                                            <span className="font-weight-bold text-secondary">{item.label}</span>
                                            <input type="checkbox"
                                                   checked={checkIsInFilter(item.value, 'status')}
                                                   onChange={value => filtersOnChange(value, item, 'status')}
                                                   value={item.value}/>
                                            <span className="slider"/>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card card-shadow border-0 mt-3">
                        <div className="card-header border-bottom-0">
                            <a data-toggle="collapse" data-target="#yearContainer" aria-expanded="true"
                               className="collapse-header">
                                <span className="d-block font-weight-bold text-secondary">Year</span>
                            </a>
                        </div>
                        <div className="collapse show" id="yearContainer">
                            <div className="card-body">
                                <ReactBootstrapSlider
                                    value={yearSliderConfig}
                                    slideStop={yearChangeValue}
                                    step={yearSliderConfigDefault.step}
                                    max={yearSliderConfigDefault.max}
                                    min={yearSliderConfigDefault.min}
                                    orientation="horizontal"
                                    reversed={false}/>
                                <div className="d-flex justify-content-between">
                                    <span>{yearSliderConfig[0]}</span>
                                    <span>{yearSliderConfig[1]}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-shadow border-0 mt-3">
                        <div className="card-header border-bottom-0 ">
                            <a data-toggle="collapse" data-target="#nationalityContainer" aria-expanded="true"
                               className="collapse-header">
                                <span className="d-block font-weight-bold text-secondary">Price</span>
                            </a>
                        </div>
                        <div className="collapse show" id="nationalityContainer">
                            <div className="card-body">
                                <div className="form-check">
                                    <ReactBootstrapSlider
                                        value={priceSliderConfig}
                                        slideStop={priceChangeValue}
                                        step={priceSliderConfigDefault.step}
                                        max={priceSliderConfigDefault.max}
                                        min={priceSliderConfigDefault.min}
                                        orientation="horizontal"
                                        reversed={false}/>
                                    <div className="d-flex justify-content-between">
                                        <span>{priceSliderConfig[0]}</span>
                                        <span>{priceSliderConfig[1]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-shadow border-0 mt-3">
                        <div className="card-header border-bottom-0">
                            <a data-toggle="collapse"
                               data-target={`#makerContainer`}
                               aria-expanded="true"
                               className="collapse-header">
                                <span className="d-block font-weight-bold text-secondary">Make</span>
                            </a>
                        </div>
                        <div className="collapse show" id={`makerContainer`}>
                            <div className="card-body">
                                {searchFilers && searchFilers.length > 0 && searchFilers.filter(key => (key.option_type === VEHICLE_OPTION.MAKER && models.length === 0) || (key.option_type === VEHICLE_OPTION.MAKER && models.length > 0 && models.indexOf(key.value) !== -1)).map(children =>
                                    <div className="form-check" key={children.value}>
                                        <label className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input"
                                                   checked={checkIsInFilter(children, {
                                                       label: 'Make',
                                                       field: 'maker',
                                                       value: 1
                                                   })}
                                                   onChange={value => filtersOnChange(value, children, {
                                                       label: 'Make',
                                                       field: 'maker',
                                                       value: 1
                                                   })}
                                                   value={children.value}/>
                                            <span className="custom-control-indicator"/>
                                            <span className="custom-control-description">{children.label}</span>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card card-shadow border-0 mt-3">
                        <div className="card-header border-bottom-0">
                            <a data-toggle="collapse"
                               data-target={`#modelContainer`}
                               aria-expanded="true"
                               className="collapse-header">
                                <span className="d-block font-weight-bold text-secondary">Model</span>
                            </a>
                        </div>
                        <div className="collapse show" id={`modelContainer`}>
                            <div className="card-body">
                                {searchFilers && searchFilers.length > 0 && searchFilers.filter(key => (key.option_type === VEHICLE_OPTION.MODEL && makers.length === 0) || (key.option_type === VEHICLE_OPTION.MODEL && makers.length > 0 && makers.indexOf(key.parent_id) !== -1)).map(children =>
                                    <div className="form-check" key={children.value}>
                                        <label className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input"
                                                   checked={checkIsInFilter(children, {
                                                       label: 'Model',
                                                       field: 'model',
                                                       value: 2
                                                   })}
                                                   onChange={value => filtersOnChange(value, children, {
                                                       label: 'Model',
                                                       field: 'model',
                                                       value: 2
                                                   })}
                                                   value={children.value}/>
                                            <span className="custom-control-indicator"/>
                                            <span className="custom-control-description">{children.label}</span>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {VEHICLE_OPTIONS_ARRAY.map(item =>
                        <div className="card card-shadow border-0 mt-3" key={item.value}>
                            <div className="card-header border-bottom-0">
                                <a data-toggle="collapse"
                                   data-target={`#${item.field}Container`}
                                   aria-expanded="true"
                                   className="collapse-header">
                                    <span className="d-block font-weight-bold text-secondary">{item.label}</span>
                                </a>
                            </div>
                            <div className="collapse show" id={`${item.field}Container`}>
                                <div className="card-body">
                                    {searchFilers && searchFilers.length > 0 && searchFilers.filter(key => key.option_type === item.value).map(children =>
                                        <div className="form-check" key={children.value}>
                                            <label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"
                                                       checked={checkIsInFilter(children, item)}
                                                       onChange={value => filtersOnChange(value, children, item)}
                                                       value={children.value}/>
                                                <span className="custom-control-indicator"/>
                                                <span className="custom-control-description">{children.label}</span>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-12 col-md-9 col-xl-10">
                    <div className="row">
                        {vehicles && vehicles.length > 0 && vehicles.map((item, key) => {
                            if (searchFilers && searchFilers.length > 0) {
                                var maker = searchFilers.filter(key => key.value === item.maker);
                                var model = searchFilers.filter(key => key.value === item.model);
                                if (maker.length > 0)
                                    maker = maker[0];
                                else
                                    maker = {};
                                if (model.length > 0)
                                    model = model[0];
                                else
                                    model = {};
                                return [<div className="col-12 col-md-3 h-auto mt-3 mt-md-0" key={item.id}>
                                    <div className="card card-shadow mb-3">
                                        <img src={'/uploads/' + item.vehicle_images[0].image}
                                             className="card-image-top" alt={model.label}
                                             title={model.label}/>
                                        <div className="card-body">
                                            <h5 className="font-weight-bold blog-title">
                                                <Link className="card-title"
                                                      to={`/vehicle/${item.id}`}>{maker.label + ' ' + model.label + ' ' + item.year}</Link>
                                            </h5>
                                        </div>
                                        <div className="card-footer d-flex justify-content-between border-top-0">
                                        <span><i
                                            className="fas fa-tachometer-alt text-secondary opacity-88 mr-1"/>{item.odometer} miles</span>
                                            <b className="font-size-13 font-weight-bold text-success">${item.price}</b>

                                        </div>
                                    </div>
                                </div>]
                            }
                        })}
                    </div>
                    <div className="d-flex pt-3 mt-5 justify-content-center align-items-center">
                        {makePagination().map(item =>
                            <button key={item} type="button" name={`page_${item}`}
                                    disabled={item === (currentPage + 1)}
                                    onClick={value => addQuery([{key: 'page', value: item}])}
                                    className={item === (currentPage + 1) ? "btn btn-primary" : "btn btn-primary"}>{item}</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
