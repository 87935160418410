import React, {useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import {http, PAGE_SIZE_ROWS} from "../../../env";
import {PostCommentModal} from "./post_comment_modal";
import {Link} from "react-router-dom";
import {PostEditingHistoriesModal} from "./post_editing_histories_modal";
import ReactTable from "react-table-v6";

export const PostManagement = props => {
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState([]);
    const [comments, setComments] = useState([]);
    const [rates, setRates] = useState([]);
    const [histories, setHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [tableState, setTableState] = useState(null);

    const getPosts = async (state, instance) => {
        try {
            setLoading(true);
            const _res = await http('POST', 'post/all', {
                pageSize: state.pageSize,
                page: state.page,
                sorted: state.sorted,
                filtered: state.filtered
            });
            if (_res.ok) {
                setTableState(state);
                setLoading(false);
                setTotalPages(_res.data.totalPages);
                setPosts(_res.data.posts);
            } else console.log(_res.messages);
        } catch (e) {
            console.log(e.messages)
        }
    };

    const showComments = async (item) => {
        try {
            setPost(item)
            if (item && item.id !== '') {
                const _res = await http('POST', 'post_comment/get-by-post-id', item);
                if (_res.ok) setComments(_res.data);
                const _rates = await http('POST', 'post_rate/get-rate-groups-by-post-id', item)
                if (_rates.ok) setRates(_rates.data);
                else console.log(_res.messages);
            }
        } catch (e) {
            console.log(e.messages)
        }
    }

    const showPostEditingHistories = async (item) => {
        try {
            setPost(item)
            console.log(item);
            if (item && item.id !== '') {
                const _res = await http('POST', 'post_editing_histories/get-by-post-id', item);
                if (_res.ok) setHistories(_res.data);
                else console.log(_res.messages);
            }
        } catch (e) {
            console.log(e.messages)
        }
    }

    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1 className="text-primary">Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-white-border btn-raised mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success btn-raised" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'post/delete', item);
            if (_res.ok) getPosts(tableState, null);
            else console.log(_res.messages);
        }
    };
    return (
        <div id='create-post-component'>
            {<PostCommentModal post={post} comments={comments} rates={rates}/>}
            {<PostEditingHistoriesModal post={post} histories={histories}/>}
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card border-0 card-shadow rounded-0">
                        <div className="card-header">
                            <h4>List of posts</h4>
                        </div>
                        <div className="card-body">
                            <ReactTable
                                data={posts}
                                filterable
                                sortable
                                manual
                                pages={totalPages}
                                loading={loading} // Display the loading overlay when we need it
                                onFetchData={getPosts} // Request new data when things change
                                collapseOnPageChange={true}
                                defaultPageSize={PAGE_SIZE_ROWS}
                                className="-striped -highlight"
                                loadingText="Loading ..."
                                defaultSorted={[
                                    {
                                        id: 'createdAt',
                                        desc: true
                                    },
                                    {
                                        id: 'updatedAt',
                                        desc: true
                                    },
                                ]}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                columns={[
                                    {
                                        Header: 'Row',
                                        filterable: false,
                                        sortable: false,
                                        id: "row",
                                        maxWidth: 50,
                                        style: {textAlign: 'center'},
                                        className: "font-weight-bold",
                                        Cell: row => <div>{(row.index + 1) + (row.page * row.pageSize)}</div>
                                    },
                                    {
                                        Header: "Section",
                                        accessor: "post_type",
                                        Filter: ({filter, onChange}) =>
                                            <select
                                                onChange={event => onChange(event.target.value)}
                                                style={{width: "100%"}}
                                                value={filter ? filter.value : "all"}
                                            >
                                                <option value="all">Show All</option>
                                                <option value="1">Article</option>
                                                <option value="0">News</option>
                                            </select>,
                                        Cell: row => <label className="" key={row.original.id}>
                                            {row.value ? <b className="font-weight-bold text-primary">Article</b> :
                                                <b className="font-weight-bold text-accent">News</b>}
                                        </label>
                                    },
                                    {
                                        Header: "Picture",
                                        filterable: false,
                                        sortable: false,
                                        accessor: 'image_file',
                                        Cell: row => <img src={'/uploads/' + row.value}
                                                          title={row.original.image_title}
                                                          alt={row.original.image_alt} className="rounded"
                                                          width="100"/>
                                    },
                                    {
                                        Header: "Title",
                                        accessor: "title",
                                    },
                                    {
                                        Header: "Content",
                                        accessor: "description",
                                    },
                                    {
                                        Header: "Created date",
                                        accessor: "createdAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="createdAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Last Update",
                                        accessor: "updatedAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="updatedAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "View",
                                        accessor: 'visit_count',
                                        filterable: false,
                                        maxWidth: 50,
                                        Filter: ({filter, onChange}) =>
                                            <input type="range" name="visit_count" className="custom-range" min="0"
                                                   max="10000"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : 0}/>,
                                        Cell: row => <span>{row.value} <span className="mr-1">Per</span></span>
                                    },
                                    {
                                        Header: "Comments",
                                        filterable: false,
                                        sortable: false,
                                        maxWidth: 50,
                                        Cell: row => (
                                            <span data-toggle="modal" data-target="#postCommentsModal"
                                                  onClick={() => showComments(row.original)}>
                                            <i
                                                className="material-icons hover pointer"
                                                data-toggle="tooltip" title="Edit">comment</i> </span>
                                        )
                                    },
                                    {
                                        Header: "History",
                                        filterable: false,
                                        sortable: false,
                                        maxWidth: 50,
                                        Cell: row => (
                                            <span data-toggle="modal" data-target="#postEditingHistoriesModal"
                                                  onClick={() => showPostEditingHistories(row.original)}>
                                            <i className="material-icons hover  ml-2 pointer"
                                               data-toggle="tooltip"
                                               title="Post editing history">history</i>
                                            </span>
                                        )
                                    },
                                    {
                                        Header: "Action",
                                        filterable: false,
                                        sortable: false,
                                        Cell: row => (
                                            <span>
                                            <span onClick={() => deleteItem(row.original)}>
                                            <i
                                                className="material-icons hover pointer mr-2"
                                                data-toggle="tooltip" title="delete">delete</i> </span>
                                            <Link
                                                to={"/admin/edit-post/" + row.original.id + "/" + row.original.post_category_id}><i
                                                className="material-icons hover pointer ml-2"
                                                data-toggle="tooltip"
                                                title="Edit">edit</i> </Link>
                                                </span>
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

