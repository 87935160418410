import React, {useEffect, useState} from "react";
import {confirmAlert} from 'react-confirm-alert';
import {http, PAGE_SIZE_ROWS} from "../../../env";
import {Link} from "react-router-dom";
import ReactTable from "react-table-v6";

export const DealerShipManagement = props => {
    const [dealerShips, setDealerShips] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [tableState, setTableState] = useState(null);
    const getDealerShips = async (state, instance) => {
        try {
            setLoading(true);
            const _res = await http('POST', 'dealer_ship/all', {
                pageSize: state.pageSize,
                page: state.page,
                sorted: state.sorted,
                filtered: state.filtered
            });
            if (_res.ok) {
                setDealerShips(_res.data.dealerShips);
                setTableState(state);
                setLoading(false);
                setTotalPages(_res.data.totalPages);
            } else console.log(_res.messages);
        } catch (e) {
            console.log(e.messages)
        }
    };
    
    const deleteItem = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui'>
                        <h1 className="text-primary">Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button className="btn btn-white-border btn-raised mr-3" onClick={onClose}>No</button>
                        <button className="btn btn-success btn-raised" onClick={() => destroy(onClose, item)}>
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });
        const destroy = async (onClose, item) => {
            onClose();
            const _res = await http('POST', 'dealer_ship/delete', item);
            if (_res.ok) getDealerShips(tableState, null);
            else console.log(_res.messages);
        }
    };
    return (
        <div id='create-post-component'>
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="card border-0 shadow-3 rounded-0">
                        <div className="card-header">
                            <h4>List of dealer ships</h4>
                        </div>
                        <div className="card-body">
                            <ReactTable
                                data={dealerShips}
                                filterable
                                sortable
                                manual
                                pages={totalPages}
                                loading={loading} // Display the loading overlay when we need it
                                onFetchData={getDealerShips} // Request new data when things change
                                collapseOnPageChange={true}
                                defaultPageSize={PAGE_SIZE_ROWS}
                                className="-striped -highlight"
                                loadingText="Loading ..."
                                defaultSorted={[
                                    {
                                        id: 'updatedAt',
                                        desc: true
                                    },
                                ]}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                defaultExpanded={{0: {index: true}}}
                                SubComponent={row => <span>{row.original.description}</span>}
                                columns={[
                                    {
                                        Header: 'Row',
                                        filterable: false,
                                        sortable: false,
                                        id: "row",
                                        maxWidth: 50,
                                        style: {textAlign: 'center'},
                                        className: "font-weight-bold",
                                        Cell: row => <div>{(row.index + 1) + (row.page * row.pageSize)}</div>
                                    },
                                    {
                                        Header: "Logo",
                                        filterable: false,
                                        sortable: false,
                                        accessor: 'logo',
                                        Cell: row => <img src={'/uploads/' + row.value}
                                                          title={row.logo}
                                                          className="rounded"
                                                          width="100"/>
                                    },
                                    {
                                        Header: "Name",
                                        accessor: "name",
                                    },
                                    {
                                        Header: "Website",
                                        accessor: "website",
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                    },
                                    {
                                        Header: "Mobile",
                                        accessor: "mobile",
                                    },
                                    {
                                        Header: "Post Code",
                                        accessor: "post_code",
                                    },
                                    {
                                        Header: "Last Update",
                                        accessor: "updatedAt",
                                        minWidth: 150,
                                        Filter: ({filter, onChange}) =>
                                            <input type="date" name="updatedAt"
                                                   onChange={event => onChange(event.target.value)}
                                                   value={filter ? filter.value : ""}/>,
                                        Cell: row => new Intl.DateTimeFormat("en-GB", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                        }).format(new Date(row.value).getTime())
                                    },
                                    {
                                        Header: "Action",
                                        filterable: false,
                                        sortable: false,
                                        // maxWidth: 90,
                                        Cell: row => (
                                            <span>
                                            <span onClick={() => deleteItem(row.original)}>
                                            <i
                                                className="material-icons hover pointer mr-2"
                                                data-toggle="tooltip" title="delete">delete</i> </span>
                                            <Link
                                                to={`/admin/edit-dealer-ship/${row.original.id}`}><i
                                                className="material-icons hover pointer ml-2"
                                                data-toggle="tooltip"
                                                title="Edit">edit</i> </Link>
                                                </span>
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

