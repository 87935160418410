import React from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm} from "react-hook-form";

export const PostCategoryFormModal = (props) => {
    const {formData, data, divKey, modalTitle, isEdit} = props;

    const callBackHandler = values => formData({
        id: data.id,
        title: values.title,
        parent_id: data.parent_id,
    })

    const {register, handleSubmit, errors} = useForm();

    return (
        <div className="modal fade" id="categoryModal" aria-labelledby="categoryModal" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content" key={divKey}>
                    <div className="modal-header">
                        <h5 className="modal-title">{modalTitle}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="categoryTitle">Category title</label>
                        <div className="form-group">
                            <input autoFocus
                                   name="title"
                                type="text"
                                id="categoryTitle"
                                placeholder='Title'
                                className='form-control'
                                ref={register({required: "Required"})}
                                defaultValue={data.title}
                            />
                            {errors.title && errors.title.message}
                        </div>

                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <button type="button" className='btn btn-primary btn-raised' data-dismiss="modal"
                                onClick={handleSubmit(callBackHandler)}>
                            {isEdit ? <i className="fas fa-save mr-2"/> : <i className="fas fa-plus mr-2"/>}
                            {isEdit ? 'Update' : 'Add'}
                        </button>
                        <button type="button" className="btn btn-white-border" data-dismiss="modal">Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

