import React, {useEffect, useState} from 'react';
import {http} from "../../../env";
import {Link} from 'react-router-dom';

export const ViewPost = () => {
    const [articles, setArticles] = useState([]);
    const [news, setNews] = useState([]);
    const [firstArticle, setFirstArticle] = useState({});
    const [firstNews, setFirstNews] = useState([]);

    const getNews = async () => {
        try {
            const _res = await http('POST', 'post/all-published-limit', {post_type: false,limit:6});
            if (_res.ok) {
                setNews(_res.data)
                setFirstNews(_res.data[0])
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }
    }
    const getArticles = async () => {
        try {
            const _res = await http('POST', 'post/all-published-limit', {post_type: true,limit:6});
            console.log(_res)
            if (_res.ok) {
                setArticles(_res.data);
                setFirstArticle(_res.data[0])
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e.messages)
        }

    }
    useEffect(() => {
        getNews();
        getArticles();
    }, [])
    return (

        <div>
            <div className="container-fluid bg-white  mx-0">
                {articles.length > 0 ?
                    <div>
                        <div className="row bg-light">
                            <div className="col-12 text-left px-4">
                                <h1 className="mb-4 font-size-25 font-weight-bold text-primary pt-3">Blog</h1>
                                <p className="text-left font-weight-normal font-size-15 line-height-1">
                                    The Power Of Information
                                </p>
                            </div>
                        </div>
                        <div className="row post-section ">
                            {firstArticle && firstArticle.id &&
                            <div className="col-12 col-md-6 p-5" key={firstArticle.id}>
                                <img src={'/uploads/' + firstArticle.image_file}
                                     className="w-100 rounded d-flex flex-grow-1"
                                     alt={firstArticle.image_alt}
                                     title={firstArticle.image_title}/>
                                <h2 className="font-weight-bold  font-size-16 pt-5">
                                    <Link className="card-title"
                                          to={`/Blog/${firstArticle.post_category.title}/${firstArticle.slug}`}>{firstArticle.title}</Link>
                                </h2>
                                <p className="card-text font-size-13 text-secondary mt-5 line-height-1">
                                    {firstArticle.description}
                                </p>
                            </div>
                            }
                            <div className=" col-12 col-md-6 mt-3 mt-md-0 border-left pl-2 pr-0 px-md-5">
                                <div className="d-block">
                                    <div className="d-flex justify-content-between w-100 px-2 py-4">
                                        <span className="font-size-13 text-secondary">Latest article</span>
                                        <Link to="/blog/articles" className="font-size-12 text-primary">See All<i
                                            className="fas fa-arrow-right ml-2"/></Link>
                                    </div>
                                    <div className="d-flex">
                                        <ul className="list-bottom-border w-100 pl-0">
                                            {articles && articles.map((item, key) =>
                                                <li className="d-flex post-list" key={item.id}>
                                                    <img src={'/uploads/' + item.image_file} style={{width: '100px'}}
                                                         className="rounded" alt={item.image_alt}
                                                         title={item.image_title}/>
                                                    <div className="d-flex flex-column justify-content-around ml-3">
                                                        <small
                                                            className="text-muted">{new Intl.DateTimeFormat("en-GB", {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "2-digit",
                                                            hour: "numeric",
                                                            minute: "numeric",
                                                        }).format(new Date(item.publishAt).getTime())}</small>
                                                        <h1 className="font-weight-bold text-accent">
                                                            <Link className="card-title"
                                                                  to={`/blog/${item.post_category.title}/${item.slug}`}>{item.title}</Link>
                                                        </h1>
                                                    </div>
                                                </li>
                                            )}

                                        </ul>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    : <div className="d-flex flex-column justify-content-center align-items-center w-100">
                        <h1 className="mb-4 mt-5 font-size-25 font-weight-bold text-primary">Blog</h1>
                        <h1 className="my-5"> There is no post</h1>
                        <img src="/images/no-data.svg" alt="" width="150" className="opacity-48"/>
                    </div>}

            </div>
            <div className="container-fluid news-section">
                {news.length > 0 ?
                    <div>
                        <div className="row">
                            <div className="col-12 text-left px-4">
                                <h1 className="mb-4 font-size-25 font-weight-bold text-primary pt-3">Latest News</h1>
                                <p className="text-left font-weight-normal font-size-15 line-height-1">
                                    Automotive Industry Latest News
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            {firstNews && firstNews.id &&
                            <div className="col-12 col-md-6 p-5" key={firstNews.id}>
                                <img src={'/uploads/' + firstNews.image_file}
                                     className="w-100 rounded d-flex flex-grow-1"
                                     alt={firstNews.image_alt}
                                     title={firstNews.image_title}/>
                                <h2 className="font-weight-bold text-white font-size-15 pt-5">
                                    <Link className="card-title text-primary"
                                          to={`/blog/${firstNews.post_category.title}/${firstNews.slug}`}>{firstNews.title}</Link>
                                </h2>
                                <p className="card-text font-size-13 text-secondary mt-5 line-height-1">
                                    {firstNews.description}
                                </p>
                            </div>
                            }
                            <div className=" col-12 col-md-6 mt-3 mt-md-0 border-left pl-2 pr-0 px-md-5">
                                <div className="d-block">
                                    <div className="d-flex justify-content-between w-100 px-2 py-4 text-primary">
                                        <span className="font-size-13 ">Latest news</span>
                                        <Link to="/blog/news" className="font-size-12">See All<i
                                            className="fas fa-arrow-right ml-2"/></Link>
                                    </div>
                                    <div className="d-flex">
                                        <ul className="list-bottom-border  w-100 pl-0">
                                            {news && news.map((item, key) =>
                                                <li className="d-flex news-list" key={item.id}>
                                                    <img src={'/uploads/' + item.image_file} style={{width: '100px'}}
                                                         className="rounded" alt={item.image_alt}
                                                         title={item.image_title}/>
                                                    <div className="d-flex flex-column justify-content-around ml-3">
                                                        <small
                                                            className="text-secondary mb-2">{new Intl.DateTimeFormat("en-GB", {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "2-digit",
                                                            hour: "numeric",
                                                            minute: "numeric",
                                                        }).format(new Date(item.publishAt).getTime())}</small>
                                                        <h1 className="font-weight-bold text-white">
                                                            <Link className="card-title"
                                                                  to={`/Blog/${item.post_category.title}/${item.slug}`}>{item.title}</Link>
                                                        </h1>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="d-flex flex-column justify-content-center align-items-center w-100">
                        <h1 className="mb-4 mt-5 font-size-25 font-weight-bold text-primary">News</h1>
                        <h1 className="my-5"> There is no News</h1>
                        <img src="/images/no-data.svg" alt="" width="150" className="opacity-48"/>
                    </div>}

            </div>
        </div>
    );
};
