import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useFormik} from "formik";


export const AuthorModal = props => {
    const {formData, data, divKey, modalTitle, isEdit} = props;
    const callBackHandler = values => {
        return formData({
            id: data.id,
            name: values.name,
            avatar: image,
            avatarObj: values.image_file,
            description: values.description,
        })
    }
    const [imagePreview, setImagePreview] = useState(null);
    const [fileName, setFileName] = useState('Select image');
    const [image, setImage] = useState();

    useEffect(() => {
        if (props.data.avatar !== null) {
            setImagePreview("/uploads/" + props.data.avatar)
            setFileName(props.data.avatar)
        }
    }, [props.data])

    const handleChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setFileName(file.name);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
    };
    const {register, handleSubmit, errors} = useForm();
    return (
        <div className="modal fade" id="authorModal" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content" key={divKey}>
                    <div className="modal-header">
                        <h5 className="modal-title text-secondary"><i className="fas fa-pen-fancy mr-2"/>{modalTitle}
                        </h5>
                        <button type="submit" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(callBackHandler)}>
                        <div className="modal-body">
                            {/*<input type="hidden" name="id"/>*/}
                            {/*<div className="d-flex justify-content-center align-items-center  box-shadow-2 mb-3">*/}
                            {/*    <div>*/}
                            {/*        <div className="file-loading ">*/}
                            {/*            <input id="AuthorAvatar" name="avatar" ref={register({required: "Required"})} type="file"/>*/}
                            {/*            {errors.avatar && errors.avatar.message}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <label htmlFor="name">Author Name</label>
                            <div className="input-group  mb-3">
                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i
                                                        className="fas fa-user opacity-38"/></span>
                                </div>
                                <input type="text" name="name" id="name" defaultValue={data.name} autoComplete="off"
                                       ref={register({required: "Required"})}
                                       className="form-control"
                                       placeholder="author name" data-msg-required="*"/>
                            </div>
                            <label htmlFor="image_file">Article Image:</label>
                            <div className="form-group">
                                <div className="custom-file">
                                    <input type="file" id="image_file" name="image_file" onChange={handleChange}
                                           className="custom-file-input"
                                           ref={register()}/>
                                    <label className="custom-file-label" htmlFor="image">{fileName}</label>
                                    {errors.image_file && errors.image_file.message}
                                </div>
                                <div className="form-group">
                                    <img src={imagePreview} alt='' className="w-100 rounded"/>
                                </div>
                            </div>
                            <label htmlFor="name">Author description</label>

                            <div className="input-group  mb-3">
                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i
                                                        className="fas fa-comment opacity-38"/></span>
                                </div>
                                <textarea name="description" className="form-control" rows="3"
                                          ref={register({required: "Required"})}
                                          defaultValue={data.description}
                                          autoComplete="off" data-msg-required="*"
                                          data-rule-required="true"
                                          placeholder="description"/>
                                {errors.description && errors.description.message}
                            </div>
                        </div>
                        <div className="modal-footer py-2">
                            <button type="submit" className="btn btn-primary btn-raised">
                                <i className="fas fa-save mr-2"/>Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

