import React, {useContext, useEffect, useState} from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useForm, Controller} from "react-hook-form";
import {http, httpUploadFile} from "../../../env";
import {toast} from "react-toastify";
import {AuthContext} from "../../../common/context/auth";
import {useHistory} from "react-router";
import ReactSelect from "react-select";

import GoogleMapReact from 'google-map-react';

export const DealerShipForm = (props) => {

    const {register, handleSubmit, control, reset, errors} = useForm();
    // const {formData, data} = props;
    let history = useHistory();
    const auth = useContext(AuthContext);
    const [dealerShip_id] = useState(props.match.params.dealerShipId);
    const [dealerShip, setDealerShip] = useState({});
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(null);
    const [states, setStates] = useState([]);
    const [state, setState] = useState(null);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [fileName, setFileName] = useState('Select image');
    const [image, setImage] = useState();
    const [latlng, setLatlng] = useState([null, null]);

    const handleOnDrag = (map) => {
        setLatlng([map.center.lat(), map.center.lng()]);
    };

    const AnyReactComponent = ({text}) => <div><i className="material-icons md-36">location_on</i></div>;

    const handleChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setFileName(file.name);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
    };

    const handleChangeCountry = selectedOption => {
        setCountry(selectedOption);
        getAddressData(selectedOption.value, 2);
        setCities([]);
        setState(null);
        setCity(null);
    };

    const handleChangeState = selectedOption => {
        setState(selectedOption);
        getAddressData(selectedOption.value, 3);
        setCity(null);
    };

    const handleChangeCity = selectedOption => {
        setCity(selectedOption);
    };

    const getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setLatlng([position.coords.latitude, position.coords.longitude]);
                }, errors => {
                    console.log(errors)
                }, {enableHighAccuracy: true}
            )
        } else {
            // error => console.log(error)
        }
    }

    const getAddressData = async (parent, type) => {
        try {
            const _res = await http('POST', 'address_data/get-by-parent-id', {id: parent})
            if (_res.ok) {
                if (type === 1)
                    setCountries(_res.data);
                else if (type === 2)
                    setStates(_res.data);
                else if (type === 3)
                    setCities(_res.data);
            } else console.log(_res.messages)
        } catch (e) {
            console.log(e)
        }
    }

    const getDealerShip = async () => {
        try {
            if (dealerShip_id && dealerShip_id !== '') {
                const _res = await http('POST', 'dealer_ship/get-by-id', {id: dealerShip_id})
                if (_res.ok) {
                    setDealerShip(_res.data);
                    // let data = {};
                    // for (let key in _res.data) {
                    //     if (key === 'logo') continue;
                    //     data[key] = _res.data[key];
                    // }
                    reset({country: _res.data.country, state: _res.data.state, city: _res.data.state});
                    setCountry(_res.data.Country);
                    setState(_res.data.State);
                    setCity(_res.data.City);
                    if (_res.data.location) {
                        setLatlng([
                            parseFloat(_res.data.location.split(',')[0]),
                            parseFloat(_res.data.location.split(',')[1])
                        ]);
                    } else getGeoLocation();
                    getAddressData(_res.data.country, 2);
                    getAddressData(_res.data.state, 3);
                    setImagePreview('/uploads/' + _res.data.logo)
                } else console.log(_res.messages)
            } else getGeoLocation();
        } catch (e) {
            console.log(e)
        }
    }

    const onSubmit = async (data) => {
        let formData = new FormData();
        for (let key in data)
            formData.append(key, data[key]);
        formData.append('logo', image);
        formData.append('country', country.value);
        formData.append('state', state.value);
        formData.append('city', city.value);
        formData.append('location', `${latlng[0]},${latlng[1]}`);
        let _res;
        if (dealerShip_id) {
            _res = await httpUploadFile('POST', 'dealer_ship/update', formData);
        } else
            _res = await httpUploadFile('POST', 'dealer_ship/save', formData);
        if (_res.ok) {
            history.push('/admin/edit-dealer-ship/' + _res.data.id);
            setDealerShip(_res.data)
            setImagePreview('/uploads/' + _res.data.logo)
            toast.success('Dealer ship saved successfully');
        } else
            toast.error('System error occurs. Please try again.');

    };

    useEffect(() => {
        getDealerShip();
        getAddressData(0, 1);
    }, [props.match.params.dealerShipId])

    useEffect(() => {
        return () => {
            setDealerShip(null);
        };
    }, []);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className="card card-shadow flex-grow-1 w-100">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h4>{dealerShip_id ?
                            <div>
                                <span>Edit dealer ship</span>
                            </div>
                            : 'Create dealer ship'}
                        </h4>
                        <button type="submit" className="btn btn-primary btn-raised"><i
                            className="fas fa-save mr-2 bt-1"/>Save
                        </button>
                    </div>
                </div>
                <div
                    className="d-flex flex-md-row flex-column align-items-md-start justify-content-md-between align-items-stretch  flex-wrap mt-3">
                    <div className="flex-grow-1">
                        <div className="card card-shadow mr-md-3">
                            <div className="card-header-none">
                                <h4>Logo</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <div className="custom-file">
                                        <input type="file" id="logo" name="logo" onChange={handleChange}
                                               className="custom-file-input"
                                               ref={register()}/>
                                        <label className="custom-file-label" htmlFor="image">{fileName}</label>
                                        {errors.logo && errors.logo.message}
                                    </div>
                                    <div className="form-group text-center mt-4">
                                        <img src={imagePreview} alt='' className="rounded" width="300"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card-shadow flex-grow-1  mt-3 mr-md-3">
                            <div className="card-header-none">
                                <a data-toggle="collapse" data-target="#DealerShipInformationBody" aria-expanded="true"
                                   className="collapse-header">
                                    <span
                                        className="d-block font-weight-bold text-secondary">Dealer Ship Information</span>
                                </a>
                            </div>
                            <div className="collapse show" id="DealerShipInformationBody">
                                <div className="card-body">
                                    <input type="hidden" name="id" defaultValue={dealerShip.id} ref={register()}/>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            name="name"
                                            type="text"
                                            placeholder='Name'
                                            className='form-control'
                                            ref={register({required: 'Name is required field'})}
                                            defaultValue={dealerShip.name}
                                        />
                                        <span className="error">{errors.name && errors.name.message}</span>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="dealer_number">Dealer Number</label>
                                        <input
                                            name="dealer_number"
                                            type="number"
                                            placeholder='Dealer Number'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={dealerShip.dealer_number}
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="gst_number">GST Number</label>
                                        <input
                                            name="gst_number"
                                            type="number"
                                            placeholder='GST Number'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={dealerShip.gst_number}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="pst_number">PST Number</label>
                                        <input
                                            name="pst_number"
                                            type="number"
                                            placeholder='PST Number'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={dealerShip.pst_number}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="hst_number">HST Number</label>
                                        <input
                                            name="hst_number"
                                            type="number"
                                            placeholder='HST Number'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={dealerShip.hst_number}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <div className="card card-shadow flex-grow-1  mt-3 mt-md-0">
                            <div className="card-header-none">
                                <a data-toggle="collapse" data-target="#AddressInformationBody" aria-expanded="true"
                                   className="collapse-header">
                                    <span className="d-block font-weight-bold text-secondary">Address Information</span>
                                </a>
                            </div>
                            <div className="collapse show" id="AddressInformationBody">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="location">Location</label>
                                        <div style={{
                                            height: '50vh',
                                            width: '100%',
                                            position: "relative",
                                        }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{key: 'AIzaSyAUVT4Ge7LXP-2BPJr812IaGEg9HrqWrBw'}}
                                                center={latlng}
                                                defaultZoom={15}
                                                onDragEnd={handleOnDrag}
                                                yesIWantToUseGoogleMapApiInternals={true}
                                            >
                                                {/*<AnyReactComponent*/}
                                                {/*    lat={latlng[0]}*/}
                                                {/*    lng={latlng[1]}*/}
                                                {/*    text="My Marker"*/}
                                                {/*/>*/}
                                            </GoogleMapReact>
                                            <div style={{
                                                position: "absolute",
                                                zIndex: 999999999,
                                                width: '36px',
                                                height: '36px',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-36px',
                                                marginLeft: '-18px',
                                            }}><i className="material-icons md-36">location_on</i></div>
                                            <div title="My location" onClick={getGeoLocation} style={{
                                                position: "absolute",
                                                zIndex: 9999999,
                                                width: '36px',
                                                height: '36px',
                                                bottom: '110px',
                                                right: '12px',
                                                cursor: "pointer",
                                                marginTop: '-36px',
                                                marginLeft: '-18px',
                                            }}><i className="material-icons md-36">my_location</i></div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="country">Country</label>
                                        <Controller
                                            name="country"
                                            render={({}) => (
                                                <ReactSelect
                                                    placeholder="Country"
                                                    onChange={handleChangeCountry}
                                                    isSearchable
                                                    options={countries}
                                                    value={country}
                                                />
                                            )}
                                            rules={{required: 'Country is required field'}}
                                            control={control}/>
                                        <span className="error">{errors.country && errors.country.message}</span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="state">State</label>
                                        <Controller
                                            name="state"
                                            render={({}) => (
                                                <ReactSelect
                                                    placeholder="State"
                                                    onChange={handleChangeState}
                                                    isSearchable
                                                    options={states}
                                                    value={state}
                                                />
                                            )}
                                            rules={{required: 'State is required field'}}
                                            control={control}/>
                                        <span className="error">{errors.state && errors.state.message}</span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="city">City</label>
                                        <Controller
                                            name="city"
                                            render={({}) => (
                                                <ReactSelect
                                                    placeholder="City"
                                                    onChange={handleChangeCity}
                                                    isSearchable
                                                    options={cities}
                                                    value={city}
                                                />
                                            )}
                                            rules={{required: 'City is required field'}}
                                            control={control}/>
                                        <span className="error">{errors.city && errors.city.message}</span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="post_code">Post Code</label>
                                        <input
                                            name="post_code"
                                            type="number"
                                            placeholder='Post Code'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={dealerShip.post_code}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="address">Address</label>
                                        <textarea name="address" id="address" rows="5" placeholder="address"
                                                  className="form-control" ref={register()}
                                                  defaultValue={dealerShip.address}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-shadow flex-grow-1 mt-3">
                            <div className="card-header-none">
                                <a data-toggle="collapse" data-target="#VendorContactBody" aria-expanded="true"
                                   className="collapse-header">
                                    <span className="d-block font-weight-bold text-secondary">Contact information</span>
                                </a>
                            </div>
                            <div className="collapse show" id="VendorContactBody">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="website">Website</label>
                                        <input
                                            name="website"
                                            type="url"
                                            placeholder='Website'
                                            className='form-control'
                                            ref={register({required: 'Website is required field'})}
                                            defaultValue={dealerShip.website}
                                        />
                                        <span className="error">{errors.website && errors.website.message}</span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            name="email"
                                            type="email"
                                            placeholder='Email'
                                            className='form-control'
                                            ref={register({required: 'Email is required field'})}
                                            defaultValue={dealerShip.email}
                                        />
                                        <span className="error">{errors.email && errors.email.message}</span>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="phone">Phone</label>
                                        <input
                                            name="phone"
                                            type="tel"
                                            placeholder='Phone'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={dealerShip.phone}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fax">Fax</label>
                                        <input
                                            name="fax"
                                            type="tel"
                                            placeholder='Fax'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={dealerShip.fax}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="mobile">Mobile</label>
                                        <input
                                            name="mobile"
                                            type="tel"
                                            placeholder='Mobile'
                                            className='form-control'
                                            ref={register()}
                                            defaultValue={dealerShip.mobile}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

